import React from 'react'
import PropTypes from 'prop-types'
import { ConflictPanelContainer } from '../containers/ConflictPanelContainer'
import { NewPanelPaneContainer } from '../containers/NewPanelPaneContainer'
import { OptionalHide } from './OptionalHide'
import '../styles/ExpandingPanel.css'

export const ExpandingPanel = ({ hidden, conflicting }) => (
    <OptionalHide hidden={hidden} className="flex-grow-1 lighter-grey full-height">
        {conflicting ? (
            <div className="conflict-panel-background full-height">
                <ConflictPanelContainer />
            </div>
        ) : (
            <div className="full-height bg-white">
                <NewPanelPaneContainer />
            </div>
        )}
    </OptionalHide>
)

ExpandingPanel.propTypes = {
    hidden: PropTypes.bool.isRequired,
    conflicting: PropTypes.bool.isRequired,
}
