import { connect } from 'react-redux'
import TablePagination from '../components/TablePagination'
import { getRows } from '../helpers'
import { setPageIndex } from '../actions/table'

const mapStateToProps = (state, ownProps) => {
    return {
        pageIndex: state.pageIndex,
        totalPageCount: Math.ceil(
            getRows(state, state.undoState).length / state.pageSize,
        ),
        onPageChange: ownProps.onPageChange,
    }
}

const mapDispatchToProps = dispatch => ({
    setPageIndex: idx => dispatch(setPageIndex(idx)),
})

export const TablePaginationContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(TablePagination)
