import {
    CompanyActions,
    FetchActions,
    PossibleStatus,
} from '../actions/companies'
import { defaultMeta, defaultData } from './defaults'
import { MergeActions } from '../actions/merge'

const removeCompanySuggestionsFromState = (state, col) => {
    let currentSuggestions = state
    let currentSuggestionsNames = currentSuggestions.map(field => field.name)
    let index = 0
    for (let name of col) {
        index = currentSuggestionsNames.indexOf(name)
        if (index > -1) {
            currentSuggestions.splice(index, 1)
            currentSuggestionsNames.splice(index, 1)
        }
    }
    return currentSuggestions
}

export const displayedCompanies = (state = [], action) => {
    switch (action.type) {
        case CompanyActions.DISPLAY_SEARCH_RESULTS:
            return [...action.searchResultIds]

        case CompanyActions.CLEAR_COMPANY_DATA:
            return []

        case CompanyActions.ADD_COMPANY_IDS:
            return [...action.companyIds]

        case CompanyActions.ADD_COMPANY_BATCH:
            return Object.keys(action.companies).map(key => Number(key))

        default:
            return state
    }
}

export const companies = (state = [], action) => {
    switch (action.type) {
        case CompanyActions.ADD_COMPANY:
            return [...state, action.id]

        case CompanyActions.ADD_COMPANY_IDS:
            return [...action.companyIds]

        case CompanyActions.REMOVE_COMPANY:
            return state.filter(id => id !== action.id)

        case CompanyActions.CLEAR_COMPANY_DATA:
            return []

        case CompanyActions.ADD_COMPANY_BATCH:
            return Object.keys(action.companies).map(key => Number(key))

        default:
            return state
    }
}

export const companyMeta = (state = defaultMeta, action) => {
    let newState = { ...state }
    switch (action.type) {
        case CompanyActions.ADD_COMPANY:
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    id: action.id,
                    name: action.name,
                    status: action.status,
                },
            }

        case CompanyActions.ADD_COMPANY_BATCH:
            return {
                ...action.companies,
            }

        case CompanyActions.CHANGE_COMPANY_STATUS:
            newState[action.id].status = action.status
            return newState

        case CompanyActions.CHANGE_COMPANY_STATUSES:
            action.statuses.forEach(company => {
                newState[company.id].status = company.status
            })
            return newState

        case CompanyActions.CHANGE_CONFLICTING_STATUS:
            newState[action.id].isConflicting = action.isConflicting
            return newState

        case CompanyActions.SAVE_MTIME:
            newState[action.id].mTime = action.mTime
            return newState

        case CompanyActions.SAVE_DBID:
            newState[action.id].dbId = action.dbId
            return newState

        case CompanyActions.BATCH_MERGE:
            for (const item of action.batch) {
                newState[item.id].mtime = item.mtime
                newState[item.id].dbId = item.dbid
                newState[item.id].isConflicting = item.conflicting
                newState[item.id].status = item.status
                    ? item.status
                    : defaultMeta[0].status
                newState[item.id].isExactMatch = item.isExactMatch
                    ? item.isExactMatch
                    : defaultMeta[0].isExactMatch
                newState[item.id].unmerged = item.unmerged || []
            }
            return newState

        case CompanyActions.CLEAR_COMPANY_DATA:
            return {
                ...defaultMeta,
            }

        case CompanyActions.ADD_INGEST_ERRORS:
            action.companies.forEach(company => {
                newState[company.id].errors = company.errors
            })
            return newState

        case MergeActions.MERGE_FIELD:
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    merges: [...state[action.id].merges, action.fieldName],
                    unmerged: state[action.id].unmerged.filter(
                        item => item !== action.fieldName,
                    ),
                },
            }

        case MergeActions.UNMERGE_FIELD:
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    merges: state[action.id].merges.filter(
                        fieldName => fieldName !== action.fieldName,
                    ),
                    unmerged: [...state[action.id].unmerged, action.fieldName],
                },
            }

        case MergeActions.MERGE_ALL:
            action.ids.forEach(id => {
                let meta = state[id]
                if (meta.isConflicting) {
                    newState[id]['merges'] = [...meta.merges, ...meta.unmerged]
                    newState[id]['unmerged'] = []
                    newState[id]['status'] = PossibleStatus.MERGED
                } else if (!meta.isExactMatch) {
                    newState[id]['status'] = PossibleStatus.ADDED
                }
            })
            return {
                ...newState,
            }

        case MergeActions.UNMERGE_ALL:
            action.ids.forEach(id => {
                let meta = state[id]
                if (meta.isConflicting) {
                    newState[id]['unmerged'] = [
                        ...meta.merges,
                        ...meta.unmerged,
                    ]
                    newState[id]['merges'] = []
                    newState[id]['status'] = PossibleStatus.PENDING
                } else if (!meta.isExactMatch) {
                    newState[id]['status'] = PossibleStatus.PENDING
                }
            })
            return {
                ...newState,
            }

        default:
            return state
    }
}

export const companyData = (state = defaultData, action) => {
    switch (action.type) {
        case CompanyActions.ADD_COMPANY:
            return {
                ...state,
                [action.id]: {
                    id: action.id,
                    ...action.data,
                },
            }

        case CompanyActions.ADD_COMPANY_DATA_BATCH:
            return {
                ...action.companyData,
            }

        case CompanyActions.CLEAR_COMPANY_DATA:
            return {
                ...defaultData,
            }

        default:
            return state
    }
}

export const companyDataCurrent = (state = defaultData, action) => {
    switch (action.type) {
        case FetchActions.FETCH_COMPANIES_SUCCESS:
            return {
                ...state,
                [action.id]: {
                    ...action.data,
                    id: action.id,
                },
            }

        case FetchActions.FETCH_COMPANIES_FAILURE:
            return {
                ...state,
                [action.id]: {
                    ...action.data,
                    id: action.id,
                },
            }

        case CompanyActions.BATCH_MERGE:
            const newState = { ...state }
            for (const item of action.batch) {
                newState[item.id] = {
                    ...item.data,
                    id: item.id,
                }
            }
            return newState

        case CompanyActions.ADD_COMPANY_DATA_CURRENT_BATCH:
            return {
                ...action.companyData,
            }

        case CompanyActions.CLEAR_COMPANY_DATA:
            return {
                ...defaultData,
            }

        default:
            return state
    }
}

export const currSelectedCompany = (state = 0, action) => {
    switch (action.type) {
        case CompanyActions.SELECT_COMPANY:
            return action.id

        case CompanyActions.CLEAR_COMPANY_DATA:
            return 0

        case CompanyActions.ADD_COMPANY_BATCH:
            return Number(Object.keys(action.companies)[0])

        default:
            return state
    }
}

export const stagedForIngest = (state = [], action) => {
    switch (action.type) {
        case CompanyActions.STAGE_FOR_INGEST:
            return [...state, ...action.staged]

        case CompanyActions.UNSTAGE_FOR_INGEST:
            return state.filter(id => !action.unstaged.includes(id))

        case CompanyActions.STAGE_INGESTED:
            return state.filter(id => !action.ingested.includes(id))

        case CompanyActions.CLEAR_COMPANY_DATA:
        case MergeActions.UNMERGE_ALL:
            return []

        case MergeActions.MERGE_ALL:
            return action.ids

        default:
            return state
    }
}

export const ingested = (state = [], action) => {
    switch (action.type) {
        case CompanyActions.STAGE_INGESTED:
            return [...state, ...action.ingested]
        case CompanyActions.CLEAR_INGESTED:
            return []
        case CompanyActions.BATCH_MERGE:
            for (const item of action.batch) {
                if (
                    item.status === PossibleStatus.NO_CHANGES &&
                    state.indexOf(item.id) === -1
                ) {
                    state.push(item.id)
                }
            }
            return state
        default:
            return state
    }
}

export const fetching = (state = { fetching: false, msg: '' }, action) => {
    switch (action.type) {
        case FetchActions.FETCH_COMPANIES_BEGIN:
            return { fetching: true, msg: action.msg || '' }
        // case FetchActions.FETCH_COMPANIES_UPDATE:
        //     return { fetching: true, msg: action.msg || '' }
        case FetchActions.FETCH_COMPANIES_END:
            return { fetching: false, msg: '' }

        default:
            return state
    }
}

export const companySuggestions = (state = [], action) => {
    switch (action.type) {
        case CompanyActions.ADD_COMPANY_SUGGESTIONS:
            return [...state, ...action.suggestions]
        case CompanyActions.REMOVE_COMPANY_SUGGESTIONS:
            return removeCompanySuggestionsFromState(state, action.col)
        case CompanyActions.CLEAR_COMPANY_SUGGESTIONS:
            return []
        default:
            return state
    }
}

export const serverCompanyData = (state = [], action) => {
    switch (action.type) {
        case CompanyActions.ADD_SERVER_COMPANY_DATA:
            return action.serverCompanyData
        default:
            return state
    }
}

export const normalizedServerCompanyNames = (state = [], action) => {
    switch (action.type) {
        case CompanyActions.ADD_SERVER_COMPANY_DATA:
            return action.normalizedServerCompanyNames
        default:
            return state
    }
}
