import React, { PureComponent, Fragment } from 'react'
import { navigateTo } from '../routes/history'
import queryString from 'query-string'

export class App extends PureComponent {
    componentDidMount() {
        const { init } = this.props
        const { token, domain } = queryString.parse(window.location.search)
        if (token && domain) {
            init(token, domain)
        }
    }

    componentDidUpdate() {
        const { isReady } = this.props
        if (isReady) navigateTo('/validate')
    }

    render() {
        return null
    }
}
