import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import '../styles/StageButtons.css'

export const StageButtons = ({
    staged,
    stageButtonTitle,
    unstageButtonTitle,
    handleStageClick,
    handleStageKeyPress,
    focusRef,
}) => (
    <div className="h-100 d-flex flex-column align-items-center justify-content-center text-center">
        <Button
            onClick={e => e.detail > 0 && handleStageClick()}
            onKeyDown={e => e.key === 'Enter' && handleStageKeyPress()}
            className={`action-btn m-0 fixed-height-btn p-0 border-0 no-focus-outline btn-${
                staged ? 'dark' : 'green'
            }`}
            tabIndex="1"
            innerRef={focusRef}
        >
            {staged ? unstageButtonTitle : stageButtonTitle}
        </Button>
    </div>
)

StageButtons.propTypes = {
    staged: PropTypes.bool.isRequired,
    stageButtonTitle: PropTypes.string.isRequired,
    unstageButtonTitle: PropTypes.string.isRequired,
    handleStageClick: PropTypes.func.isRequired,
    handleStageKeyPress: PropTypes.func.isRequired,
    focusRef: PropTypes.func.isRequired,
}
