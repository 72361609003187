import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap'

import { StatusBox } from './StatusBox'
import { PossibleStatus } from '../actions/companies'
import '../styles/CompanyTile.css'

const getPrefix = (status, conflicting) => {
    if (!conflicting) {
        return (
            <div className="bg-success text-white align-self-center new-banner">
                &nbsp;
            </div>
        )
    }

    return <Fragment />
}

const getStatus = status => {
    switch (status) {
        case PossibleStatus.NO_CHANGES:
            return 'No Changes'
        case PossibleStatus.ADDED:
        case PossibleStatus.MERGED:
            return 'Ingest Ready'
        case PossibleStatus.INGESTED:
            return 'Ingested'
        case PossibleStatus.WARNING:
            return 'Warning'
        case PossibleStatus.ERROR:
            return 'Error'
        case PossibleStatus.PENDING:
        default:
            return 'Pending'
    }
}

export class CompanyTile extends PureComponent {
    render() {
        const { name, status, focusRef, conflicting } = this.props
        return (
            <div ref={focusRef} tabIndex="-1">
                <Row>
                    <Col
                        xs="9"
                        className="tile-word-break d-flex p-0 overflow-hidden"
                    >
                        <div className="p-0 new-banner-holder">
                            {getPrefix(status, conflicting)}
                        </div>
                        <div className="flex-grow-1 p-0">{name}</div>
                    </Col>
                    <Col xs="3" className="badge-container p-0">
                        <StatusBox status={getStatus(status)} />
                    </Col>
                </Row>
            </div>
        )
    }
}

CompanyTile.propTypes = {
    name: PropTypes.string.isRequired,
    status: PropTypes.oneOf(Object.values(PossibleStatus)).isRequired,
    focusRef: PropTypes.func.isRequired,
    conflicting: PropTypes.bool.isRequired,
}
