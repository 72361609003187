import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { ListGroup, ListGroupItem, Button } from 'reactstrap'
import { CompanyTileContainer } from '../containers/CompanyTileContainer'
import { PossibleStatus } from '../actions/companies'
import Pagination from 'react-js-pagination'
import '../styles/CompanyList.css'

export class CompanyList extends Component {
    constructor(props) {
        super(props)
        this.companyTileRefs = []
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
    }

    state = {
        currentPage: 1,
        pageSize: 10,
        pageCount: 1,
    }

    componentDidMount() {
        const { companies } = this.props
        const heightOfOtherElemsInCol = 169 // Combined height of other elements in the displayed column
        const heightOfCompanyItem = 29 // Height of each individual company item

        const maxCompaniesPerHeight = Math.ceil(
            (window.innerHeight - heightOfOtherElemsInCol) /
                heightOfCompanyItem,
        )
        const pageCount = Math.ceil(companies.length / maxCompaniesPerHeight)
        this.setState({ pageSize: maxCompaniesPerHeight, pageCount })
    }

    // handleArrowNavigation(event, prevId, nextId, handleSelection) {
    //     switch (event.key) {
    //         case 'ArrowUp':
    //             handleSelection(prevId)
    //             event.preventDefault()
    //             break
    //         case 'ArrowDown':
    //             handleSelection(nextId)
    //             event.preventDefault()
    //             break
    //         default:
    //     }
    // }

    focusCompanyTile(id) {
        const toFocus = this.companyTileRefs.find(
            companyTile => companyTile.id === id,
        )
        if (toFocus) {
            toFocus.element.focus()
        }
    }

    toggleModal(type) {
        const { actionModalToggle } = this.props
        actionModalToggle(type)
    }

    handleClick(e, idx) {
        e.preventDefault()

        this.setState({ currentPage: idx })
    }

    handlePageChange(pageNumber) {
        this.setState({ currentPage: pageNumber })
    }

    handleSubmit(e) {
        if (e) {
            e.preventDefault()
            const pageIdx = Number(this.input.value)
            const pageCount = this.state.pageCount

            if (pageIdx > 0 && pageIdx <= pageCount) {
                this.setState({ currentPage: pageIdx })
            }
        }
    }

    render() {
        const {
            companies,
            noCompanies,
            // prevId,
            activeId,
            // nextId,
            handleSelection,
            canIngest,
            numToIngest,
            fullyMerged,
            fullyIngested,
        } = this.props
        const { currentPage, pageSize } = this.state

        return (
            <Fragment>
                <ListGroup flush className="overflow-y-auto">
                    {noCompanies ? (
                        <ListGroupItem className="pb-1 pt-1">
                            No Companies To Display
                        </ListGroupItem>
                    ) : (
                        companies
                            .slice(
                                (currentPage - 1) * pageSize,
                                currentPage * pageSize,
                            )
                            .map((company, i) => (
                                <ListGroupItem
                                    action
                                    key={company.id}
                                    className={`company-list-group-item pb-1 pt-1 ${company.id ===
                                        activeId && 'regular'}`}
                                    onClick={e => {
                                        this.focusCompanyTile(company.id)
                                        handleSelection(
                                            company.id,
                                            company.name,
                                        )
                                    }}
                                    // onKeyDown={e =>
                                    //     this.handleArrowNavigation(
                                    //         e,
                                    //         prevId,
                                    //         nextId,
                                    //         handleSelection,
                                    //     )
                                    // }
                                >
                                    <CompanyTileContainer
                                        id={company.id}
                                        focusRef={el =>
                                            this.companyTileRefs.push({
                                                id: company.id,
                                                element: el,
                                            })
                                        }
                                    />
                                </ListGroupItem>
                            ))
                    )}
                    {/* This is used as workingaround to double border issue */}
                    <ListGroupItem className="flex-grow-1" />
                </ListGroup>
                <div className="border-top d-flex justify-content-center align-items-center">
                    <Pagination
                        innerClass="pagination pagination-height w-100 mt-2 mb-2 ml-1 mr-1"
                        activePage={this.state.currentPage}
                        itemsCountPerPage={pageSize}
                        totalItemsCount={companies.length}
                        pageRangeDisplayed={7}
                        onChange={this.handlePageChange}
                        firstPageText="First"
                        lastPageText="Last"
                        prevPageText="«"
                        nextPageText="»"
                        linkClass="no-focus-outline page-link w-100  d-flex justify-content-center align-content-center"
                        activeLinkClass="navigation-background no-focus-outline"
                        itemClass="page-item page-form-item d-flex justify-content-center align-content-center"
                    />
                </div>
                <div className="bottom-nav-height border-top d-flex flex-column align-items-center pl-1 pr-1">
                    <Button
                        disabled={noCompanies || fullyIngested}
                        className="btn-block transparent-border btn-green text-center fixed-height-btn z-10 no-focus-outline mt-2"
                        onClick={() => this.toggleModal('save')}
                    >
                        Save Pending
                    </Button>
                    {fullyMerged ? (
                        <Button
                            disabled={noCompanies || fullyIngested}
                            className="btn-block transparent-border btn-yellow text-center fixed-height-btn z-10 no-focus-outline mt-2"
                            onClick={() => this.toggleModal('unmerge')}
                        >
                            Unmerge All
                        </Button>
                    ) : (
                        <Button
                            className="btn-block transparent-border btn-blue text-center fixed-height-btn z-10 no-focus-outline mt-2"
                            onClick={() => this.toggleModal('merge')}
                        >
                            Merge All
                        </Button>
                    )}

                    <Button
                        disabled={!canIngest}
                        className="btn-block transparent-border btn-blue text-center fixed-height-btn z-10 no-focus-outline"
                        onClick={() => this.toggleModal('ingest')}
                    >
                        Ingest Changes ({numToIngest})
                    </Button>
                </div>
            </Fragment>
        )
    }
}

CompanyList.propTypes = {
    companies: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            status: PropTypes.oneOf(Object.values(PossibleStatus)).isRequired,
            name: PropTypes.string.isRequired,
        }),
    ).isRequired,
    // prevId: PropTypes.number.isRequired,
    activeId: PropTypes.number.isRequired,
    // nextId: PropTypes.number.isRequired,
    handleSelection: PropTypes.func.isRequired,
}
