import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import { CompanyListContainer } from '../containers/CompanyListContainer'
import { SortStatusButtonContainer } from '../containers/SortStatusButtonContainer'
import { ExpandingPanelContainer } from '../containers/ExpandingPanelContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Spinner } from '../components/Spinner'
import { SearchBarContainer } from '../containers/SearchBarContainer'
import { SaveModalContainer } from '../containers/SaveModalContainer'
import { IngestModalContainer } from '../containers/IngestModalContainer'
import { MergeModalContainer } from '../containers/MergeModalContainer'
import { UnmergeModalContainer } from '../containers/UnmergeModalContainer'
import '../styles/MergeTool.css'

export class MergeTool extends PureComponent {
    render() {
        const { fetching, fetchingMsg } = this.props

        return (
            <Fragment>
                <Spinner spin={fetching} text={fetchingMsg} />
                <Row noGutters className="full-height border overflow-hidden">
                    <Col className="flex-grow-3 company-list p-0 border-right overflow-y-hidden h-100">
                        <div className="d-flex justify-content-between align-items-center pl-2 pr-2 border-bottom general-header">
                            <Link
                                to="/validate"
                                className="text-dark pr-2 border-right"
                            >
                                <FontAwesomeIcon icon="long-arrow-alt-left" />{' '}
                                Back
                            </Link>
                            <SortStatusButtonContainer />
                        </div>
                        <div className="d-flex justify-content-between p-1">
                            <SearchBarContainer />
                        </div>
                        <CompanyListContainer />
                    </Col>
                    <div className="nav-seperator-gap " />
                    <Col className="flex-grow-9 p-0 border-left h-100">
                        <ExpandingPanelContainer />
                    </Col>
                </Row>
                <SaveModalContainer />
                <IngestModalContainer />
                <MergeModalContainer />
                <UnmergeModalContainer />
            </Fragment>
        )
    }
}

MergeTool.propTypes = {
    fetching: PropTypes.bool.isRequired,
}
