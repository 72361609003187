import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
    Button,
    Popover,
    PopoverHeader,
    PopoverBody,
    ListGroup,
    ListGroupItem,
} from 'reactstrap'
import '../styles/ErrorPopover.css'

export class ErrorPopover extends Component {
    constructor(props) {
        super(props)
        this.state = { open: false, mouseOver: false }
    }

    toggle(open, mouseOver) {
        this.setState({ open, mouseOver })
    }

    render() {
        const { open } = this.state
        const {
            errors,
            errorsNotIgnorable,
            rowNum,
            colNum,
            ignoreErrors,
            selectChoice,
        } = this.props
        const uniqueId = `row-${rowNum}-col-${colNum}`

        return (
            <Fragment>
                <Button
                    outline
                    size="sm"
                    className="super-small border-0 custom-btn-outline-danger allow-focus"
                    id={uniqueId}
                    onMouseEnter={() => this.toggle(true, false)}
                    onMouseLeave={e => {
                        // try and do it based on timing - all we need is a 50ms delay to
                        // allow a mouse cursor to potentially enter the popover
                        setTimeout(() => {
                            const { mouseOver } = this.state
                            if (mouseOver) {
                                this.toggle(true, false)
                            } else {
                                this.toggle(false, false)
                            }
                        }, 50)
                    }}
                >
                    !
                </Button>
                <Popover
                    placement="right-end"
                    isOpen={open}
                    target={uniqueId}
                    onMouseEnter={() => this.toggle(true, true)}
                    onMouseLeave={() => this.toggle(false, false)}
                    className="admin-tool-font"
                >
                    <PopoverHeader className="border-bottom-0 popover-header-color admin-tool-font">
                        {`${errors.length} ${
                            errors.length > 1 ? 'errors' : 'error'
                        } detected!`}
                    </PopoverHeader>
                    <PopoverBody className="popover-body-color admin-tool-font">
                        <ListGroup flush className="scroll-group">
                            {errors.map((error, idx) => (
                                <ListGroupItem
                                    className="outer-popover-body-color"
                                    action
                                    key={idx}
                                >
                                    <strong>{error.header}:</strong>
                                    <br />
                                    {error.err_type === 'enumeration' ? (
                                        <ListGroup flush>
                                            {error.message
                                                .split('\n')
                                                .map((choice, idx) => (
                                                    <ListGroupItem
                                                        className="popover-body-color"
                                                        action
                                                        key={idx}
                                                        onClick={() => {
                                                            selectChoice(choice)
                                                        }}
                                                    >
                                                        <i>{choice}</i>
                                                    </ListGroupItem>
                                                ))}
                                        </ListGroup>
                                    ) : (
                                        <span>{error.message}</span>
                                    )}
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    </PopoverBody>
                    <PopoverHeader className="text-right border-top popover-header-color">
                        <Button
                            outline
                            disabled={errorsNotIgnorable}
                            className="border-0 custom-btn-outline-danger"
                            size="sm"
                            onClick={() => {
                                ignoreErrors()
                            }}
                        >
                            {errorsNotIgnorable
                                ? 'Cannot Ignore'
                                : `Ignore ${errors.length > 1 ? 'All' : ''}`}
                        </Button>
                    </PopoverHeader>
                </Popover>
            </Fragment>
        )
    }
}

ErrorPopover.propTypes = {
    errors: PropTypes.arrayOf(PropTypes.object).isRequired,
    rowNum: PropTypes.number.isRequired,
    colNum: PropTypes.number.isRequired,
    ignoreErrors: PropTypes.func.isRequired,
}
