import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
    Button,
    InputGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export class SearchBar extends PureComponent {
    state = {
        currentInput: ''
    }

    onInput = (e) => {
        this.setInput(e.target.value)
    }

    clearInput = () => {
        this.setInput('')
    }

    setInput = (newVal) => {
        const { handleSearch } = this.props
        
        this.setState({currentInput: newVal})
        handleSearch(newVal)
    }

    render() {
        return (
            <InputGroup size="sm" className="pl-1 pr-1">
                <InputGroupAddon addonType="prepend">
                    <InputGroupText
                        className="border border-right-0 bg-white text-muted">
                        <FontAwesomeIcon icon="search" />
                    </InputGroupText>
                </InputGroupAddon>
                <Input
                    className={`border border-left-0 border-right-0`}
                    placeholder="Search..."
                    onChange={this.onInput}
                    value={this.state.currentInput}
                />
                <InputGroupAddon addonType="append">
                    <Button
                        outline
                        disabled={!this.state.currentInput}
                        className={`border border-left-0 no-background no-focus-outline text-muted search-bar-btn-holder`}
                        onClick={this.clearInput}
                    >
                        {this.state.currentInput && 'X'}
                    </Button>
                </InputGroupAddon>
            </InputGroup>
        )
    }
}

SearchBar.propTypes = {
    handleSearch: PropTypes.func.isRequired,
}
