import React, { Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'reactstrap'
import { connect } from 'react-redux'
import { undo, redo } from '../actions/validate'
import { getRows } from '../helpers'
import '../styles/UndoRedo.css'

const ShownUndoRedo = ({ canUndo, canRedo, undo, redo }) => (
    <Fragment>
        <div className="undo-button">
            <Button
                outline
                disabled={!canUndo}
                className="text-center fixed-size-btn border-0 no-focus-outline"
                onClick={() => undo()}
            >
                <FontAwesomeIcon icon="undo" />
            </Button>
        </div>
        <div className="redo-button">
            <Button
                outline
                disabled={!canRedo}
                className="text-center fixed-size-btn border-0 no-focus-outline"
                onClick={() => redo()}
            >
                <FontAwesomeIcon icon="redo" />
            </Button>
        </div>
    </Fragment>
)

export const UndoRedo = connect(
    state => ({
        canUndo:
            state.undoState < 4 &&
            state.undoState > -1 &&
            getRows(state, state.undoState + 1).length !== 0,
        canRedo: state.undoState > 0,
    }),
    dispatch => ({
        undo: () => dispatch(undo()),
        redo: () => dispatch(redo()),
    }),
)(ShownUndoRedo)
