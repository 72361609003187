import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap'

import { StatusBox } from './StatusBox'
import {
    PossibleStatus,
    PossibleConflictStatus,
    PossibleMergeStatus,
} from '../actions/companies'
import '../styles/CompanyHeader.css'

export const CompanyHeader = ({
    name,
    pendingStatus,
    conflictStatus,
    mergeStatus,
}) => (
        <h4 className="mb-0 align-self-center">
            {conflictStatus || mergeStatus}
        </h4>
    )

CompanyHeader.propTypes = {
    name: PropTypes.string.isRequired,
    pendingStatus: PropTypes.oneOf(Object.values(PossibleStatus)).isRequired,
    conflictStatus: PropTypes.oneOf(Object.values(PossibleConflictStatus)),
    mergeStatus: PropTypes.oneOf(Object.values(PossibleMergeStatus)),
}
