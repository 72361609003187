export default {
    MODALS: {
        INGEST: {
            HEAD: 'Confirm Ingest',
            BODY: [
                `Ingest will complete the process of importing company data. 
                Ensure that all changes have been confirmed before proceeding. Data that is not marked
                "Ingest Ready" will not be imported into the dataset.`,
                'Click Cancel to continue working on this page.',
            ],
            OK_BUTTON: 'Ingest',
            CANCEL_BUTTON: 'Cancel',
        },
        INGEST_REPORT: {
            HEAD: 'Confirm Ingest',
            BODY: [
                `Ingest will complete the process of importing company data. 
                Ensure that all changes have been confirmed before proceeding. Data that is not marked
                "Ingest Ready" will not be imported into the dataset.`,
                'Click Cancel to continue working on this page.',
            ],
            OK_BUTTON: 'Ingest',
            CANCEL_BUTTON: 'Cancel',
        },
        SAVE: {
            HEAD: 'Confirm Save',
            BODY:
                'You are saving any changes that have been made so far. Please name your file below',
            OK_BUTTON: 'Save',
            CANCEL_BUTTON: 'Cancel',
            INVALID_FILE:
                'Invalid file name. Name can only include letters, numbers and/or underscores.',
        },
        MERGE: {
            HEAD: 'Merge All',
            BODY: [
                'Please click Merge All if you wish to merge all new changes.',
                'Click Cancel to continue working on this page.',
            ],
            OK_BUTTON: 'Merge All',
            CANCEL_BUTTON: 'Cancel',
        },
        UNMERGE: {
            HEAD: 'Unmerge All',
            BODY: [
                'Please click Unmerge All if you wish to unmerge all new changes.',
                'Click Cancel to continue working on this page.',
            ],
            OK_BUTTON: 'Unmerge All',
            CANCEL_BUTTON: 'Cancel',
        },
    },
}
