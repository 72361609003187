import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { toggleSkipRow } from '../actions/validate'
import '../styles/InteractiveTableRow.css'

export const ShownRowToggle = ({ disabled, toggleSkipRow }) => (
    <span className="border-0 skip-btn h-100 align-items-center justify-content-center d-flex">
        <input
            type="checkbox"
            checked={!disabled}
            onChange={() => toggleSkipRow()}
        />
    </span>
)

ShownRowToggle.propTypes = {
    disabled: PropTypes.bool.isRequired,
    toggleSkipRow: PropTypes.func.isRequired,
}

export const RowToggle = connect(
    // mapStateToProps
    (state, ownProps) => ({
        disabled: state.skippedRows.includes(ownProps.row),
    }),
    // mapDispatchToProps
    (dispatch, ownProps) => ({
        toggleSkipRow: () => dispatch(toggleSkipRow(ownProps.row)),
    }),
)(ShownRowToggle)
