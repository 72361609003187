import { connect } from 'react-redux'
import { ExpandingPanel } from '../components/ExpandingPanel'
import { filterCompanyList } from '../helpers'
const mapStateToProps = state => {
    const sortedCompanies = filterCompanyList(
        state.displayedCompanies,
        state.companyMeta,
        state.sortStatusField,
    )
    return {
        hidden: sortedCompanies.length === 0 ? true : false,
        conflicting: state.companyMeta[state.currSelectedCompany].isConflicting,
    }
}

export const ExpandingPanelContainer = connect(mapStateToProps)(ExpandingPanel)
