import { InteractiveTableOriginCell } from '../components/InteractiveTableOriginCell'
import { skipSelectedRows, clearSkippedRows } from '../actions/validate'
import { connect } from 'react-redux'
import { getRows } from '../helpers'

const mapStateToProps = state => {
    return {
        noneSkipped: state.skippedRows.length == 0,
        rowsArray: Array.from(
            Array(getRows(state, state.undoState).length).keys(),
        ),
    }
}

const mapDispatchToProps = dispatch => ({
    skipSelectedRows: selectRows => dispatch(skipSelectedRows(selectRows)),
    clearSkippedRows: () => dispatch(clearSkippedRows()),
})

export const InteractiveTableOriginCellContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
    }),
)(InteractiveTableOriginCell)
