import { connect } from 'react-redux'
import { SaveModal } from '../components/SaveModal'
import { saveCompanies } from '../actions/companies'
import { actionModalToggle } from '../actions/modals'

const mapDispatchToProps = dispatch => ({
    handleSave: filename => dispatch(saveCompanies(filename)),
    actionModalToggle: type => dispatch(actionModalToggle(type)),
})

export const SaveModalContainer = connect(
    null,
    mapDispatchToProps,
)(SaveModal)
