import { TableActions } from '../actions/table'

export const pageIndex = (state = 0, action) => {
    switch (action.type) {
        case TableActions.SET_PAGE_INDEX:
            return action.pageIdx

        default:
            return state
    }
}

export const pageSize = (state = 25, action) => {
    switch (action.type) {
        case TableActions.SET_PAGE_SIZE:
            return action.pageSize

        default:
            return state
    }
}

export const jumpIndex = (state = -1, action) => {
    switch (action.type) {
        case TableActions.SET_JUMP_INDEX:
            return action.errIdx

        default:
            return state
    }
}
