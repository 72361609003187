import { connect } from 'react-redux'
import { ActionModalContainer } from '../containers/ActionModalContainer'
import { actionModalToggle } from '../actions/modals'
import { mergeAll } from '../actions/merge'
import { generateModalBody } from '../helpers'
import locales from '../statics/locales'

const {
    MODALS: {
        MERGE: { HEAD, BODY, OK_BUTTON, CANCEL_BUTTON },
    },
} = locales

const mapStateToProps = ({ companies, ingested }) => {
    const newProps = {
        modalType: 'merge',
        head: HEAD,
        body: generateModalBody(BODY),
        okButton: OK_BUTTON,
        cancelButton: CANCEL_BUTTON,
    }
    const unMerged = companies.filter(id => !ingested.includes(id))
    return {
        unMerged,
        ...newProps,
    }
}

const mapDispatchToProps = dispatch => ({
    handleMergeAll: ids => dispatch(mergeAll(ids)),
    actionModalToggle: type => dispatch(actionModalToggle(type)),
})

export const MergeModalContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        onAcceptFunc: () => (
            dispatchProps.handleMergeAll(stateProps.unMerged),
            dispatchProps.actionModalToggle(stateProps.modalType)
        ),
    }),
)(ActionModalContainer)
