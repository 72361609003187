import React from 'react'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

import '../styles/JumpButton.css'

export const JumpButton = ({ direction, onClick, disabled }) => (
    <Button
        outline
        disabled={disabled}
        className="pt-0 pb-0 fixed-size-btn border-0"
        onClick={onClick}
    >
        <FontAwesomeIcon icon={`long-arrow-alt-${direction}`} />
    </Button>
)
