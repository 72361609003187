import { setPageIndex, setJumpIndex } from '../actions/table'
import { undo, redo } from '../actions/validate'
import { actionModalToggle } from '../actions/modals'
import { connect } from 'react-redux'
import { InteractiveTableFooter } from '../components/InteractiveTableFooter'
import { getRows } from '../helpers'

const sortList = errorsList => {
    let itemLoc = []
    let sortedErrorsList = []

    if (errorsList.slice(-1)[0] !== undefined) {
        errorsList.forEach(cur => {
            if (cur.errors.slice(-1)[0] === undefined) {
                return false
            } else {
                itemLoc.push({ row: cur.row, col: cur.col })
            }
        })
        if (itemLoc.slice(-1)[0] === undefined) {
        } else {
            sortedErrorsList = Object.values(itemLoc).sort((a, b) => {
                if (a.row < b.row) {
                    return -1
                } else if (a.row > b.row) {
                    return 1
                } else if (a.col < b.col) {
                    return -1
                } else if (a.col > b.col) {
                    return 1
                }
                return 0
            })
        }
    }
    return sortedErrorsList
}
const mapStateToProps = state => {
    return {
        rows: getRows(state, state.undoState),
        skippedRows: state.skippedRows,
        skippedCols: state.skippedCols,
        pageSize: state.pageSize,
        jumpIndex: state.jumpIndex,
        errorsList: sortList(
            state.itemErrors.filter(
                item =>
                    !(
                        state.skippedRows.includes(item.row) ||
                        state.skippedCols.includes(item.col)
                    ),
            ),
        ),
        pageIndex: state.pageIndex,
        canUndo:
            state.undoState < 4 &&
            state.undoState > -1 &&
            getRows(state, state.undoState + 1).length !== 0,
        canRedo: state.undoState > 0,
    }
}
const mapDispatchToProps = dispatch => ({
    setJumpIndex: errIdx => dispatch(setJumpIndex(errIdx)),
    setPageIndex: idx => dispatch(setPageIndex(idx)),
    undo: () => dispatch(undo()),
    redo: () => dispatch(redo()),
    actionModalToggle: type => dispatch(actionModalToggle(type)),
})

export const InteractiveTableFooterContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(InteractiveTableFooter)
