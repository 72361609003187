import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { truncateText, denormalizeColumnName } from '../helpers'
import { Button } from 'reactstrap'
import '../styles/ExpandingTile.css'
import { categoryKeys } from '../statics/keyMapping'

class ExpandingTile extends React.Component {
    state = {
        isOpen: false,
    }

    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }

    componentDidUpdate(prevProps, prevState) {
        const { activeId } = this.props
        if (prevProps.activeId !== activeId && prevState.isOpen === true) {
            this.toggle()
        }
    }

    render() {
        const {
            merged,
            value,
            conflicting: propConf,
            fieldName,
            optionMappings,
        } = this.props
        const colorClass = merged ? 'merged' : 'normal'
        const isOpen = this.state.isOpen
        const isTruncated = truncateText(value).truncated
        const toggleShow = truncateText(value).truncated
            ? !isOpen
                ? '\u25be Show more'
                : '\u25b4 Show less'
            : ''
        const toggleText = truncateText(value).truncated
            ? !isOpen
                ? truncateText(value).text
                : value
            : value
        const isEmptyText = value.length === 0
        const conflicting = propConf
        const mergedClass = conflicting ? colorClass : ''
        const isEmptyTextClass = isEmptyText ? 'empty-text align-center' : ''
        const fieldTitle = optionMappings[fieldName]
            ? optionMappings[fieldName]
            : denormalizeColumnName(fieldName)

        return (
            <div
                className={`${
                    isTruncated ? 'tile-truncated' : 'tile'
                } flex-grow-1 align-self-center`}
            >
                <span className="font-weight-bold">{fieldTitle}</span>
                <Fragment>
                    <div>
                        <span
                            className={`${mergedClass} ${isEmptyTextClass}
                        word-break rounded`}
                        >
                            {toggleText}
                        </span>
                    </div>
                    {isTruncated && (
                        <Button
                            className="show-more-btn"
                            color="link"
                            tabIndex="-1"
                            onClick={() => this.toggle()}
                        >
                            {toggleShow}
                        </Button>
                    )}
                </Fragment>
            </div>
        )
    }
}

ExpandingTile.propTypes = {
    fieldName: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
    return {
        activeId: state.currSelectedCompany,
        optionMappings: state.optionMappings,
    }
}

export default connect(mapStateToProps)(ExpandingTile)
