import { connect } from 'react-redux'
import { NewPanelPane } from '../components/NewPanelPane'
import { filterId } from '../helpers'
import { PossibleStatus } from '../actions/companies'

const mapStateToProps = state => {
    const id = state.currSelectedCompany
    return {
        name: state.companyMeta[state.currSelectedCompany].name,
        pendingStatus: state.companyMeta[state.currSelectedCompany].status,
        fieldsObj: filterId(
            state.currSelectedCompany,
            state.companyData[state.currSelectedCompany],
        ),
        noChanges:
            state.companyMeta[id].status === PossibleStatus.INGESTED ||
            state.companyMeta[id].status === PossibleStatus.WARNING ||
            state.companyMeta[id].status === PossibleStatus.ERROR,
        errors: state.companyMeta[state.currSelectedCompany].errors,
    }
}

export const NewPanelPaneContainer = connect(mapStateToProps)(NewPanelPane)
