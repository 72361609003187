import { connect } from 'react-redux'
import { ValidateTool } from '../components/ValidateTool'
import { fetchServerCompanyData } from '../actions/companies'
import { getRows } from '../helpers'

const mapStateToProps = state => {
    const rows = getRows(state, state.undoState)
    return {
        fetching: state.fetching.fetching,
        fetchingMsg: state.fetching.msg,
        errors: state.errors,
        tableExists: rows.length > 0,
    }
}
const mapDispatchToProps = dispatch => ({
    fetchServerCompanyData: () => dispatch(fetchServerCompanyData()),
})
export const ValidateToolContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ValidateTool)
