import config from '../config'

function AuthException(message) {
    this.message = message
    this.code = 401
}

function FailureException(message) {
    this.message = message
    this.code = 500
}

export class ClientProxy {
    constructor(opt = {}) {
        this.baseUrl = window.location.origin

        // set defaults to null instead of '' because '' could be a valid option
        this.prefix = opt.withPrefix || null
        this.forcedOrigin = opt.forceOrigin || null
        this.passCredentials = opt.passCredentials ? true : false
    }

    static throwErrors(res) {
        if (res.status === 401) {
            throw new AuthException(
                'Invalid token.  Either your session has timed out, or someone has logged in using the same credentials.',
            )
        } else if (!res.ok) {
            throw new FailureException('Internal server error.')
        }
        return res
    }

    formUrl(path) {
        if (this.prefix !== null) {
            if (this.forcedOrigin !== null) {
                return `${this.forcedOrigin}${this.prefix}${path}`
            } else {
                return `${this.baseUrl}${this.prefix}${path}`
            }
        } else {
            if (this.forcedOrigin !== null) {
                return `${this.forcedOrigin}${path}`
            } else {
                return `${this.baseUrl}${path}`
            }
        }
    }

    perform(url, fetchOptions = {}) {
        const { responseType, ...restFetchOptions } = fetchOptions
        const parsedUrl = new URL(this.formUrl(url))
        const credentials = this.passCredentials
            ? { credentials: 'include' }
            : {}
        return fetch(parsedUrl, {
            ...credentials,
            ...restFetchOptions,
        })
            .then(ClientProxy.throwErrors)
            .then(res => {
                if (responseType === 'text/csv') {
                    return res.blob()
                } else {
                    return res.json()
                }
            })
            .catch(err => err)
    }
}

const { ip, port, protocol, env } = config

// flaskProxy for internal server stuff
const flaskOptions =
    env === 'development'
        ? { forceOrigin: '' }
        : {
              //   withPrefix: '/importer',
              passCredentials: true,
              forceOrigin: `${protocol}://${window.location.hostname}${
                  port !== '' ? `:${port}` : ''
              }`,
          }
export const flaskProxy = new ClientProxy(flaskOptions)
