import React, { Component, Fragment } from 'react'
import { Button, Input, Table } from 'reactstrap'
import { denormalizeColumnName } from '../helpers.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import '../styles/InteractiveTableHeaderItem.css'
import '../styles/InteractiveTableHeaderInput.css'

export class InteractiveTableHeaderInput extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchString: '',
            dropDown: false,
            width: null,
            left: null,
            bottom: null,
        }
        this.inputRef = ref => (this.containerNode = ref)
        this.dropDownContainer = ref => (this.dropDownNode = ref)
        this.searchField = React.createRef()
    }

    handleChange = event => {
        this.setState({
            searchString: event.target.value,
        })
    }

    handleClick = val => {
        this.setState({
            searchString: val,
            dropDown: false,
        })
    }

    toggleDropDown = e => {
        this.measure()

        if (!this.state.dropDown) {
            document.addEventListener('click', this.handleOutsideClick, false)
            document
                .querySelector('.rt-table')
                .addEventListener('scroll', this.closeDropDown)
        } else {
            document.removeEventListener(
                'click',
                this.handleOutsideClick,
                false,
            )
            document
                .querySelector('.rt-table')
                .removeEventListener('scroll', this.closeDropDown)
        }
        //set focus on seach search field if we are opening a dropDown
        this.setState(
            {
                dropDown: !this.state.dropDown,
                searchString: '',
            },
            () => {
                if (this.state.dropDown) {
                    this.searchField.current.focus()
                }
            },
        )
    }

    closeDropDown = () => {
        document.removeEventListener('click', this.handleOutsideClick, false)
        document
            .querySelector('.rt-table')
            .removeEventListener('scroll', this.closeDropDown)
        this.setState({
            dropDown: false,
            searchString: '',
        })
    }

    handleOutsideClick = e => {
        // ignore clicks on the component itself
        if (this.dropDownNode.contains(e.target)) {
            return
        }
        this.toggleDropDown(e)
    }

    //expand functionality for this in the future
    getOptions = options => {
        if (this.state.searchString) {
            const lowerSearchString = this.state.searchString.toLowerCase()
            let filtered = options.filter(
                option =>
                    (this.props.optionMappings[option] &&
                        this.props.optionMappings[option]
                            .toLowerCase()
                            .includes(lowerSearchString)) ||
                    option.includes(lowerSearchString),
            )
            return filtered
        } else {
            return options
        }
    }

    measure = () => {
        const containerInfo = this.containerNode.getBoundingClientRect()
        this.setState({
            width: containerInfo.width,
            left: containerInfo.left,
            bottom: containerInfo.bottom,
        })
    }

    componentDidMount() {
        document.body.appendChild(this.dropDownNode)
        this.measure()
        window.addEventListener('resize', this.measure)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.measure)
    }
    //Currently error popovers will shift every element down
    componentDidUpdate(prevProps) {
        if (prevProps.errorsLength !== this.props.errorsLength) {
            this.measure()
        }
    }

    render() {
        const {
            options,
            selected,
            col,
            handleSelectOption,
            colDisabled,
            resetItemErrorsCol,
            actionModalToggle,
            optionMappings,
            errorsLength,
            permanent,
        } = this.props
        const { width, left, bottom, dropDown, searchString } = this.state
        const cx = classNames(
            'admin-tool-font option-select',
            {
                'nonapplicable-option-select font-italic': colDisabled,
            },
            {
                'no-click': permanent,
            },
        )
        return (
            <Fragment>
                <Input
                    onClick={this.toggleDropDown}
                    id={'inputBox-' + col}
                    type="select"
                    defaultValue="init"
                    className={cx}
                    innerRef={this.inputRef}
                >
                    <option value="init" disabled hidden>
                        {optionMappings[selected]
                            ? optionMappings[selected]
                            : denormalizeColumnName(selected)}
                    </option>
                </Input>
                <div
                    className="popoverModified"
                    style={{
                        top: '0px',
                        left: '0px',
                        width: width + 'px',
                        transform:
                            'translate3d(' + left + 'px,' + bottom + 'px,0px)',
                    }}
                    ref={this.dropDownContainer}
                >
                    {dropDown && (
                        <div>
                            <Input
                                id="inputSearch"
                                type="text"
                                value={searchString}
                                onChange={this.handleChange}
                                className="border-0 no-focus style-font"
                                placeholder="Search"
                                innerRef={this.searchField}
                            />
                            <span id="search-icon" className="overlap-icon">
                                <FontAwesomeIcon icon="search" />
                            </span>
                            <Table hover className="options-box cursor-pointer">
                                <tbody className="w-100 tbody-style">
                                    <tr
                                        onClick={e => {
                                            handleSelectOption('Not Applicable')
                                            resetItemErrorsCol(col)
                                            this.toggleDropDown(e)
                                        }}
                                        className="nonapplicable-option font-italic"
                                    >
                                        <td className="data-padding">
                                            Not Applicable
                                        </td>
                                    </tr>
                                    {this.getOptions(options).map(
                                        (option, idx) => (
                                            <tr
                                                key={option + '-' + idx}
                                                onClick={e => {
                                                    handleSelectOption(option)
                                                    resetItemErrorsCol(col)
                                                    this.toggleDropDown(e)
                                                }}
                                            >
                                                <td className="data-padding">
                                                    {optionMappings[option]
                                                        ? optionMappings[option]
                                                        : denormalizeColumnName(
                                                              option,
                                                          )}
                                                </td>
                                            </tr>
                                        ),
                                    )}
                                </tbody>
                            </Table>
                            <div
                                className="text-center border-bottom cursor-pointer"
                                onClick={e => {
                                    actionModalToggle('addColumn', col)
                                    this.toggleDropDown(e)
                                }}
                            >
                                <span id="search-icon">
                                    <FontAwesomeIcon icon="plus-circle" /> Add
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </Fragment>
        )
    }
}
