import { connect } from 'react-redux'
import { FieldMergeTile } from '../components/FieldMergeTile'
import { mergeField, unmergeField } from '../actions/merge'
import { categoryKeys } from '../statics/keyMapping'

const setIncomingVal = (company, field) => {
    if (company[field]) {
        return company[field]
    } else {
        return ''
    }
}

const setCurrVal = (company, field) => {
    if (company[field]) {
        if (field === 'category' && categoryKeys[company[field]]) {
            return categoryKeys[company[field]].label
        } else {
            return company[field]
        }
    } else {
        return 'N/A'
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = state.currSelectedCompany
    const { merges, errors } = state.companyMeta[id]

    //this may be problematic for nwely added columns
    const incomingVal = setIncomingVal(
        state.companyData[id],
        ownProps.fieldName,
    )
    const currVal = setCurrVal(state.companyDataCurrent[id], ownProps.fieldName)
    return {
        activeId: id,
        fieldName: ownProps.fieldName,
        disableHalf: ownProps.disableHalf,
        incomingVal: incomingVal,
        currVal: merges.includes(ownProps.fieldName) ? incomingVal : currVal,
        error: errors.includes(ownProps.fieldName),
        conflicting: incomingVal !== currVal,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    handleMerge: id => dispatch(mergeField(id, ownProps.fieldName)),
    handleUnmerge: id => dispatch(unmergeField(id, ownProps.fieldName)),
})

export const FieldMergeTileContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    // mergeProps
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        handleMerge: () => {
            stateProps.incomingVal !== stateProps.currVal
                ? dispatchProps.handleMerge(stateProps.activeId)
                : dispatchProps.handleUnmerge(stateProps.activeId)
        },
    }),
)(FieldMergeTile)
