import React from 'react'
import {
    fetchCompaniesBegin,
    fetchCompaniesEnd,
    batchMerge,
    addCompanyIds,
    addCompanyDataBatch,
    changeCompanyStatus,
    PossibleStatus,
    addCompanySuggestions,
    addServerCompanyData,
    stageIngested,
    changeCompanyStatuses,
    addIngestErrors,
    CompanyActions,
    unstageForIngest,
} from '../actions/companies'
import { setMessageModal, ModalActions } from '../actions/modals'
import {
    showErrors,
    clearErrors,
    showRows,
    skipSelectedRows,
} from '../actions/validate'
import { delay } from 'redux-saga'
import { put, call, apply, take, all, select } from 'redux-saga/effects'
import _ from 'lodash'
import {
    normalizeData,
    emptyCompanyObj,
    filterSaveRows,
    isExtendedKey,
    normalizeExtendedColumnName,
    normalizeIngest,
} from '../helpers'
import { navigateTo } from '../routes/history'
import config from '../config'
import { categoryKeys, ingestKeys } from '../statics/keyMapping'
import {
    getServerCompanyData,
    getCompanyData,
    getCompanyMeta,
    getIngested,
    getSkippedRows,
    getRows0,
    getSelectedOptions,
    getUserAffiliationId,
    getAffiliations,
} from './selectors'
import { IngestReport } from '../components/IngestReport'
import { callFlaskProxy } from './utils'

export function* fetchServerCompanyDataHandler() {
    yield put(fetchCompaniesBegin('Retrieving company data...'))
    const target = `/services/company/find?limit=1000000000000`
    const fetchResult = yield callFlaskProxy(
        `/api/proxy?target_url=${target}`,
        {
            method: 'GET',
            credentials: 'include',
        },
    )
    if (fetchResult && fetchResult.status === 200) {
        let normalizedCompanyNames = []
        normalizedCompanyNames = fetchResult.data.results.map(comp => ({
            name: comp.name,
            normalizedName: comp.name.replace(/\s/g, '').toUpperCase(),
            normalizedLegalName: comp.legal_name
                ? comp.legal_name.replace(/\s/g, '').toUpperCase()
                : '',
        }))
        yield put(
            addServerCompanyData(
                fetchResult.data.results,
                normalizedCompanyNames,
            ),
        )
        // yield put(addNormalizedServerCompanyNames())
    } else {
        yield put(
            showErrors([
                {
                    type: 'danger',
                    message: 'Failed to get existing company data',
                },
            ]),
        )
    }
    yield put(fetchCompaniesEnd())
}
export function* getCurrentDataHandler(action, companyData) {
    yield call(delay, 2000)
    yield put(
        fetchCompaniesBegin(
            'Validation successful!  Comparing new data to existing data...',
        ),
    )

    // if (fetchResult && fetchResult.status === 200) {
    const userAffiliationId = yield select(getUserAffiliationId)
    const affiliations = yield select(getAffiliations)
    const fetchResult = yield select(getServerCompanyData)
    const batch = []
    let unmerged = []
    let exactMatch = false
    const ignoreKeys = { name: true, id: true }
    for (let data of Object.values(companyData)) {
        data = normalizeData(data)
        let match = fetchResult.find(
            res =>
                res.name.replace(/\s/g, '').toUpperCase() ===
                data.name.replace(/\s/g, '').toUpperCase(),
        )
        if (match) {
            // normalize data first so we can compare api data and imported data
            // without worrying about extraneous things like empty spaces being represented as null,
            // ', ' vs ',' etc.
            match = normalizeData(match, affiliations, userAffiliationId)
            // if we find an exact match, update the status
            exactMatch = _.every(data, (val, key) => {
                // key !== 'id' ? _.isEqual(val, match[key]) : true, // ignore key when checking for exact match
                if (key !== 'id') {
                    if (key === 'category') {
                        let label = categoryKeys[match[key]]
                            ? categoryKeys[match[key]].label
                            : match[key]
                        return _.isEqual(val, label)
                    } else {
                        return _.isEqual(val, match[key])
                    }
                } else {
                    return true
                }
            })
            if (exactMatch) {
                batch.push({
                    id: data.id,
                    conflicting: true,
                    status: PossibleStatus.NO_CHANGES,
                    isExactMatch: true,
                    data: match,
                    mtime: match.mtime,
                    dbid: match.id,
                })
            } else {
                unmerged = Object.keys(data).filter(
                    key =>
                        !match[key] ||
                        (data[key] !== match[key] && !(key in ignoreKeys)),
                )

                batch.push({
                    id: data.id,
                    conflicting: true,
                    data: match,
                    mtime: match.mtime,
                    dbid: match.id,
                    unmerged,
                })
            }
        } else {
            // found new company
            batch.push({
                id: data.id,
                conflicting: false,
                data: { ...emptyCompanyObj },
            })
        }
    }
    const idsToShow = batch.map(item => item.id)
    yield put(batchMerge(batch))
    yield put(addCompanyIds(idsToShow))
    yield put(addCompanyDataBatch(companyData))
    yield put(clearErrors())
    navigateTo('/merge')
    // } else {
    //     yield put(
    //         showErrors([
    //             {
    //                 type: 'danger',
    //                 message: 'Failed to fetch existing companies.',
    //             },
    //         ]),
    //     )
    // }
    yield put(fetchCompaniesEnd())
}
export function* ingestCompaniesHandler(action) {
    yield put(fetchCompaniesBegin('Ingesting companies into database...'))
    const userAffiliationId = yield select(getUserAffiliationId)
    const form = new FormData()
    let newIngest = action.toIngest
    for (let obj in newIngest) {
        for (let key in newIngest[obj]) {
            switch (key) {
                case 'category':
                    newIngest[obj][key] = categoryKeys[newIngest[obj][key]]
                        ? categoryKeys[newIngest[obj][key]].label
                        : newIngest[obj][key]
                    break
                case 'affiliate':
                    if (newIngest[obj][key] === 'true') {
                        newIngest[obj][key] = [userAffiliationId]
                    } else {
                        //ensure we are not unaffiliating a brand new company
                        if ('id' in newIngest[obj]) {
                            newIngest[obj]['unaffiliate'] = [userAffiliationId]
                        }
                        delete newIngest[obj][key]
                    }
                    break
            }
        }
    }
    form.append('companies', JSON.stringify(newIngest))
    const target = '/services/admin/company/upd_batch'
    const res = yield callFlaskProxy(`/api/proxy?target_url=${target}`, {
        method: 'POST',
        credentials: 'include',
        body: form,
    })

    yield put(fetchCompaniesEnd())
    try {
        if (typeof res === 'object' && res.status === 200) {
            const ingest = res.data
            const companyMeta = yield select(getCompanyMeta)
            const companyData = yield select(getCompanyData)
            let companyMetaCopy = _.cloneDeep(companyMeta)
            let companyDataCopy = _.cloneDeep(companyData)
            const statuses = [],
                errors = [],
                successIds = [],
                warningIds = [],
                errorIds = []

            const normalizedIngest = normalizeIngest(
                ingest,
                Object.values(companyMetaCopy),
                userAffiliationId,
            )

            const [newCompanies, existingCompanies] = _.partition(
                Object.values(companyMetaCopy),
                ['dbId', undefined],
            )

            const [newIngest, existingIngest] = _.partition(normalizedIngest, [
                'ingestId',
                null,
            ])
            const newMerged = _.merge(
                _.keyBy(newCompanies, 'name'),
                _.keyBy(newIngest, 'ingestName'),
            )
            const existingMerged = _.merge(
                _.keyBy(existingCompanies, 'dbId'),
                _.keyBy(existingIngest, 'ingestId'),
            )
            const merged = [
                ...Object.values(newMerged),
                ...Object.values(existingMerged),
            ]
            merged.forEach(obj => {
                if (obj.ingestStatus === PossibleStatus.INGESTED) {
                    statuses.push({
                        id: obj.id,
                        status: PossibleStatus.INGESTED,
                    })
                    successIds.push(obj.id)
                } else if (obj.ingestStatus === PossibleStatus.WARNING) {
                    statuses.push({
                        id: obj.id,
                        status: PossibleStatus.WARNING,
                    })
                    errors.push({ id: obj.id, errors: obj.ingestErrorKeys })
                    warningIds.push(obj.id)
                } else if (obj.ingestStatus === PossibleStatus.ERROR) {
                    statuses.push({ id: obj.id, status: PossibleStatus.ERROR })
                    errors.push({ id: obj.id, errors: obj.ingestErrorKeys })
                    errorIds.push(obj.id)
                }
            })
            const head = `Ingest Status`
            const body = (
                <IngestReport
                    report={{
                        [PossibleStatus.INGESTED]: successIds.length,
                        [PossibleStatus.WARNING]: warningIds.length,
                        [PossibleStatus.ERROR]: errorIds.length,
                    }}
                />
            )

            yield put(
                stageIngested([...successIds, ...warningIds, ...errorIds]),
            )
            // yield put(unstageForIngest([...warningIds, ...errorIds]))
            yield put(changeCompanyStatuses(statuses))
            yield put(addIngestErrors(errors))
            yield put(setMessageModal(true, { head, body }))
            yield take(ModalActions.CONFIRM_MODAL_ACTION)
            // From directory meeting Roy noted this behaviour is confusing and should be disabled for now
            //until it is more clear
            // const skippedRows = yield select(getSkippedRows)
            // const rows = yield select(getRows0)
            // const selectedOptions = yield select(getSelectedOptions)
            // successIds.forEach(id => {
            //     delete companyDataCopy[id]
            // })
            // const { newRows, newRowsSkipped } = filterSaveRows(
            //     rows,
            //     companyDataCopy,
            //     skippedRows,
            //     selectedOptions,
            // )
            // yield put(showRows(newRows))
            // yield put(skipSelectedRows(newRowsSkipped))
            // navigateTo('/')
        } else {
            throw new Error('Returned response was not an object')
        }
    } catch (e) {
        // Failed to update companies
        const head = 'Notice'
        const body = 'A problem occured while attempting to ingest'
        yield put(setMessageModal(true, { head, body }))
        console.log(e)
    }
    // yield call(delay, 5000)
    // yield put(clearErrors())
}
export function* saveCompanies(action) {
    try {
        const rows = yield select(getRows0)
        const skippedRows = yield select(getSkippedRows)
        const selectedOptions = yield select(getSelectedOptions)
        const form = new FormData()
        const filteredRows = rows.filter(
            (row, index) => !skippedRows.includes(index),
        )
        const companies = filteredRows.map((row, index) => {
            let newCompany = {}
            selectedOptions.forEach((option, index) => {
                newCompany[option] = row[index]
            })
            return newCompany
        })
        form.append('companies', JSON.stringify(companies))
        const fetchResult = yield callFlaskProxy('/api/save', {
            method: 'POST',
            body: form,
            responseType: 'text/csv',
        })
        if (typeof fetchResult === 'object') {
            const url = window.URL.createObjectURL(fetchResult)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${action.filename}.csv`)
            document.body.appendChild(link)
            link.click()
        } else {
            throw new Error('Returned response was not an object')
        }
    } catch (e) {
        const head = 'Notice'
        const body = 'A problem occured while attempting to save progress'
        yield put(setMessageModal(true, { head, body }))
        console.log(e)
    }
}
