import { connect } from 'react-redux'
import { ingestCompanies } from '../actions/companies'
import { actionModalToggle } from '../actions/modals'
import { organizeKeysForIngest } from '../helpers'
import locales from '../statics/locales'
import { ActionModalContainer } from '../containers/ActionModalContainer'
import { generateModalBody } from '../helpers'

const {
    MODALS: {
        INGEST: { HEAD, BODY, OK_BUTTON, CANCEL_BUTTON },
    },
} = locales

const buildIngestionObject = (staged, meta, data, userAffiliationId) => {
    return staged.map(id =>
        meta[id].isConflicting
            ? {
                  id: meta[id].dbId,
                  mtime: meta[id].mtime,
                  ...organizeKeysForIngest(
                      data[id],
                      meta[id].merges,
                      userAffiliationId,
                  ),
              }
            : {
                  ...organizeKeysForIngest(
                      data[id],
                      Object.keys(data[id]),
                      userAffiliationId,
                  ),
              },
    )
}

const mapStateToProps = state => {
    const newProps = {
        modalType: 'ingest',
        head: HEAD,
        body: generateModalBody(BODY),
        okButton: OK_BUTTON,
        cancelButton: CANCEL_BUTTON,
    }
    return {
        toIngest: buildIngestionObject(
            state.stagedForIngest,
            state.companyMeta,
            state.companyData,
            state.userAffiliationId,
        ),
        ...newProps,
    }
}

const mapDispatchToProps = dispatch => ({
    handleIngest: toIngest => dispatch(ingestCompanies(toIngest)),
    actionModalToggle: type => dispatch(actionModalToggle(type)),
})

export const IngestModalContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        onAcceptFunc: () => (
            dispatchProps.handleIngest(stateProps.toIngest),
            dispatchProps.actionModalToggle(stateProps.modalType)
        ),
    }),
)(ActionModalContainer)
