import React from 'react'

export const BadToken = () => (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <h5>
            Invalid token. Either your session has timed out, or someone has
            logged in using the same credentials. Please refresh.
        </h5>
    </div>
)
