export const getApp = state => state.app

export const getServerCompanyData = state => state.serverCompanyData

export const getCompanyData = state => state.companyData

export const getCompanyMeta = state => state.companyMeta

export const getIngested = state => state.ingested

export const getSkippedRows = state => state.skippedRows

export const getRows0 = state => state.rows0

export const getSelectedOptions = state => state.selectedOptions

export const getUserAffiliationId = state => state.userAffiliationId

export const getAffiliations = state => state.affiliations

