import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { connect } from 'react-redux'
import { setMessageModal, confirmModalAction } from '../actions/modals'

class MessageModal extends React.PureComponent {
    acknowledgeModal = () => {
        const { closeModal, confirmModal } = this.props
        closeModal()
        confirmModal()
    }

    render() {
        const {
            isOpen,
            data: { head, body },
        } = this.props

        return (
            <Modal isOpen={isOpen}>
                <ModalHeader>{head}</ModalHeader>
                <ModalBody>{body}</ModalBody>
                <ModalFooter>
                    <Button
                        className="btn-colored btn-green fixed-height-btn admin-tool-font no-focus-outline"
                        onClick={this.acknowledgeModal}
                    >
                        OK
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        closeModal() {
            return dispatch(setMessageModal(false, {}))
        },
        confirmModal() {
            return dispatch(confirmModalAction())
        },
    }
}

function mapStateToProps({ modals }) {
    return {
        isOpen: modals.isOpen,
        data: modals.data,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MessageModal)
