import React from 'react'
import PropTypes from 'prop-types'
import '../styles/OptionalDisable.css'

export const OptionalDisable = ({ disabled, className, children }) => (
    <div className={`${className} ${disabled && 'disabled'}`}>{children}</div>
)

OptionalDisable.propTypes = {
    disabled: PropTypes.bool.isRequired,
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]),
}
