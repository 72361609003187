export const ValidatorActions = {
    SHOW_ERRORS: 'SHOW_ERRORS',
    SHOW_FILENAMES: 'SHOW_FILENAMES',
    SHOW_FILES_DATA: 'SHOW_FILES_DATA',
    SHOW_SELECTED_FILE: 'SHOW_SELECTED_FILE',
    SHOW_ROWS: 'SHOW_ROWS',
    UPDATE_CELL_DATA: 'UPDATE_CELL_DATA',
    SHOW_OPTIONS: 'SHOW_OPTIONS',
    SHOW_OPTION_MAPPINGS: 'SHOW_OPTION_MAPPINGS',
    ADD_OPTION_MAPPING: 'ADD_OPTION_MAPPING',
    SHOW_SELECTED_OPTIONS: 'SHOW_SELECTED_OPTIONS',
    SKIP_SELECTED_COLS: 'SKIP_SELECTED_COLS',
    SKIP_SELECTED_ROWS: 'SKIP_SELECTED_ROWS',
    SHOW_REQUIRED_FIELDS: 'SHOW_REQUIRED_FIELDS',
    SELECT_OPTION: 'SELECT_OPTION',
    TOGGLE_SKIP_ROW: 'TOGGLE_SKIP_ROW',
    SKIP_COL: 'SKIP_COL',
    UNSKIP_COL: 'UNSKIP_COL',
    CLEAR_SKIPPED_ROWS: 'CLEAR_SKIPPED_ROWS',
    CLEAR_SKIPPED_COLS: 'CLEAR_SKIPPED_COLS',
    UPLOAD_FILE: 'UPLOAD_FILE',
    DELETE_FILE: 'DELETE_FILE',
    SELECT_FILE: 'SELECT_FILE',
    ADD_ITEM_ERRORS: 'ADD_ITEM_ERRORS',
    CLEAR_ITEM_ERRORS: 'CLEAR_ITEM_ERRORS',
    ADD_ITEM_ERRORS_COL: 'ADD_ITEM_ERRORS_COL',
    RESET_ITEM_ERRORS_COL: 'RESET_ITEM_ERRORS_COL',
    GET_ITEM_ERRORS: 'GET_ITEM_ERRORS',
    CHECK_ITEMS_COL: 'CHECK_ITEMS_COL',
    INFER_SETTINGS: 'INFER_SETTINGS',
    START_BATCH_UPDATE: 'START_BATCH_UPDATE',
    BATCH_UPDATE_COLUMN: 'BATCH_UPDATE_COLUMN',
    ADD_COL_DATA_TYPE: 'ADD_COL_DATA_TYPE',
    TRY_AND_PROCEED_TO_MERGE_STATE: 'TRY_AND_PROCEED_TO_MERGE_STATE',
    UNDO: 'UNDO',
    REDO: 'REDO',
    ADD_NEW_COLUMN: 'ADD_NEW_COLUMN',
    CLEAR_ROWS_HISTORY: 'CLEAR_ROWS_HISTORY',
}

export const showErrors = errors => ({
    type: ValidatorActions.SHOW_ERRORS,
    errors,
})

export const clearErrors = () => showErrors([])

export const clearItemErrors = () => ({
    type: ValidatorActions.CLEAR_ITEM_ERRORS,
})

export const undo = () => ({
    type: ValidatorActions.UNDO,
})

export const redo = () => ({
    type: ValidatorActions.REDO,
})

export const showSelectedFile = fn => ({
    type: ValidatorActions.SHOW_SELECTED_FILE,
    fn,
})

export const clearSkippedRows = () => ({
    type: ValidatorActions.CLEAR_SKIPPED_ROWS,
})

export const clearSkippedCols = () => ({
    type: ValidatorActions.CLEAR_SKIPPED_COLS,
})

export const showFilenames = filenames => ({
    type: ValidatorActions.SHOW_FILENAMES,
    filenames,
})

export const showFilesData = filesData => ({
    type: ValidatorActions.SHOW_FILES_DATA,
    filesData,
})

export const showRows = rows => ({
    type: ValidatorActions.SHOW_ROWS,
    rows,
})

export const updateCellData = (row, col, data) => ({
    type: ValidatorActions.UPDATE_CELL_DATA,
    row,
    col,
    data,
})

export const showOptions = options => ({
    type: ValidatorActions.SHOW_OPTIONS,
    options,
})

export const showOptionMappings = options => ({
    type: ValidatorActions.SHOW_OPTION_MAPPINGS,
    options,
})

export const addOptionMapping = (option, label) => ({
    type: ValidatorActions.ADD_OPTION_MAPPING,
    option,
    label,
})

export const showSelectedOptions = selectedOptions => ({
    type: ValidatorActions.SHOW_SELECTED_OPTIONS,
    selectedOptions,
})

export const skipSelectedCols = skippedCols => ({
    type: ValidatorActions.SKIP_SELECTED_COLS,
    skippedCols,
})

export const skipSelectedRows = skippedRows => ({
    type: ValidatorActions.SKIP_SELECTED_ROWS,
    skippedRows,
})

export const showRequiredFields = requiredFields => ({
    type: ValidatorActions.SHOW_REQUIRED_FIELDS,
    requiredFields,
})

export const selectOption = (col, selection) => ({
    type: ValidatorActions.SELECT_OPTION,
    col,
    selection,
})

export const toggleSkipRow = row => ({
    type: ValidatorActions.TOGGLE_SKIP_ROW,
    row,
})

export const skipCol = col => ({
    type: ValidatorActions.SKIP_COL,
    col,
})

export const unskipCol = col => ({
    type: ValidatorActions.UNSKIP_COL,
    col,
})

export const addItemErrors = (row, col, errors) => ({
    type: ValidatorActions.ADD_ITEM_ERRORS,
    row,
    col,
    errors,
})

export const addItemErrorsCol = (col, errors) => ({
    type: ValidatorActions.ADD_ITEM_ERRORS_COL,
    col,
    errors,
})

export const resetItemErrorsCol = col => ({
    type: ValidatorActions.RESET_ITEM_ERRORS_COL,
    col,
})

export const addColDataType = (header, dataType, choices) => ({
    type: ValidatorActions.ADD_COL_DATA_TYPE,
    header,
    dataType,
    choices,
})

export const batchUpdateColumn = (batch, col, data, toSkip) => ({
    type: ValidatorActions.BATCH_UPDATE_COLUMN,
    batch,
    col,
    data,
    toSkip,
})

export const addNewColumn = (
    name,
    normalizedName,
    description,
    field_type,
    enum_options,
    range,
    encrypt,
    optional,
    col,
) => ({
    type: ValidatorActions.ADD_NEW_COLUMN,
    name,
    normalizedName,
    description,
    field_type,
    enum_options,
    range,
    encrypt,
    optional,
    col,
})

export const clearRowsHistory = () => ({
    type: ValidatorActions.CLEAR_ROWS_HISTORY,
})

/**------------------------ saga triggers -----------------------------**/

export const uploadFile = (fn, file) => ({
    type: ValidatorActions.UPLOAD_FILE,
    fn,
    file,
})

export const deleteFile = fn => ({
    type: ValidatorActions.DELETE_FILE,
    fn,
})

export const selectFile = fn => ({
    type: ValidatorActions.SELECT_FILE,
    fn,
})

export const checkItemsCol = (batch, col, header) => ({
    type: ValidatorActions.CHECK_ITEMS_COL,
    batch,
    col,
    header,
})

export const getItemErrors = (row, col, field, value) => ({
    type: ValidatorActions.GET_ITEM_ERRORS,
    row,
    col,
    field,
    value,
})

export const tryAndProceedToMergeState = (
    colBatches,
    skippedRows,
    skippedCols,
    headerErrors,
    companyMetaBatch,
    companyDataBatch,
) => ({
    type: ValidatorActions.TRY_AND_PROCEED_TO_MERGE_STATE,
    colBatches,
    skippedRows,
    skippedCols,
    headerErrors,
    companyMetaBatch,
    companyDataBatch,
})

export const inferSettings = rows => ({
    type: ValidatorActions.INFER_SETTINGS,
    rows,
})
