import React, { Fragment } from 'react'
import { Button, UncontrolledTooltip } from 'reactstrap'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../styles/MergeAllButtons.css'

export const MergeAllButtons = ({
    fullyMerged,
    partiallyMerged,
    handleMergeAll,
    handleUnmergeAll,
}) => (
    <Fragment>
        <Button
            id="primary-merge-icon"
            outline
            onClick={
                fullyMerged ? () => handleUnmergeAll() : () => handleMergeAll()
            }
            className={`conflict-btn pt-0 pb-0 font-weight-bold med-btn custom-btn-outline-${
                fullyMerged ? 'danger' : 'success'
            }`}
        >
            {fullyMerged ? (
                <FontAwesomeIcon icon="times" />
            ) : (
                <FontAwesomeIcon icon="angle-double-right" />
            )}
        </Button>
        {partiallyMerged && (
            <Fragment>
                <Button
                    id="secondary-merge-icon"
                    outline
                    onClick={() => handleUnmergeAll()}
                    className="conflict-btn pt-0 pb-0 font-weight-bold med-btn custom-btn-outline-danger"
                >
                    <FontAwesomeIcon icon="times" />
                </Button>
                <UncontrolledTooltip
                    placement="right"
                    target="secondary-merge-icon"
                    innerClassName="infer-tooltip pl-1 pr-1"
                >
                    Unmerge All
                </UncontrolledTooltip>
            </Fragment>
        )}
        <UncontrolledTooltip
            placement="left"
            target="primary-merge-icon"
            innerClassName="infer-tooltip pl-1 pr-1"
        >
            {fullyMerged ? 'Unmerge All' : 'Merge All'}
        </UncontrolledTooltip>
    </Fragment>
)

MergeAllButtons.propTypes = {
    fullyMerged: PropTypes.bool.isRequired,
    partiallyMerged: PropTypes.bool.isRequired,
    handleMergeAll: PropTypes.func.isRequired,
    handleUnmergeAll: PropTypes.func.isRequired,
}
