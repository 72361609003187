import { connect } from 'react-redux'
import { AddColumnModalNameOnly } from '../components/AddColumnModalNameOnly'
import { actionModalToggle } from '../actions/modals'
import { addNewColumn } from '../actions/validate'

const initialState = {
    selectVal: 'text',
    columnName: '',
    columnDescription: 'blank',
    enumOptions: [],
    range: { lower: null, upper: null },
    optionData: '',
    invalidName: { status: false, description: '' },
    encrypt: false,
    optional: true,
}

const mapStateToProps = state => {
    return {
        options: state.options,
        companyLabels: Object.keys(state.optionMappings).map(key =>
            state.optionMappings[key].toLowerCase(),
        ),
        actionModals: state.actionModals,
        initialState: initialState,
    }
}

const mapDispatchToProps = dispatch => ({
    actionModalToggle: modalType => {
        dispatch(actionModalToggle(modalType))
    },
    confirmAddColumn: (
        name,
        normalizedName,
        description,
        field_type,
        enum_options,
        range,
        encrypt,
        optional,
        col,
    ) =>
        dispatch(
            addNewColumn(
                name,
                normalizedName,
                description,
                field_type,
                enum_options,
                range,
                encrypt,
                optional,
                col,
            ),
        ),
})

export const AddColumnModalNameOnlyContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddColumnModalNameOnly)
