import React, { Fragment, Component } from 'react'
import { JumpButton } from './JumpButton'
import { UndoRedo } from './UndoRedo'
import { TablePaginationContainer } from '../containers/TablePaginationContainer'
import { ValidateButtonContainer } from '../containers/ValidateButtonContainer'
import { Button } from 'reactstrap'
import '../styles/InteractiveTableFooter.css'

export class InteractiveTableFooter extends Component {
    componentDidUpdate(prevProps) {
        if (
            prevProps.jumpIndex !== this.props.jumpIndex &&
            this.props.jumpIndex !== -1
        ) {
            const jumpIndex = this.props.jumpIndex
            const errorsList = this.props.errorsList
            this.setPage(errorsList[jumpIndex].row)
        } else if (
            prevProps.skippedCols.length !== this.props.skippedCols.length ||
            this.props.errorsList.length === 1
        ) {
            this.setJumpIndex(-1)
        }
    }

    setPage = rowIdx => {
        const pageSize = this.props.pageSize
        const page = Math.floor(rowIdx / pageSize)
        this.props.setPageIndex(page)
        setTimeout(() => {
            this.setFocus(this.props.errorsList, this.props.jumpIndex)
        }, 50)
    }

    setFocus(errorsList, idx) {
        document
            .getElementById(
                `row-${errorsList[idx].row}-col-${errorsList[idx].col}`,
            )
            .focus()
    }

    setJumpIndex = jumpIndex => {
        this.props.setJumpIndex(jumpIndex)
    }

    applyFocus = (errorsList, type) => {
        const jumpIndex = this.props.jumpIndex
        if (errorsList.slice(-1)[0] === undefined) {
            return false
        } else {
            if (type === 'left') {
                if (jumpIndex > 0) {
                    this.setJumpIndex(jumpIndex - 1)
                } else if (jumpIndex <= 0) {
                    this.setJumpIndex(errorsList.length - 1)
                }
            } else if (type === 'right') {
                if (jumpIndex < errorsList.length - 1) {
                    this.setJumpIndex(jumpIndex + 1)
                } else if (jumpIndex >= errorsList.length - 1) {
                    this.setJumpIndex(0)
                }
            }
        }
    }

    toggleSaveModal = () => {
        const { actionModalToggle } = this.props
        actionModalToggle('save')
    }

    render() {
        const {
            errorsList,
            canUndo,
            canRedo,
            tryAndProceedToMergeState,
            rows,
            skippedRows,
        } = this.props

        return (
            <Fragment>
                <div className="bottom-row">
                    <div className="d-flex flex-row flex-wrap justify-content-center position-absolute jump-buttons">
                        <div className="jump-button-left">
                            <JumpButton
                                disabled={!(errorsList.length > 0)}
                                direction="left"
                                onClick={() => {
                                    this.applyFocus(errorsList, 'left')
                                }}
                            />
                        </div>
                        <div className="jump-button-right">
                            <JumpButton
                                disabled={!(errorsList.length > 0)}
                                direction="right"
                                onClick={() => {
                                    this.applyFocus(errorsList, 'right')
                                }}
                            />
                        </div>

                        <p
                            className={`m-0 small-font ${
                                !(errorsList.length > 0) ? 'disabled-text' : ''
                            }`}
                        >
                            Jump To Errors
                        </p>
                    </div>

                    <div className="d-flex flex-row flex-wrap justify-content-center position-absolute undo-buttons">
                        <UndoRedo />
                        <p
                            className={`m-0 small-font ${
                                !(canUndo || canRedo) ? 'disabled-text' : ''
                            }`}
                        >
                            Undo / Redo
                        </p>
                    </div>
                    <div className="align-self-center">
                        <TablePaginationContainer
                            onPageChange={this.props.onPageChange}
                        />
                    </div>
                    <div className="d-flex flex-row flex-wrap justify-content-center position-absolute validate-buttons">
                        <Button
                            className="btn-colored btn-green action-btn fixed-height-btn no-focus-outline"
                            disabled={rows.length === skippedRows.length}
                            onClick={this.toggleSaveModal}
                        >
                            Save Progress
                        </Button>
                        <ValidateButtonContainer
                            className={
                                'btn-colored btn-blue action-btn fixed-height-btn no-focus-outline ml-2'
                            }
                        />
                    </div>
                </div>
            </Fragment>
        )
    }
}
