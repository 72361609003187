import { ModalActions } from '../actions/modals'
import { defaultActionModals } from './defaults'

export const modals = (state = { isOpen: false, data: {} }, action) => {
    switch (action.type) {
        case ModalActions.SET_MESSAGE_MODAL:
            return { isOpen: action.isOpen, data: action.data }
        default:
            return state
    }
}
export const actionModals = (state = defaultActionModals, action) => {
    switch (action.type) {
        case ModalActions.ACTION_MODAL_TOGGLE:
            let modalObj = {}
            if (!isNaN(action.column)) {
                modalObj.column = action.column
            }
            modalObj.isOpen = !state[action.modalType]['isOpen']
            return {
                ...state,
                [action.modalType]: modalObj,
            }
        default:
            return state
    }
}
