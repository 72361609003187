import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormText } from 'reactstrap'
import { Errors } from './Errors'
import { Upload } from './Upload'
import { Import } from './Import'
import { InferButtonContainer } from '../containers/InferButtonContainer'
import { InteractiveTableContainer } from '../containers/InteractiveTableContainer'
import { Spinner } from './Spinner'
import '../styles/ValidateTool.css'
import { SaveModalContainer } from '../containers/SaveModalContainer'
export class ValidateTool extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        const { fetchServerCompanyData } = this.props
        fetchServerCompanyData()
    }

    render() {
        // wtf - needs rename ??
        const {
            errors,
            fetching,
            fetchingMsg,
            tableExists,
        } = this.props
        return (
            <Fragment>
                <Spinner spin={fetching} text={fetchingMsg} />
                <Fragment>
                    <Errors />
                    <div className={errors.length > 0 ? 'pos-marg-top' : ''}>
                        <div className="top m-0 pt-1">
                            <div className="top-text pl-1 align-self-center">
                                Data File
                            </div>
                            <div className="import-field">
                                <Import />
                            </div>
                            <div className="upload-button">
                                <Upload />
                            </div>
                        </div>
                    </div>
                    <br />
                </Fragment>
                {tableExists && (
                    <div className="infer-row d-flex justify-content-end">
                        <InferButtonContainer />
                    </div>
                )}
                <InteractiveTableContainer />
                <div className="d-flex align-content-center justify-content-center m-2 min-height-footer">
                    {tableExists && (
                        <Fragment>
                            <div className="d-flex w-100">
                                <div className="col-sm-2" />
                                <FormText className="p-0 m-0 col-sm-8 text-center">
                                    Select correct column headers and/or modify
                                    data before proceeding
                                </FormText>
                            </div>
                        </Fragment>
                    )}
                </div>
                <SaveModalContainer />
            </Fragment>
        )
    }
}
ValidateTool.propTypes = {
    errs: PropTypes.arrayOf(PropTypes.object).isRequired,
    fetching: PropTypes.bool.isRequired,
    fetchServerCompanyData: PropTypes.func.isRequired,
}
