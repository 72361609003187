import { ValidatorActions } from '../actions/validate'
import { getRows, denormalizeColumnName } from '../helpers'
import { companyKeys } from '../statics/keyMapping'

const mergeItemErrorsIntoState = (currState, row, col, errs) => {
    if (currState.find(item => item.row === row && item.col === col)) {
        // errors for this location already exist, overwrite
        return currState.map(item =>
            item.row === row && item.col === col
                ? { ...item, errors: errs }
                : { ...item },
        )
    } else {
        // errors for this location don't already exist, append
        return [
            ...currState,
            {
                row: row,
                col: col,
                errors: errs,
            },
        ]
    }
}

const removeItemErrorsFromState = (currState, col) => {
    if (currState.find(item => item.col === col)) {
        return currState.map(item =>
            item.col === col ? { ...item, errors: [] } : { ...item },
        )
    }
    //no change because this column has no errors
    else {
        return currState
    }
}

export const errors = (state = [], action) => {
    switch (action.type) {
        case ValidatorActions.SHOW_ERRORS:
            return action.errors

        default:
            return state
    }
}

export const filenames = (state = [], action) => {
    switch (action.type) {
        case ValidatorActions.SHOW_FILENAMES:
            return action.filenames

        default:
            return state
    }
}

export const filesData = (state = [], action) => {
    switch (action.type) {
        case ValidatorActions.SHOW_FILES_DATA:
            return action.filesData
        default:
            return state
    }
}

export const selectedFile = (state = 'Choose File', action) => {
    switch (action.type) {
        case ValidatorActions.SELECT_FILE:
            return action.fn

        case ValidatorActions.SHOW_SELECTED_FILE:
            return action.fn

        default:
            return state
    }
}

export const options = (state = [], action) => {
    switch (action.type) {
        case ValidatorActions.SHOW_OPTIONS:
            return action.options
        case ValidatorActions.ADD_NEW_COLUMN:
            return [...state, action.normalizedName]
        default:
            return state
    }
}

export const optionMappings = (state = [], action) => {
    switch (action.type) {
        case ValidatorActions.SHOW_OPTION_MAPPINGS:
            let optionMap = {}
            let keys = action.options
            for (const key of keys) {
                optionMap[key] = companyKeys[key]
                    ? companyKeys[key].label
                    : denormalizeColumnName(key)
            }
            return optionMap
        case ValidatorActions.ADD_OPTION_MAPPING:
            return { ...state, [action.option]: action.label }
        default:
            return state
    }
}

export const selectedOptions = (state = [], action) => {
    switch (action.type) {
        case ValidatorActions.SHOW_SELECTED_OPTIONS:
            return action.selectedOptions

        case ValidatorActions.SELECT_OPTION:
            const newState = [...state]
            newState.splice(action.col, 1, action.selection)
            return newState

        default:
            return state
    }
}

export const types = (state = {}, action) => {
    switch (action.type) {
        case ValidatorActions.ADD_COL_DATA_TYPE:
            return {
                ...state,
                [action.header]: {
                    type: action.dataType,
                    choices: action.choices,
                },
            }

        default:
            return state
    }
}

export const required = (state = [], action) => {
    switch (action.type) {
        case ValidatorActions.SHOW_REQUIRED_FIELDS:
            return action.requiredFields

        default:
            return state
    }
}

export const skippedRows = (state = [], action) => {
    switch (action.type) {
        case ValidatorActions.TOGGLE_SKIP_ROW:
            return state.includes(action.row)
                ? state.filter(row => row !== action.row)
                : [...state, action.row]

        case ValidatorActions.CLEAR_SKIPPED_ROWS:
            return []

        case ValidatorActions.SKIP_SELECTED_ROWS:
            return action.skippedRows

        default:
            return state
    }
}

export const skippedCols = (state = [], action) => {
    switch (action.type) {
        case ValidatorActions.CLEAR_SKIPPED_COLS:
            return []
        case ValidatorActions.SKIP_SELECTED_COLS:
            return action.skippedCols
        //Removed toggle_skip_col because this is no longer a toggle
        //if included in skippedCols already do nothing else add to skippedCols
        case ValidatorActions.SKIP_COL:
            return state.includes(action.col) ? state : [...state, action.col]
        //if included in skippedCols already remove else do nothing
        case ValidatorActions.UNSKIP_COL:
            return state.includes(action.col)
                ? state.filter(col => col !== action.col)
                : state

        default:
            return state
    }
}

export const itemErrors = (state = [], action) => {
    switch (action.type) {
        case ValidatorActions.ADD_ITEM_ERRORS:
            return mergeItemErrorsIntoState(
                state,
                action.row,
                action.col,
                action.errors,
            )

        case ValidatorActions.ADD_ITEM_ERRORS_COL:
            let newState = [...state]
            for (const [row, errs] of action.errors.entries()) {
                newState = mergeItemErrorsIntoState(
                    newState,
                    row,
                    action.col,
                    errs,
                )
            }
            return newState

        case ValidatorActions.RESET_ITEM_ERRORS_COL:
            let resetState = [...state]
            resetState = removeItemErrorsFromState(resetState, action.col)
            return resetState

        case ValidatorActions.CLEAR_ITEM_ERRORS:
            return []

        default:
            return state
    }
}

export const rows0 = (state = [], action) => {
    switch (action.type) {
        default:
            return state
    }
}

export const rows1 = (state = [], action) => {
    switch (action.type) {
        default:
            return state
    }
}

export const rows2 = (state = [], action) => {
    switch (action.type) {
        default:
            return state
    }
}

export const rows3 = (state = [], action) => {
    switch (action.type) {
        default:
            return state
    }
}

export const rows4 = (state = [], action) => {
    switch (action.type) {
        default:
            return state
    }
}

export const undoState = (state = -1, action) => {
    switch (action.type) {
        case ValidatorActions.REDO:
            return --state
        case ValidatorActions.UNDO:
            return ++state

        case ValidatorActions.SHOW_ROWS:
            return -1

        default:
            return state
    }
}

const updateCell = (rows, row, col, data) => {
    return rows.map((currRow, idx) => {
        const newRow = [...currRow]
        if (idx === row) {
            newRow[col] = data
        }
        return newRow
    })
}

const updateColumn = (rows, col, data, toSkip) => {
    return rows.map((row, idx) => {
        const newRow = [...row]
        // Only update this cell if the row is not skipped
        if (!toSkip.includes(idx)) {
            newRow[col] = data
        }
        return newRow
    })
}

const updateRowsFromPastState = (state, updater) => {
    const rowsHistory = {
        rows4: [...state.rows4],
        rows3: [...state.rows3],
        rows2: [...state.rows2],
        rows1: [...state.rows1],
        rows0: [...state.rows0],
    }
    for (let i = 0; i < state.undoState; i++) {
        rowsHistory.rows0 = rowsHistory.rows1
        rowsHistory.rows1 = rowsHistory.rows2
        rowsHistory.rows2 = rowsHistory.rows3
        rowsHistory.rows3 = rowsHistory.rows4
        rowsHistory.rows4 = []
    }

    rowsHistory.rows4 = rowsHistory.rows3
    rowsHistory.rows3 = rowsHistory.rows2
    rowsHistory.rows2 = rowsHistory.rows1
    rowsHistory.rows1 = rowsHistory.rows0
    rowsHistory.rows0 = updater()
    return rowsHistory
}

// special reducer, reducing happens after first 'main reducer' i.e. combineReducers.
// meant for keeping track of rows and prevRows
export const rowsReducer = (state, action) => {
    switch (action.type) {
        case ValidatorActions.SHOW_ROWS:
            return {
                ...state,
                rows4: [],
                rows3: [],
                rows2: [],
                rows1: [],
                rows0: action.rows,
            }
        case ValidatorActions.UPDATE_CELL_DATA:
            return {
                ...state,
                ...updateRowsFromPastState(state, () =>
                    updateCell(
                        getRows(state, state.undoState),
                        action.row,
                        action.col,
                        action.data,
                    ),
                ),
                undoState: 0,
            }

        case ValidatorActions.BATCH_UPDATE_COLUMN:
            return {
                ...state,
                ...updateRowsFromPastState(state, () =>
                    updateColumn(
                        getRows(state, state.undoState),
                        action.col,
                        action.data,
                        action.toSkip || [],
                    ),
                ),
                undoState: 0,
            }
        //clean it up
        case ValidatorActions.REMOVE_ROWS:
            return {
                ...state,
                rows4: [],
                rows3: [],
                rows2: [],
                rows1: [],
                rows0: state.rows0.filter(
                    (row, index) => !action.rows.includes(index),
                ),
            }

        case ValidatorActions.CLEAR_ROWS_HISTORY:
            return {
                ...state,
                rows4: [],
                rows3: [],
                rows2: [],
                rows1: [],
            }

        default:
            return state
    }
}
