import { connect } from 'react-redux'
import { CompanyTile } from '../components/CompanyTile'

const mapStateToProps = (state, ownProps) => ({
    id: ownProps.id,
    name: state.companyMeta[ownProps.id].name,
    status: state.companyMeta[ownProps.id].status,
    focusRef: ownProps.focusRef,
    conflicting: state.companyMeta[ownProps.id].isConflicting,
    active: ownProps.id === state.currSelectedCompany,
})

export const CompanyTileContainer = connect(mapStateToProps)(CompanyTile)
