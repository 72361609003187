import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Button,
    Popover,
    PopoverHeader,
    PopoverBody,
    ListGroup,
    ListGroupItem,
} from 'reactstrap'
import '../styles/NameConflictPopover.css'
import { updateCellData } from '../actions/validate'
import {
    addCompanySuggestions,
    removeCompanySuggestions,
} from '../actions/companies'
import { getRows, createCompanySuggestionsArray } from '../helpers'
import config from '../config'

class ShownNameConflictPopover extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fetching: true,
            data: [],
            open: false,
            mouseOver: false,
            acceptedNew: false,
        }
    }

    checkSuggestionsTable(name) {
        const { companySuggestions } = this.props;
        const { acceptedNew } = this.state;

        if (companySuggestions.length !== 0 && !acceptedNew) {
            const result = companySuggestions.find(
                suggestion => suggestion.name === name,
            )
            //should i still be including fetching and data in the state?
            if (result) {
                this.setState({
                    fetching: false,
                    data: result.similar,
                    open: false,
                    mouseOver: false,
                    acceptedNew: false,
                })
            }
        }
    }

    toggle(open, mouseOver) {
        this.setState({
            open,
            mouseOver,
        })
    }

    componentDidMount() {
        const { cellData } = this.props
        this.checkSuggestionsTable(cellData)
    }

    componentDidUpdate(prevProps) {
        //if suggestions update
        if (
            this.props.companySuggestions !== prevProps.companySuggestions ||
            this.props.pageIndex !== prevProps.pageIndex
        ) {
            this.checkSuggestionsTable(this.props.cellData)
        }
        //if cell changes and page does not change and page has not changed
        if (
            prevProps.cellData !== this.props.cellData &&
            prevProps.pageIndex === this.props.pageIndex &&
            prevProps.companySuggestions.length !== 0
        ) {
            this.props.removeCompanySuggestions([prevProps.cellData])
            const suggestions = createCompanySuggestionsArray(
                [this.props.cellData],
                this.props.normalizedServerCompanyNames,
            )
            this.props.addCompanySuggestions(suggestions)
        }
    }

    render() {
        const { row, col, cellData, selectChoice } = this.props
        const { open, fetching, data, acceptedNew } = this.state
        const uniqueId = `warn-row-${row}-col-${col}`

        if (fetching) {
            return <div className="loader" />
        } else {
            if (data.length === 0) {
                return null
            } else if (
                (data.length === 1 && data[0].name === cellData) ||
                acceptedNew
            ) {
                return (
                    <Button
                        outline
                        color="success"
                        size="xs"
                        className="no-click neg-marg border-0 p-0 no-hover-background no-focus-outline"
                    >
                        <FontAwesomeIcon
                            icon="circle"
                            className="text-success"
                        />
                    </Button>
                )
            } else {
                return (
                    <Fragment>
                        <Button
                            outline
                            color="warning"
                            size="xs"
                            className="neg-marg border-0 p-0 no-hover-background no-focus-outline"
                            id={uniqueId}
                            onMouseEnter={() => this.toggle(true, false)}
                            onMouseLeave={e => {
                                // try and do it based on timing - all we need is a 50ms delay to
                                // allow a mouse cursor to potentially enter the popover
                                setTimeout(() => {
                                    const { mouseOver } = this.state
                                    if (mouseOver) {
                                        this.toggle(true, false)
                                    } else {
                                        this.toggle(false, false)
                                    }
                                }, 50)
                            }}
                        >
                            <FontAwesomeIcon
                                icon="circle"
                                className="text-warning"
                            />
                        </Button>
                        <Popover
                            placement="right-end"
                            isOpen={open}
                            target={uniqueId}
                            onMouseEnter={() => this.toggle(true, true)}
                            onMouseLeave={() => this.toggle(false, false)}
                            className="warning-popover admin-tool-font"
                        >
                            <PopoverHeader className="border-bottom-0 warning-popover-header admin-tool-font">
                                {`${data.length} similar ${
                                    data.length > 1 ? 'companies' : 'company'
                                    } found!`}
                                <br />
                                Did you mean:
                            </PopoverHeader>
                            <PopoverBody className="admin-tool-font">
                                <ListGroup flush className="scroll-group">
                                    {data.map((name, idx) => (
                                        <ListGroupItem
                                            key={idx}
                                            action
                                            className="w-item"
                                            onClick={() => {
                                                selectChoice(name.name)
                                                this.setState({
                                                    acceptedNew: true,
                                                    open: false,
                                                })
                                            }}
                                        >
                                            <i>{name.name}</i>
                                        </ListGroupItem>
                                    ))}
                                    <ListGroupItem
                                        action
                                        className="w-item"
                                        onClick={() => {
                                            selectChoice(cellData)
                                            this.setState({
                                                acceptedNew: true,
                                                open: false,
                                            })
                                        }}
                                    >
                                        <strong>
                                            <i>None of the above</i>
                                        </strong>
                                    </ListGroupItem>
                                </ListGroup>
                            </PopoverBody>
                        </Popover>
                    </Fragment>
                )
            }
        }
    }
}

export const NameConflictPopover = connect(
    // mapStateToProps
    (state, ownProps) => ({
        row: ownProps.row,
        col: ownProps.col,
        cellData: getRows(state, state.undoState)[ownProps.row][ownProps.col],
        companySuggestions: state.companySuggestions,
        pageIndex: state.pageIndex,
        pageSize: state.pageSize,
        normalizedServerCompanyNames: state.normalizedServerCompanyNames,
        selectedFile: state.selectedFile,
    }),
    // mapDispatchToProps
    (dispatch, ownProps) => ({
        selectChoice: choice => {
            dispatch(updateCellData(ownProps.row, ownProps.col, choice))
        },
        addCompanySuggestions: suggestions => {
            dispatch(addCompanySuggestions(suggestions))
        },
        removeCompanySuggestions: col => {
            dispatch(removeCompanySuggestions(col))
        },
    }),
)(ShownNameConflictPopover)
