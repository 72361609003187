export const TableActions = {
    SET_PAGE_INDEX: 'SET_PAGE_INDEX',
    SET_PAGE_SIZE: 'SET_PAGE_SIZE',
    CHECK_ACTIVE_PAGE: 'CHECK_ACTIVE_PAGE',
    SET_JUMP_INDEX: 'SET_JUMP_INDEX',
}

export const setPageIndex = pageIdx => ({
    type: TableActions.SET_PAGE_INDEX,
    pageIdx,
})

export const setPageSize = pageSize => ({
    type: TableActions.SET_PAGE_SIZE,
    pageSize,
})

export const setJumpIndex = errIdx => ({
    type: TableActions.SET_JUMP_INDEX,
    errIdx,
})
