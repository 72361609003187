import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'
import { rootReducer } from '../reducers'
import { watchAll } from '../sagas'
import config from '../config'

function configStore(initialState) {
    const middleware = []
    const enhancers = []
    let monitor = null

    /* ------------- Assemble Middleware ------------- */
    if (config.env === 'development') {
        monitor = window['__SAGA_MONITOR_EXTENSION__']
        const loggerMiddleware = createLogger({
            collapsed: true,
        })
        middleware.push(loggerMiddleware)
    }

    const sagaMiddleware = createSagaMiddleware({ sagaMonitor: monitor })
    middleware.push(sagaMiddleware)

    enhancers.push(applyMiddleware(...middleware))

    const store = createStore(
        rootReducer,
        initialState,
        compose(
            ...enhancers,
            window.devToolsExtension ? window.devToolsExtension() : f => f,
        ),
    )

    sagaMiddleware.run(watchAll)

    return { store }
}

const { store } = configStore()

export { store }

export default configStore
