import React from 'react'
import PropTypes from 'prop-types'
import '../styles/Spinner.css'

export const Spinner = ({ spin, text }) => (
    <div
        className={`${spin ? 'spinner' : ''} d-flex flex-column`}
        id={spin ? 'spinner' : ''}
    >
        <div className={spin ? 'loader' : ''} />
        {spin &&
            text !== '' && (
                <p className="spinner-text mt-3 p-1 rounded">{text}</p>
            )}
    </div>
)

Spinner.propTypes = {
    spin: PropTypes.bool.isRequired,
}
