import React, { Fragment, PureComponent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from 'reactstrap'
import '../styles/InteractiveTable.css'
import '../styles/InteractiveTableOriginCell.css'
import '../styles/InferButton.css'

export class InferButton extends PureComponent {
    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this)
    }

    state = {
        modal: false,
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
        })
    }

    render() {
        const { infer } = this.props

        return (
            <div className="infer-button-container">
                <span id="infer-button" onClick={this.toggle}>
                    <FontAwesomeIcon icon="layer-group" />
                </span>
                <UncontrolledTooltip
                    innerClassName="infer-tooltip pl-1 pr-1"
                    placement="right"
                    target="infer-button"
                >
                    Auto-Classify columns
                </UncontrolledTooltip>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    className={this.props.className}
                >
                    <ModalHeader toggle={this.toggle}>
                        Auto-Classify Data Columns
                    </ModalHeader>
                    <ModalBody>
                        Automatically classify data columns in the uploaded
                        dataset.
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            className="btn-colored fixed-height-btn no-focus-outline"
                            color="dark"
                            onClick={this.toggle}
                        >
                            Cancel
                        </Button>{' '}
                        <Button
                            className="btn-colored btn-blue fixed-height-btn no-focus-outline"
                            onClick={() => {
                                infer()
                                this.toggle()
                            }}
                        >
                            Auto-Classify
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
