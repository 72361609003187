import React, { Component, Fragment } from 'react'
import { ActionModalContainer } from '../containers/ActionModalContainer'
import { Button, FormGroup, Label, Input, FormFeedback } from 'reactstrap'
import locales from '../statics/locales'

const {
    MODALS: {
        SAVE: { HEAD, BODY, OK_BUTTON, CANCEL_BUTTON, INVALID_FILE },
    },
} = locales

export class SaveModal extends Component {
    constructor(props) {
        super(props)
        this.validateInput = this.validateInput.bind(this)
        this.confirmSave = this.confirmSave.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.fileNameInput = React.createRef()
    }

    state = {
        modal: false,
        invalid: false,
    }

    validateInput(input, cb) {
        const re = /^[a-zA-Z0-9_]+$/
        if (!input.match(re)) {
            this.setState({
                invalid: true,
            })
        } else {
            cb(input)
            this.toggleModal()
        }
    }

    confirmSave() {
        const { handleSave } = this.props
        const {
            current: { value },
        } = this.fileNameInput
        this.validateInput(value, handleSave)
    }
    toggleModal() {
        const { actionModalToggle } = this.props
        actionModalToggle('save')
    }

    render() {
        const { invalid } = this.state
        return (
            <ActionModalContainer
                modalType="save"
                head={HEAD}
                body={
                    <div>
                        <p>{BODY}</p>

                        <FormGroup>
                            <Label for="name">File Name:</Label>
                            <Input
                                type="text"
                                name="name"
                                id="name"
                                autoComplete="off"
                                innerRef={this.fileNameInput}
                                invalid={invalid}
                            />
                            <FormFeedback invalid={invalid}>
                                {INVALID_FILE}
                            </FormFeedback>
                        </FormGroup>
                    </div>
                }
                customFooter={
                    <div>
                        {' '}
                        <Button
                            className="btn-colored fixed-height-btn no-focus-outline mr-1"
                            color="dark"
                            onClick={this.toggleModal}
                        >
                            {CANCEL_BUTTON}
                        </Button>
                        <Button
                            className="btn-colored btn-green fixed-height-btn admin-tool-font no-focus-outline ml-1"
                            onClick={this.confirmSave}
                        >
                            {OK_BUTTON}
                        </Button>
                    </div>
                }
            />
        )
    }
}
