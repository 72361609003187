import { connect } from 'react-redux'
import { StageButtons } from '../components/StageButtons'
import {
    stageForIngest,
    unstageForIngest,
    changeCompanyStatus,
    PossibleStatus,
    selectCompany,
} from '../actions/companies'
import { checkExactMatch } from '../helpers'

const getButtonTitlesForStatus = status => {
    switch (status) {
        case PossibleStatus.MERGED:
        case PossibleStatus.ADDED:
        default:
            return ['Confirm', 'Cancel']
    }
}

const mapStateToProps = (state, ownProps) => {
    const buttonTitles = getButtonTitlesForStatus(ownProps.onClickNextStatus)
    return {
        focusRef: ownProps.focusRef,
        activeId: state.currSelectedCompany,
        firstPendingId: state.displayedCompanies
            .filter(company => company !== state.currSelectedCompany)
            .filter(
                company =>
                    state.companyMeta[company].status ===
                    PossibleStatus.PENDING,
            )[0],
        firstMergedOrAddedId: state.displayedCompanies
            .filter(company => company !== state.currSelectedCompany)
            .filter(
                company =>
                    state.companyMeta[company].status ===
                        PossibleStatus.ADDED ||
                    state.companyMeta[company].status === PossibleStatus.MERGED,
            )[0],
        staged: state.stagedForIngest.includes(state.currSelectedCompany),
        stageButtonTitle: buttonTitles[0],
        unstageButtonTitle: buttonTitles[1],
        companyMeta: state.companyMeta,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    handleStage: (id, firstPendingId) => {
        dispatch(stageForIngest([id]))
        dispatch(changeCompanyStatus(ownProps.onClickNextStatus, id))
        if (firstPendingId) {
            dispatch(selectCompany(firstPendingId))
        }
    },
    handleUnstage: (companyMeta, id, firstMergedOrAddedId) => {
        dispatch(unstageForIngest([id]))
        dispatch(changeCompanyStatus(checkExactMatch(companyMeta, id), id))
        if (firstMergedOrAddedId) {
            dispatch(selectCompany(firstMergedOrAddedId))
        }
    },
})

export const StageButtonsContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    // mergeProps
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        handleStageClick: () => {
            stateProps.staged
                ? dispatchProps.handleUnstage(
                      stateProps.companyMeta,
                      stateProps.activeId,
                  )
                : dispatchProps.handleStage(stateProps.activeId)
        },
        handleStageKeyPress: () => {
            stateProps.staged
                ? dispatchProps.handleUnstage(
                      stateProps.activeId,
                      stateProps.firstMergedOrAddedId,
                  )
                : dispatchProps.handleStage(
                      stateProps.activeId,
                      stateProps.firstPendingId,
                  )
        },
    }),
)(StageButtons)
