import { connect } from 'react-redux'
import { ActionModalContainer } from '../containers/ActionModalContainer'
import { actionModalToggle } from '../actions/modals'
import { unmergeAll } from '../actions/merge'
import { generateModalBody } from '../helpers'
import locales from '../statics/locales'

const {
    MODALS: {
        UNMERGE: { HEAD, BODY, OK_BUTTON, CANCEL_BUTTON },
    },
} = locales

const mapStateToProps = ({ stagedForIngest }) => {
    const newProps = {
        modalType: 'unmerge',
        head: HEAD,
        body: generateModalBody(BODY),
        okButton: OK_BUTTON,
        cancelButton: CANCEL_BUTTON,
    }
    return {
        stagedForIngest,
        ...newProps,
    }
}

const mapDispatchToProps = dispatch => ({
    handleUnmergeAll: ids => dispatch(unmergeAll(ids)),
    actionModalToggle: type => dispatch(actionModalToggle(type)),
})

export const UnmergeModalContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        onAcceptFunc: () => (
            dispatchProps.handleUnmergeAll(stateProps.stagedForIngest),
            dispatchProps.actionModalToggle(stateProps.modalType)
        ),
    }),
)(ActionModalContainer)
