import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Table } from 'reactstrap'
import { getColorClass } from '../helpers'

export class IngestReport extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { report } = this.props

        return (
            <Table hover>
                <thead>
                    <tr className="table-header">
                        <th>Ingest Status</th>
                        <th>Count</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(report).map(([key, val], idx) => (
                        <tr key={idx} className="table-body-row">
                            <td className={`text-${getColorClass(key)}`}>
                                {key}
                            </td>
                            <td id={val} className="cursor-change">
                                {val}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
    }
}
