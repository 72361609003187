import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Input } from 'reactstrap'
import { BatchPopover } from './BatchPopover'
import { InteractiveTableHeaderInputContainer } from '../containers/InteractiveTableHeaderInputContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../styles/InteractiveTableHeaderItem.css'
import '../styles/ErrorPopover.css'
import { createCompanySuggestionsArray } from '../helpers'

export class InteractiveTableHeaderItem extends Component {
    constructor(props) {
        super(props)
    }

    componentDidUpdate(prevProps) {
        const {
            pageSize,
            pageIndex,
            selected,
            companySuggestions,
            removeCompanySuggestions,
            normalizedServerCompanyNames,
            fullCol,
            addCompanySuggestions,
        } = this.props
        // if user changes column from name to anything else we want to
        // remove the suggestions generated for this col
        if (
            prevProps.selected === 'name' &&
            selected !== 'name' &&
            companySuggestions.length !== 0
        ) {
            removeCompanySuggestions(prevProps.fullCol)
        }
        // add suggestions for any column that has column header switched to name
        if (selected === 'name' && prevProps.selected !== 'name') {
            const reducedBatch = fullCol.slice(
                pageIndex * pageSize,
                (pageIndex + 1) * pageSize,
            )
            const suggestions = createCompanySuggestionsArray(
                reducedBatch,
                normalizedServerCompanyNames,
            )
            addCompanySuggestions(suggestions)
        }
        // page has been switched so remove all suggestions from previous page
        if (pageIndex !== prevProps.pageIndex && selected === 'name') {
            const prevPageSize = prevProps.pageSize
            const prevPageIndex = prevProps.pageIndex
            const prevReducedBatch = prevProps.fullCol.slice(
                prevPageIndex * prevPageSize,
                (prevPageIndex + 1) * prevPageSize,
            )
            removeCompanySuggestions(prevReducedBatch)
            const reducedBatch = fullCol.slice(
                pageIndex * pageSize,
                (pageIndex + 1) * pageSize,
            )
            const suggestions = createCompanySuggestionsArray(
                reducedBatch,
                normalizedServerCompanyNames,
            )
            addCompanySuggestions(suggestions)
        }
    }

    render() {
        const {
            options,
            col,
            selected,
            permanent,
            handleSelectOption,
            totalNumErrors,
            colDisabled,
            resetItemErrorsCol,
            normalizedServerCompanyNames,
            pageSize,
            pageIndex,
        } = this.props
        const btnColor = totalNumErrors > 0 ? 'danger' : 'success'
        const btnText = totalNumErrors > 0 ? `${totalNumErrors} !` : '\u2713'
        return (
            <div className="d-flex justify-content-between align-items-center blue-text">
                <InteractiveTableHeaderInputContainer col={col} />
                <BatchPopover col={col} />
                {totalNumErrors > 0 && !colDisabled && (
                    <p
                        className={`mb-0 mr-2 text-${btnColor} font-weight-bold`}
                    >
                        {btnText}
                    </p>
                )}
            </div>
        )
    }
}

InteractiveTableHeaderItem.defaultProps = {
    options: [],
    selected: '',
    totalNumErrors: 0,
    colDisabled: true,
}

InteractiveTableHeaderItem.propTypes = {
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    selected: PropTypes.string.isRequired,
    handleSelectOption: PropTypes.func.isRequired,
    totalNumErrors: PropTypes.number.isRequired,
    colDisabled: PropTypes.bool.isRequired,
}
