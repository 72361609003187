let nextCompanyId = 0

export const CompanyActions = {
    ADD_COMPANY: 'ADD_COMPANY',
    ADD_COMPANY_BATCH: 'ADD_COMPANY_BATCH',
    ADD_COMPANY_IDS: 'ADD_COMPANY_IDS',
    ADD_COMPANY_DATA_BATCH: 'ADD_COMPANY_DATA_BATCH',
    ADD_COMPANY_DATA_CURRENT_BATCH: 'ADD_COMPANY_DATA_CURRENT_BATCH',
    REMOVE_COMPANY: 'REMOVE_COMPANY',
    CHANGE_COMPANY_STATUS: 'CHANGE_COMPANY_STATUS',
    CHANGE_COMPANY_STATUSES: 'CHANGE_COMPANY_STATUSES',
    DISPLAY_SEARCH_RESULTS: 'DISPLAY_SEARCH_RESULTS',
    SELECT_COMPANY: 'SELECT_COMPANY',
    STAGE_FOR_INGEST: 'STAGE_FOR_INGEST',
    UNSTAGE_FOR_INGEST: 'UNSTAGE_FOR_INGEST',
    CHANGE_CONFLICTING_STATUS: 'CHANGE_CONFLICTING_STATUS',
    CLEAR_COMPANY_DATA: 'CLEAR_COMPANY_DATA',
    STAGE_INGESTED: 'STAGE_INGESTED',
    CLEAR_INGESTED: 'CLEAR_INGESTED',
    INGEST_COMPANIES: 'INGEST_COMPANIES',
    ADD_INGEST_ERRORS: 'ADD_INGEST_ERRORS',
    SAVE_MTIME: 'SAVE_MTIME',
    SAVE_DBID: 'SAVE_DBID',
    BATCH_MERGE: 'BATCH_MERGE',
    ADD_COMPANY_SUGGESTIONS: 'ADD_COMPANY_SUGGESTIONS',
    ADD_SERVER_COMPANY_DATA: 'ADD_SERVER_COMPANY_DATA',
    CLEAR_COMPANY_SUGGESTIONS: 'CLEAR_COMPANY_SUGGESTIONS',
    REMOVE_COMPANY_SUGGESTIONS: 'REMOVE_COMPANY_SUGGESTIONS',
    SAVE_COMPANIES: 'SAVE_COMPANIES',
}

export const FetchActions = {
    FETCH_COMPANIES_BEGIN: 'FETCH_COMPANIES_BEGIN',
    FETCH_COMPANIES_END: 'FETCH_COMPANIES_END',
    FETCH_COMPANIES_SUCCESS: 'FETCH_COMPANIES_SUCCESS',
    FETCH_COMPANIES_FAILURE: 'FETCH_COMPANIES_FAILURE',
    FETCH_SERVER_COMPANY_DATA: 'FETCH_SERVER_COMPANY_DATA',
}

export const PossibleStatus = {
    PENDING: 'Pending',
    ADDED: 'Added',
    MERGED: 'Merged',
    NO_CHANGES: 'No Changes',
    INGESTED: 'Ingested',
    WARNING: 'Warning',
    ERROR: 'Error',
}

export const PossibleConflictStatus = {
    CONFLICT: 'CONFLICT',
    NEW: 'NEW',
}

export const PossibleMergeStatus = {
    INCOMING: 'New Changes',
    CURRENT: 'Existing',
}

export const EditStatus = {
    NEW_EDIT: 'New Changes',
    FINAL_EDIT: 'Final Edit',
}

export const addCompany = (status, name, data) => ({
    type: CompanyActions.ADD_COMPANY,
    id: nextCompanyId++,
    status,
    name,
    data,
})

export const addCompanyIds = companyIds => ({
    type: CompanyActions.ADD_COMPANY_IDS,
    companyIds,
})

export const removeCompany = id => ({
    type: CompanyActions.REMOVE_COMPANY,
    id,
})

export const changeCompanyStatus = (status, id) => ({
    type: CompanyActions.CHANGE_COMPANY_STATUS,
    status,
    id,
})

export const changeCompanyStatuses = statuses => ({
    type: CompanyActions.CHANGE_COMPANY_STATUSES,
    statuses,
})

export const selectCompany = (id, name) => ({
    type: CompanyActions.SELECT_COMPANY,
    id,
    name,
})

export const displaySearchResults = searchResultIds => ({
    type: CompanyActions.DISPLAY_SEARCH_RESULTS,
    searchResultIds,
})

export const stageForIngest = staged => ({
    type: CompanyActions.STAGE_FOR_INGEST,
    staged,
})

export const unstageForIngest = unstaged => ({
    type: CompanyActions.UNSTAGE_FOR_INGEST,
    unstaged,
})

export const stageIngested = ingested => ({
    type: CompanyActions.STAGE_INGESTED,
    ingested,
})

export const clearIngested = () => ({
    type: CompanyActions.CLEAR_INGESTED,
})

export const ingestCompanies = toIngest => ({
    type: CompanyActions.INGEST_COMPANIES,
    toIngest,
})

export const addIngestErrors = companies => ({
    type: CompanyActions.ADD_INGEST_ERRORS,
    companies,
})

export const changeConflictingStatus = (isConflicting, id) => ({
    type: CompanyActions.CHANGE_CONFLICTING_STATUS,
    isConflicting,
    id,
})

export const clearCompanyData = () => ({
    type: CompanyActions.CLEAR_COMPANY_DATA,
})

export const addCompanyBatch = companies => ({
    type: CompanyActions.ADD_COMPANY_BATCH,
    companies,
})

export const addCompanyDataBatch = companyData => ({
    type: CompanyActions.ADD_COMPANY_DATA_BATCH,
    companyData,
})

export const addCompanyDataCurrentBatch = companyData => ({
    type: CompanyActions.ADD_COMPANY_DATA_CURRENT_BATCH,
    companyData,
})

export const savemTime = (id, mTime) => ({
    type: CompanyActions.SAVE_MTIME,
    id,
    mTime,
})

export const saveDbId = (id, dbId) => ({
    type: CompanyActions.SAVE_DBID,
    id,
    dbId,
})

export const batchMerge = batch => ({
    type: CompanyActions.BATCH_MERGE,
    batch,
})

export const addCompanySuggestions = suggestions => ({
    type: CompanyActions.ADD_COMPANY_SUGGESTIONS,
    suggestions,
})

export const clearCompanySuggestions = () => ({
    type: CompanyActions.CLEAR_COMPANY_SUGGESTIONS,
})

export const removeCompanySuggestions = col => ({
    type: CompanyActions.REMOVE_COMPANY_SUGGESTIONS,
    col,
})

export const addServerCompanyData = (
    serverCompanyData,
    normalizedServerCompanyNames,
) => ({
    type: CompanyActions.ADD_SERVER_COMPANY_DATA,
    serverCompanyData,
    normalizedServerCompanyNames,
})

export const saveCompanies = filename => ({
    type: CompanyActions.SAVE_COMPANIES,
    filename,
})

/************** data fetching  ****************************/

export const fetchCompaniesBegin = msg => ({
    type: FetchActions.FETCH_COMPANIES_BEGIN,
    msg,
})

export const fetchCompaniesEnd = () => ({
    type: FetchActions.FETCH_COMPANIES_END,
})

export const fetchCompaniesSuccess = (id, data) => ({
    type: FetchActions.FETCH_COMPANIES_SUCCESS,
    id,
    data,
})

export const fetchCompaniesFailure = (id, data) => ({
    type: FetchActions.FETCH_COMPANIES_FAILURE,
    id,
    data,
})

export const fetchServerCompanyData = () => ({
    type: FetchActions.FETCH_SERVER_COMPANY_DATA,
})
