import { connect } from 'react-redux'
import { ConflictPanel } from '../components/ConflictPanel'
import { filterId } from '../helpers'
import { PossibleStatus } from '../actions/companies'
import { compareCompanyKeys } from '../helpers'
import _ from 'lodash'

const mapStateToProps = state => {
    const id = state.currSelectedCompany
    return {
        name: state.companyMeta[id].name,
        pendingStatus: state.companyMeta[id].status,
        fieldsList: Object.keys(filterId(id, state.companyData[id])),
        noChanges:
            state.companyMeta[id].status === PossibleStatus.INGESTED ||
            state.companyMeta[id].status === PossibleStatus.WARNING ||
            state.companyMeta[id].status === PossibleStatus.ERROR ||
            _.every(
                state.companyData[id],
                (val, key) =>
                    key !== 'id'
                        ? compareCompanyKeys(
                              key,
                              val,
                              state.userAffiliationId,
                              state.companyDataCurrent[id],
                          )
                        : true, // ignore key when checking for exact match
            ),
    }
}

export const ConflictPanelContainer = connect(mapStateToProps)(ConflictPanel)
