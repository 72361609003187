export const AffiliationActions = {
    ADD_USER_AFFILIATION_ID: 'ADD_USER_AFFILIATION_ID',
    ADD_AFFILIATIONS: 'ADD_AFFILIATIONS',
}

export const addUserAffiliationId = affiliationId => ({
    type: AffiliationActions.ADD_USER_AFFILIATION_ID,
    affiliationId,
})

export const addAffiliations = affiliations => ({
    type: AffiliationActions.ADD_AFFILIATIONS,
    affiliations,
})
