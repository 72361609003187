import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { store } from './store/configStore'
import './fontawesome'

import './styles/bootstrap.css'
import './styles/General.css'

import { Routes } from './routes'
import { history } from './routes/history'
import registerServiceWorker from './registerServiceWorker'

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>{Routes}</Router>
    </Provider>,
    document.getElementById('root'),
)

registerServiceWorker()
