import { connect } from 'react-redux'
import { MergeAllButtons } from '../components/MergeAllButtons'
import { mergeField, unmergeField } from '../actions/merge'
import { filterId } from '../helpers'

const unMergedArray = (companyData, companyDataCurrent, merges) => {
    let unMerged = []
    for (const field in companyData) {
        if (
            companyData[field] !== companyDataCurrent[field] &&
            !merges.includes(field)
        ) {
            unMerged.push(field)
        }
    }
    return unMerged
}

const mapStateToProps = (state, ownProps) => {
    const id = state.currSelectedCompany
    const companyData = filterId(id, state.companyData[id])
    const companyDataCurrent = filterId(id, state.companyDataCurrent[id])
    const merges = state.companyMeta[id].merges
    const unMerged = unMergedArray(companyData, companyDataCurrent, merges)
    const fullyMerged = unMerged.length === 0

    return {
        activeId: id,
        fullyMerged: fullyMerged,
        partiallyMerged:
            state.companyMeta[id].merges.length !== 0 && !fullyMerged,
        merges: merges,
        unMerged: unMerged,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    handleMergeAll: (id, unMerged) => {
        for (const field of unMerged) {
            dispatch(mergeField(id, field))
        }
    },
    handleUnmergeAll: (id, merges) => {
        for (const field of merges) {
            dispatch(unmergeField(id, field))
        }
    },
})

export const MergeAllButtonsContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    // mergeProps
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        handleMergeAll: () => {
            dispatchProps.handleMergeAll(
                stateProps.activeId,
                stateProps.unMerged,
            )
            if (ownProps.onMergeAll) {
                ownProps.onMergeAll()
            }
        },
        handleUnmergeAll: () =>
            dispatchProps.handleUnmergeAll(
                stateProps.activeId,
                stateProps.merges,
            ),
    }),
)(MergeAllButtons)
