import { library } from '@fortawesome/fontawesome-svg-core'
import * as faIcons from '@fortawesome/free-solid-svg-icons'

const icons = [
    'CloudUploadAlt',
    'FileAlt',
    'Check',
    'Times',
    'Exclamation',
    'LongArrowAltRight',
    'LongArrowAltLeft',
    'SortAmountDown',
    'Undo',
    'Redo',
    'AngleLeft',
    'AngleRight',
    'AngleDoubleLeft',
    'AngleDoubleRight',
    'Times',
    'TimesCircle',
    'PlusCircle',
    'MinusCircle',
    'PencilAlt',
    'ChevronDown',
    'Tag',
    'Search',
    'LayerGroup',
    'TrashAlt',
    'Square',
    'CheckSquare',
    'Circle',
    'ArrowLeft',
    'ArrowRight',
]

icons.map(icon => library.add(faIcons[`fa${icon}`]))
