import { connect } from 'react-redux'
import { SortStates, FilterStates, selectSortState } from '../actions/sort'
import { SortStatusButton } from '../components/SortStatusButton'
import { PossibleStatus } from '../actions/companies'
import { filterCompanyList } from '../helpers'

const getSortState = sortState => {
    switch (sortState) {
        case SortStates.BY_ADDED:
            return PossibleStatus.ADDED
        case SortStates.BY_MERGED:
            return PossibleStatus.MERGED
        case SortStates.BY_NEW:
            return 'NEW'
        case SortStates.BY_PENDING:
        default:
            return PossibleStatus.PENDING
    }
}

const getFilterState = filterState => {
    switch (filterState) {
        case FilterStates.BY_PENDING:
            return 'PENDING'
        case FilterStates.BY_INGEST_READY:
            return 'INGEST READY'
        case FilterStates.BY_NO_CHANGES:
            return 'NO CHANGES'    
        case FilterStates.BY_ALL:
        default:
            return 'ALL'
    }
}

const mapStateToProps = state => ({
    status: getFilterState(state.sortStatusField),
    numOfStatus: filterCompanyList(
        state.displayedCompanies,
        state.companyMeta,
        state.sortStatusField,
    ).length,
})

const mapDispatchToProps = dispatch => ({
    clickHandler: sortState => dispatch(selectSortState(sortState)),
})

export const SortStatusButtonContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SortStatusButton)
