import React, { Component } from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { getRows, createIncrementingArray } from '../helpers'

export default class TablePagination extends Component {
    constructor(props) {
        super()
        this.pageClicked = this.pageClicked.bind(this)
        this.nextClicked = this.nextClicked.bind(this)
        this.prevClicked = this.prevClicked.bind(this)
        this.changePage = this.changePage.bind(this)
        props.setPageIndex(0)
    }

    componentDidUpdate(prevProps) {
        if (this.props.pageIndex != prevProps.pageIndex) {
            this.changePage(this.props.pageIndex)
        }
    }

    pageClicked(e) {
        e.preventDefault()
        let pageNumber = parseInt(e.target.getAttribute('href'))
        //only change page if user clicks on a different one than what is current
        if (pageNumber !== this.props.pageIndex + 1) {
            this.props.setPageIndex(pageNumber - 1)
        }
    }
    //This function should only be called from within componentDidUpdate
    //Now if users update the page without directly clicking the pagination buttons
    // the pagination should update accordingly
    changePage(pageNumber) {
        this.props.onPageChange(pageNumber)
    }
    nextClicked(e) {
        e.preventDefault()
        const nextPage = this.props.pageIndex + 1
        if (nextPage < this.props.totalPageCount) {
            this.props.setPageIndex(nextPage)
        }
    }
    prevClicked(e) {
        e.preventDefault()
        const nextPage = this.props.pageIndex - 1
        if (nextPage >= 0) {
            this.props.setPageIndex(nextPage)
        }
    }

    render() {
        const { pageIndex, totalPageCount } = this.props

        return (
            <Pagination>
                <PaginationItem disabled={pageIndex === 0}>
                    <PaginationLink
                        className="navigation-text no-focus-outline"
                        onClick={this.pageClicked}
                        href={1}
                    >
                        First
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={pageIndex === 0}>
                    <PaginationLink
                        previous
                        className="navigation-text no-focus-outline"
                        onClick={this.prevClicked}
                        href={pageIndex}
                    />
                </PaginationItem>

                {createIncrementingArray(totalPageCount, pageIndex + 1).map(
                    p => {
                        return (
                            <PaginationItem
                                key={p}
                                active={p === pageIndex + 1}
                            >
                                <PaginationLink
                                    className={
                                        p === pageIndex + 1
                                            ? 'navigation-background no-focus-outline'
                                            : 'navigation-text no-focus-outline'
                                    }
                                    onClick={this.pageClicked}
                                    href={p}
                                >
                                    {p}
                                </PaginationLink>
                            </PaginationItem>
                        )
                    },
                )}
                <PaginationItem disabled={pageIndex + 1 === totalPageCount}>
                    <PaginationLink
                        next
                        className="navigation-text no-focus-outline"
                        onClick={this.nextClicked}
                        href={pageIndex}
                    />
                </PaginationItem>
                <PaginationItem disabled={pageIndex + 1 === totalPageCount}>
                    <PaginationLink
                        onClick={this.pageClicked}
                        href={totalPageCount}
                        className="navigation-text no-focus-outline"
                    >
                        Last
                    </PaginationLink>
                </PaginationItem>
            </Pagination>
        )
    }
}
