import { connect } from 'react-redux'
import { MergeTool } from '../components/MergeTool'

const mapStateToProps = state => ({
    fetching: state.fetching.fetching,
    fetchingMsg: state.fetching.msg,
})

export const MergeToolContainer = connect(
    mapStateToProps,
    null,
)(MergeTool)
