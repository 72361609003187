import { InferButton } from '../components/InferButton'
import {
    skipSelectedRows,
    clearSkippedRows,
    inferSettings,
} from '../actions/validate'
import { connect } from 'react-redux'
import { getRows } from '../helpers'

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => ({
    skipSelectedRows: selectRows => dispatch(skipSelectedRows(selectRows)),
    clearSkippedRows: () => dispatch(clearSkippedRows()),
    infer: rows => dispatch(inferSettings(rows)),
})

export const InferButtonContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        infer: () => {
            dispatchProps.infer(stateProps.rows)
        },
    }),
)(InferButton)
