import React from 'react'
import { FilterStates } from './actions/sort'
import {
    PossibleStatus,
    PossibleConflictStatus,
    PossibleMergeStatus,
} from './actions/companies'
import { extendedSchemaPrefix } from './statics/columnStatics'
import _ from 'lodash'
import { companyKeys } from './statics/keyMapping'

/**
 *
 * @param {*} companies List of companies from the csv file
 * @param {*} companyMeta Company metaData (merge status, conflicting status, etc)
 * @param {*} filterStatusField The field to filter by
 */
export const filterCompanyList = (
    companies,
    companyMeta,
    filterStatusField,
) => {
    const adjustedCompanies = companies.map(id => ({
        ...companyMeta[id],
    }))
    switch (filterStatusField) {
        case FilterStates.BY_PENDING:
            return adjustedCompanies.filter(company => {
                return company.status === PossibleStatus.PENDING
            })
        case FilterStates.BY_INGEST_READY:
            return adjustedCompanies.filter(company => {
                return (
                    company.status === PossibleStatus.ADDED ||
                    company.status === PossibleStatus.MERGED
                )
            })
        case FilterStates.BY_NO_CHANGES:
            return adjustedCompanies.filter(company => {
                return company.status === PossibleStatus.NO_CHANGES
            })
        case FilterStates.BY_ALL:
        default:
            return adjustedCompanies
    }
}
export const normalizeData = (
    resultData,
    affiliations = [],
    affiliationId = false,
) => {
    for (const obj in resultData) {
        resultData[obj] = resultData[obj] === null ? '' : resultData[obj]
        if (obj === 'affiliation_data') {
            for (const extendedObj in resultData[obj][affiliationId]) {
                let prefixedExtendedObj = `${extendedSchemaPrefix}_${extendedObj}`
                let extendedVal = resultData[obj][affiliationId][extendedObj]
                resultData[prefixedExtendedObj] =
                    extendedVal && extendedVal.toString()
                resultData[prefixedExtendedObj] = normalizeDataHelper(
                    resultData,
                    prefixedExtendedObj,
                )
            }
            delete resultData[obj]
        } else if (obj === 'affiliations') {
            resultData['affiliate'] = 'false'
            if (affiliations[affiliationId]) {
                resultData['affiliate'] = resultData[obj].includes(
                    affiliations[affiliationId].name,
                )
            }
            resultData['affiliate'] = resultData['affiliate'].toString()
        } else {
            if (typeof resultData[obj] === 'object') {
                resultData[obj] = resultData[obj].toString()
            }
            resultData[obj] = normalizeDataHelper(resultData, obj)
        }
    }
    return resultData
}

const normalizeDataHelper = (resultData, obj) => {
    // name normalization should be avoided as much as possible
    if(obj !== 'name' && typeof resultData[obj] === 'string'){
        resultData[obj] =  resultData[obj].replace(/,\s/g, ',')
    }

    // normalize all possible options for affiliate to either 'true' or 'false'
    if (obj === 'affiliate') {
        resultData[obj] = normalizeAffiliateBool(resultData[obj])
    }
    // normalize all 'number' strings, i.e. convert 2.000 to 2,
    // 1.23000000 to 1.23 etc
    else if (
        obj !== 'id' &&
        !isNaN(resultData[obj]) &&
        resultData[obj] !== ''
    ) {
        let converted = Number(resultData[obj])
        converted = String(converted)
        resultData[obj] = converted
    }
    return resultData[obj]
}

export const normalizeIngest = (ingestData, companyData, affiliationId) =>
    ingestData.map(obj => {
        const { company, warnings, errors } = obj
        let normalizedObj = {}
        const errorKeys = Object.keys(errors).map(errorKey =>
            errorKey.split('.')[1] == affiliationId
                ? normalizeExtendedColumnName(errorKey.split('.').pop())
                : errorKey,
        )
        const warningKeys = Object.keys(warnings).map(warningKey =>
            warningKey.split('.')[1] == affiliationId
                ? normalizeExtendedColumnName(warningKey.split('.').pop())
                : warningKey,
        )
        const invalidKeys = _.union(errorKeys, warningKeys)
        let status = PossibleStatus.INGESTED
        if (errorKeys.length) {
            status = PossibleStatus.ERROR
        } else if (warningKeys.length) {
            status = PossibleStatus.WARNING
        }
        normalizedObj['ingestStatus'] = status
        // populate ingestErrorKeys
        let ingestedCompany = companyData.find(existingCompany => {
            if (company.id) {
                return (
                    existingCompany.name === company.name ||
                    existingCompany.dbId == company.id
                )
            } else {
                return existingCompany.name === company.name
            }
        })
        normalizedObj['ingestErrorKeys'] = {}
        invalidKeys.forEach(key => {
            switch (key) {
                // if affiliation id is one of the errors/warnings then throw all imported extended schema keys into here
                case 'affiliation_id':
                    // invalidKeys.splice(index, 1)
                    ingestedCompany.merges.forEach(merge => {
                        if (isExtendedKey(merge)) {
                            normalizedObj['ingestErrorKeys'][merge] = true
                        }
                    })
                    break
                // if company is one of the errors/warnings then throw all keys into here
                case 'company':
                    ingestedCompany.merges.forEach(
                        merge =>
                            (normalizedObj['ingestErrorKeys'][merge] = true),
                    )
                    return
                case 'unaffiliate':
                    normalizedObj['ingestErrorKeys']['affiliate'] = true

                default:
                    normalizedObj['ingestErrorKeys'][key] = true
                    break
            }
        })
        normalizedObj['ingestErrorKeys'] = Object.keys(
            normalizedObj['ingestErrorKeys'],
        )
        normalizedObj['ingestId'] = ingestedCompany.dbId || null
        normalizedObj['ingestName'] = ingestedCompany.name
        return normalizedObj
    })

export const getRows = (state, undoState) => {
    return state[`rows${undoState < 0 ? 0 : undoState}`]
}
export const filterId = (id, dataObj) => {
    const newObj = { ...dataObj }
    delete newObj.id
    return newObj
}
export const truncateText = (str, length = 100, ending = '...') => {
    if (str.length > length) {
        return {
            text: str.substring(0, length - ending.length) + ending,
            truncated: true,
        }
    } else {
        return {
            text: str,
            truncated: false,
        }
    }
}
//return an array [1, 2, ... , n]
//if possible show 7 numbers at a time
//also if possible make current index the middle index in array
//In the future this may not be 7 but hardcoded for now
export const createIncrementingArray = (n, current) => {
    var start, fin
    if (n < 7) {
        start = 1
        fin = n
    } else if (current <= 4) {
        start = 1
        fin = 7
    } else {
        fin = current + 3 < n ? current + 3 : n
        start = current + 3 < n ? current - 3 : n - 6
    }
    return Array(fin - start + 1)
        .fill()
        .map((item, index) => start + index)
    // for()
}
export const createCompanySuggestionsArray = (
    companyNames,
    normalizedCompanyData,
) => {
    const matchy = []
    for (let data of companyNames) {
        let normalizedName = data.replace(/\s/g, '').toUpperCase()
        let matchArray = normalizedCompanyData.filter(res => {
            let result = false
            if (res.normalizedName.length < normalizedName.length) {
                result = normalizedName.includes(res.normalizedName)
            } else {
                result = res.normalizedName.includes(normalizedName)
            }
            if (!result && res.normalizedLegalName.length) {
                if (res.normalizedLegalName.length < normalizedName.length) {
                    result = normalizedName.includes(res.normalizedLegalName)
                } else {
                    result = res.normalizedLegalName.includes(normalizedName)
                }
            }
            return result
        })
        matchy.push({ name: data, similar: matchArray })
    }
    return matchy
}
export const checkExactMatch = (companyMeta, id) => {
    return companyMeta[id].isExactMatch
        ? PossibleStatus.NO_CHANGES
        : PossibleStatus.PENDING
}
// export const firstLetterUpper = string => {
//     return string.charAt(0).toUpperCase() + string.substr(1)
// }
//Convert all spaces to underscore and make all characters lowercase
export const normalizeColumnName = string => {
    if (string) {
        return string
            .trim()
            .replace(/ /g, '_')
            .toLowerCase()
    }
}

export const normalizeExtendedColumnName = string => {
    if (string) {
        return normalizeColumnName(`${extendedSchemaPrefix}_${string}`)
    }
}

//opposite of normalizeColumnName
export const denormalizeColumnName = string => {
    //check if we have a mapping for this column name
    if (companyKeys[string]) return companyKeys[string].label
    const extensionRe = new RegExp(`^${extendedSchemaPrefix}`)
    if (string) {
        return string
            .trim()
            .replace(extensionRe, '')
            .replace(/_/g, ' ')
            .replace(/\b[a-z]/g, function(firstLetter) {
                return firstLetter.toUpperCase()
            })
    }
}
export const filterSaveRows = (
    rows,
    companyData,
    skippedRowsIds,
    selectedOptions,
) => {
    const skippedRowsArray = rows.filter((row, index) =>
        skippedRowsIds.includes(index),
    )
    let newRows = Object.values(companyData).map(company => {
        let newRow = []
        selectedOptions.forEach(option => {
            if (company[option]) newRow.push(company[option])
            else newRow.push('')
        })
        return newRow
    })
    const newRowsLength = newRows.length
    newRows = newRows.concat(skippedRowsArray)
    const newRowsSkipped = skippedRowsIds.map(
        (rowIndex, index) => newRowsLength + index,
    )
    return { newRows, newRowsSkipped }
}
export const compareCompanyKeys = (
    newKey,
    newVal,
    affiliationId,
    currentCompany,
) => {
    const compareVal =
        currentCompany[newKey] ||
        findExtendedValue(newKey, affiliationId, currentCompany)
    return _.isEqual(newVal, compareVal)
}

export const findExtendedValue = (key, affiliationId, company) => {
    const { affiliation_data } = company
    //normalize key if necessary
    const normalizedKey = resetExtendedKey(key)
    return affiliation_data && affiliation_data[affiliationId]
        ? affiliation_data[affiliationId][normalizedKey]
        : null
}

// use this to remove extended prefix from key
export const resetExtendedKey = key => {
    const extensionRe = new RegExp(`^${extendedSchemaPrefix}_`)
    return key.replace(extensionRe, '')
}

export const isExtendedKey = key => {
    const keySplit = key.split('_')
    return keySplit[0] === extendedSchemaPrefix
}

export const organizeKeysForIngest = (company, fields, affiliationId) => {
    return fields.reduce((map, field) => {
        if (field !== 'id') {
            if (isExtendedKey(field)) {
                if (!map['affiliation_data']) {
                    map['affiliation_data'] = { [affiliationId]: {} }
                }
                map['affiliation_data'][affiliationId][
                    resetExtendedKey(field)
                ] = company[field]
            } else {
                map[field] = company[field]
            }
        }
        return map
    }, {})
}

export const generateModalBody = body => {
    if (Array.isArray(body)) {
        return (
            <div>
                {body.map(paragraph => (
                    <p>{paragraph}</p>
                ))}
            </div>
        )
    }
    return <div>{body}</div>
}

export const normalizeAffiliateBool = affiliateBool => {
    const trueRe = new RegExp('^t(rue)?$|^1$', 'i')
    const falseRe = new RegExp('^f(alse)?$|^0$', 'i')
    if (affiliateBool) {
        return affiliateBool
            .trim()
            .replace(trueRe, 'true')
            .replace(falseRe, 'false')
    }
}

export const getColorClass = status => {
    switch (status) {
        case PossibleStatus.MERGED:
        case PossibleStatus.ADDED:
        case PossibleStatus.NO_CHANGES:
            return 'secondary'
        case PossibleConflictStatus.NEW:
        case PossibleMergeStatus.CURRENT:
        case PossibleStatus.INGESTED:
            return 'success'
        case PossibleConflictStatus.CONFLICT:
        case PossibleMergeStatus.INCOMING:
        case PossibleStatus.ERROR:
            return 'danger'

        case PossibleStatus.PENDING:
        case PossibleStatus.WARNING:
            return 'warning'

        default:
            return 'primary'
    }
}

export const emptyCompanyObj = {
    id: '',
    name: '',
    legal_name: '',
    category: '',
    sector: '',
    subsectors: '',
    local_employee_count: '',
    website: '',
    founding_year: '',
    address: '',
    city: '',
    postal_code: '',
    description: '',
    organization_type: '',
    company_status: '',
    headquarters: '',
    summary: '',
    capital_raised: '',
    size: '',
    revenue: '',
    revenue_stage: '',
    global_employee_count: '',
    ticker: '',
    phone: '',
    linked_in: '',
    facebook_url: '',
    twitter_id: '',
    acquired_by_name: '',
    acquired_date: '',
    acquisition_amount: '',
    industries: '',
    demograpgics: '',
    hours: '',
    email: '',
    notes: '',
    eligibility_notes: '',
    contact_notes: '',
}
export const colors = {
    app_header: '#253a57',
    app_header_text: 'white',
    body: 'white',
    company_header: '#afd9ee',
    conflict_panel: 'white',
    danger_text: '#721c24',
    danger_background: '#f5c6cb',
    show_button: 'rgb(242, 113, 28)',
    status_box: '#253a57',
    text: '#253a57',
}
