import { apply, select } from 'redux-saga/effects'
import { flaskProxy } from './clientProxy'
import { getApp } from './selectors'

export function* updateOptions(options = {}) {
    const { token, destdomain } = yield select(getApp)
    if (!options['headers']) options['headers'] = {}
    options['headers']['x-session-token'] = token
    options['headers']['x-session-destdomain'] = destdomain
    return options
}

export function* callFlaskProxy(url, options) {
    const updatedOptions = yield updateOptions(options)
    return yield apply(flaskProxy, flaskProxy.perform, [url, updatedOptions])
}
