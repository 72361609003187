import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap'

import { PossibleStatus } from '../actions/companies'
import { SortStates, FilterStates } from '../actions/sort'
import '../styles/SortStatusButton.css'

// const sortStateToDisplayed = {
//     [SortStates.BY_ADDED]: 'ADDED',
//     [SortStates.BY_PENDING]: 'PENDING',
//     [SortStates.BY_MERGED]: 'MERGED',
//     [SortStates.BY_NEW]: 'NEW',
// }

const filterStateLabels = {
    [FilterStates.BY_ALL]: 'ALL',
    [FilterStates.BY_PENDING]: 'PENDING',
    [FilterStates.BY_INGEST_READY]: 'INGEST READY',
    [FilterStates.BY_NO_CHANGES]: 'NO CHANGES',
}

/**
 * Filter selection button
 */
export class SortStatusButton extends Component {
    state = {
        open: false,
    }

    toggle = () => {
        this.setState(prevState => ({
            open: !prevState.open,
        }))
    }

    render() {
        const { status, numOfStatus, clickHandler } = this.props
        const { open } = this.state

        return (
            <Dropdown
                isOpen={open}
                toggle={() => this.toggle()}
                className="text-center sort-btn"
            >
                <DropdownToggle
                    color="white"
                    className="admin-tool-font no-focus-outline border"
                    caret
                >
                    {status} ({numOfStatus})
                </DropdownToggle>
                <DropdownMenu>
                    {Object.values(FilterStates).map(
                        (filterState, idx, array) => (
                            <Fragment key={idx}>
                                <DropdownItem
                                    onClick={() => clickHandler(filterState)}
                                >
                                    {filterStateLabels[filterState]}
                                </DropdownItem>

                                {/* don't display the last separator */}
                                {idx !== array.length - 1 && (
                                    <DropdownItem divider />
                                )}
                            </Fragment>
                        ),
                    )}
                </DropdownMenu>
            </Dropdown>
        )
    }
}

SortStatusButton.propTypes = {
    clickHandler: PropTypes.func.isRequired,
    status: PropTypes.oneOf(Object.values(PossibleStatus)).isRequired,
}
