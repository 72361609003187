import { combineReducers } from 'redux'
import reduceReducers from 'reduce-reducers'
import {
    app
} from './app'
import {
    companies,
    displayedCompanies,
    companyMeta,
    companyData,
    currSelectedCompany,
    companyDataCurrent,
    stagedForIngest,
    ingested,
    fetching,
    companySuggestions,
    serverCompanyData,
    normalizedServerCompanyNames,
} from './companies'
import { sortStatusField } from './sort'
import {
    errors,
    filenames,
    filesData,
    selectedFile,
    rows0,
    rows1,
    rows2,
    rows3,
    rows4,
    options,
    optionMappings,
    selectedOptions,
    skippedRows,
    skippedCols,
    itemErrors,
    required,
    tableExpanded,
    types,
    rowsReducer,
    undoState,
} from './validate'
import { userAffiliationId, affiliations } from './affiliations'
import { pageIndex, pageSize, jumpIndex } from './table'
import { modals, actionModals } from './modals'

const mainReducer = combineReducers({
    app,
    //state for general
    modals,
    actionModals,
    // state for 'validator' stage a.k.a route at '/'
    errors,
    filenames,
    filesData,
    selectedFile,
    rows0,
    rows1,
    rows2,
    rows3,
    rows4,
    options,
    optionMappings,
    selectedOptions,
    required,
    skippedRows,
    skippedCols,
    itemErrors,
    fetching,
    pageIndex,
    pageSize,
    jumpIndex,
    types,
    undoState,
    companySuggestions,
    serverCompanyData,
    normalizedServerCompanyNames,
    userAffiliationId,
    affiliations,

    // state for 'merge' stage a.k.a route at '/merge'
    companies,
    displayedCompanies,
    companyMeta,
    companyData,
    companyDataCurrent,
    currSelectedCompany,
    stagedForIngest,
    ingested,
    sortStatusField,
})

export const rootReducer = reduceReducers(mainReducer, rowsReducer)
