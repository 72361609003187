import { PossibleStatus } from '../actions/companies'

export const companyKeys = {
    name: {
        label: 'Name',
    },
    category: {
        label: 'Category',
    },
    sector: {
        label: 'Sector',
    },
    affiliate: {
        label: 'Affiliate Company',
    },
    legal_name: {
        label: 'Legal Name',
    },
    subsectors: {
        label: 'Sub Sectors',
    },
    normalized_name: {
        label: 'Normalized Name',
    },
    website: {
        label: 'Web Site',
    },
    organization_type: {
        label: 'Organization Type',
    },
    description: {
        label: 'Description',
    },
    summary: {
        label: 'Tagline',
    },
    founding_year: {
        label: 'Year Founded',
    },
    local_employee_count: {
        label: 'Local Employees',
    },
    global_employee_count: {
        label: 'Global Employees',
    },
    capital_raised: {
        label: 'Capital Raised',
    },
    market_cap: {
        label: 'Market Cap',
    },
    ticker: {
        label: 'Ticker Symbol',
    },
    address: {
        label: 'Local Office Address',
    },
    city: {
        label: 'Local Office City',
    },
    postal_code: {
        label: 'Postal/Zip Code',
    },
    industries: {
        label: 'Industries',
    },
    service_categories: {
        label: 'Service Categories',
    },
    organization_name: {
        label: 'Organization Name',
    },
    demograpgics: {
        label: 'Demographics',
    },
    hours: {
        label: 'Hours',
    },
    email: {
        label: 'Email',
    },
    notes: {
        label: 'Notes',
    },
    eligibility_notes: {
        label: 'Eligibility Notes',
    },
    contact_notes: {
        label: 'Contact Notes',
    },
    latitude: {
        label: 'Latitude',
    },
    longitude: {
        label: 'Longitude',
    },
    market_cap_currency: {
        label: 'Market Cap Cur',
    },
    company_status: {
        label: 'Operating Status',
    },
    business_number: {
        label: 'Business Number',
    },
    logo: {
        label: 'Logo',
    },
    logo_src: {
        label: 'Logo Source',
    },
    headquarters: {
        label: 'Headquarter City, State',
    },
    size: {
        label: 'Headcount',
    },
    revenue: {
        label: 'Annual Revenue',
    },
    revenue_stage: {
        label: 'Revenue Stage',
    },
    revenue_growth: {
        label: 'Revenue Growth',
    },
    employee_growth: {
        label: 'Employee Growth',
    },
    phone: {
        label: 'Phone Number',
    },
    facebook_url: {
        label: 'Facebook URL',
    },
    linkedin_url: {
        label: 'LinkedIn URL',
    },
    twitter_id: {
        label: 'Twitter Handle',
    },
    src: {
        label: 'Data Source',
    },
    membership: {
        label: 'Membership',
    },
    is_local: {
        label: 'Is Company Local',
    },
    acquired_by_name: {
        label: 'Acquired By',
    },
    acquired_date: {
        label: 'Acquisition Date',
    },
    acquisition_amount: {
        label: 'Acquisition Amount',
    },
    fund_size: {
        label: 'Fund Size',
    },
    fund_type: {
        label: 'Fund Type',
    },
    investment_sector: {
        label: 'Investment Sector',
    },
    revenue_history: {
        label: 'Annual Revenue History',
    },
    local_employee_history: {
        label: 'Local Employee History',
    },
    global_employee_history: {
        label: 'Global Employee History',
    },
    geolookup: {
        label: 'Get Lat/Lng',
    },
}

export const categoryKeys = {
    'FINANCIAL SERVICES': {
        label: 'OTHER',
    },
    OTHER: {
        label: 'FINANCIAL SERVICES',
    },
}

export const ingestKeys = {
    success: PossibleStatus.INGESTED,
    warning: PossibleStatus.WARNING,
    error: PossibleStatus.ERROR,
}

export const enumKeys = {
    affiliations: {
        label: 'affiliations',
    },
    sectors: {
        label: 'sector',
    },
    growth: {
        label: 'employee_growth',
    },
    revenue_stage: {
        label: 'revenue_stage',
    },
    size: {
        label: 'size',
    },
    organization_types: {
        label: 'organization_type',
    },
    company_status: {
        label: 'company_status'
    }
}
