import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Alert, Button, Card } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { clearErrors } from '../actions/validate'
import { OptionalHide } from './OptionalHide'
import '../styles/Errors.css'

class ShownErrors extends Component {
    render() {
        const { errors, clearErrs } = this.props

        return (
            <OptionalHide hidden={errors.length === 0}>
                <Card className="border-0">
                    {errors.map((error, idx) => (
                        <Alert
                            key={idx}
                            color={error.type}
                            className="p-2 mb-0"
                        >
                            <FontAwesomeIcon
                                icon={
                                    error.type === 'success'
                                        ? 'check'
                                        : 'exclamation'
                                }
                                className="mr-2 ml-1"
                            />
                            {error.message}
                            {error.importantMessage && (
                                <strong>{error.importantMessage}</strong>
                            )}
                        </Alert>
                    ))}
                </Card>
                {errors.length > 0 && (
                    <div className="dismiss-all-container" onClick={clearErrs}>
                        <Button
                            className="border-0 float-right d-flex"
                            color="danger"
                            outline
                            size="sm"
                        >
                            Dismiss All
                            <FontAwesomeIcon
                                className="ml-1 align-self-center"
                                icon="times-circle"
                            />
                        </Button>
                    </div>
                )}
            </OptionalHide>
        )
    }
}

ShownErrors.propTypes = {
    errors: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export const Errors = connect(
    //mapStateToProps
    state => ({ errors: state.errors }),
    dispatch => ({ clearErrs: () => dispatch(clearErrors()) }),
)(ShownErrors)
