export const ModalActions = {
    SET_MESSAGE_MODAL: 'SET_MESSAGE_MODAL',
    CONFIRM_MODAL_ACTION: 'CONFIRM_MODAL_ACTION',
    ACTION_MODAL_TOGGLE: 'ACTION_MODAL_TOGGLE',
}

export const setMessageModal = (isOpen, data) => ({
    type: ModalActions.SET_MESSAGE_MODAL,
    isOpen,
    data,
})

export const confirmModalAction = () => ({
    type: ModalActions.CONFIRM_MODAL_ACTION,
})

export const actionModalToggle = (modalType, column) => ({
    type: ModalActions.ACTION_MODAL_TOGGLE,
    modalType,
    column,
})
