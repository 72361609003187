export const missingOptions = [
    'id',
    'aliases',
    'market_cap',
    'information_status',
    'business_number',
    'region',
    'market_cap_currency',
    'latitude',
    'longitude',
    'logo',
    'revenue_growth',
    'employee_growth',
    'membership',
    'is_local',
    'chatnels_url',
    'fund_type',
    'fund_size',
    'investment_sector',
    'business_number',
    'src',
    'normalized_name',
    'acquired_by_id',
]

export const permanentOptions = [
    'affiliate'
]
export const extendedSchemaPrefix = 'extsch'

//expand to include all strings that are to be blacklisted from column creation
export const blackList = ['0', '_', extendedSchemaPrefix]

