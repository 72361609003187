import React, { Fragment } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

// views
import { BadToken } from '../components/BadToken'
import {AppContainer} from '../containers/AppContainer'
import { ValidateToolContainer } from '../containers/ValidateToolContainer'
import { MergeToolContainer } from '../containers/MergeToolContainer'
import MessageModal from '../components/MessageModal'

export const Routes = (
    <Fragment>
        <MessageModal />
        <Switch>
            <Route exact path="/" component={AppContainer} />
            <Route exact path="/validate" component={ValidateToolContainer} />
            <Route exact path="/merge" component={MergeToolContainer} />
            <Route exact path="/badtoken" component={BadToken} />
            <Route render={() => <Redirect to="/" />} />
        </Switch>
    </Fragment>
)
