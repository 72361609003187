import React from 'react'
import PropTypes from 'prop-types'

export const OptionalHide = ({ hidden, className, children }) => (
    <div className={hidden ? 'd-none' : `${className} d-block`}>{children}</div>
)

OptionalHide.propTypes = {
    hidden: PropTypes.bool.isRequired,
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]),
}
