import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, ListGroup, ListGroupItem } from 'reactstrap'

import { CompanyHeader } from './CompanyHeader'
import {
    PossibleMergeStatus,
    PossibleStatus,
    EditStatus,
} from '../actions/companies'
import { StageButtonsContainer } from '../containers/StageButtonsContainer'
import { FieldMergeTileContainer } from '../containers/FieldMergeTileContainer'
import { MergeAllButtonsContainer } from '../containers/MergeAllButtonsContainer'
import { OptionalDisable } from './OptionalDisable'
import '../styles/ConflictPanel.css'

export class ConflictPanel extends Component {
    componentDidUpdate(prevProps) {
        // after the 'merge all' button is clicked, shift focus to the stage button
        // so conflicts can be merged and staged quickly by repeatedly pressing enter
        if (this.shouldFocusStageButton === true) {
            this.focusElement.focus()
            this.shouldFocusStageButton = false
        }
    }

    render() {
        // noChanges signifies when incoming data and current data is exactly the same,
        // on the ui, grey the panel out
        const { name, pendingStatus, fieldsList, noChanges } = this.props
        const shouldDisable =
            pendingStatus === PossibleStatus.ADDED ||
            pendingStatus === PossibleStatus.MERGED
        return (
            <div className="d-flex flex-column justify-content-between full-height">
                <span className="absolute-center-border">&nbsp;</span>
                <Row className="ml-0 mr-0 conflict-header">
                    <div className="d-flex flex-column justify-content-center p-0 col-sm-6 conflict-header conflict-header-background general-header border-bottom">
                        <CompanyHeader
                            name={name}
                            pendingStatus={pendingStatus}
                            mergeStatus={PossibleMergeStatus.INCOMING}
                        />
                    </div>
                    <div className="d-flex flex-column p-0 conflict-header col-sm-6 conflict-header-background general-header border-bottom fixed-height-header">
                        <CompanyHeader
                            name={name}
                            pendingStatus={pendingStatus}
                            mergeStatus={
                                shouldDisable
                                    ? EditStatus.FINAL_EDIT
                                    : PossibleMergeStatus.CURRENT
                            }
                        />
                    </div>
                </Row>
                <OptionalDisable
                    disabled={noChanges}
                    className="d-flex flex-column justify-content-between full-height overflow-y-auto"
                >
                    {!shouldDisable && !noChanges && (
                        <div className="d-flex align-self-center text-center bg-white z-10 merge-font p-0 fixed-height-button">
                            <MergeAllButtonsContainer
                                fieldsList={fieldsList}
                                onMergeAll={() =>
                                    (this.shouldFocusStageButton = true)
                                }
                            />
                        </div>
                    )}
                    <ListGroup flush className="hoverflay-y">
                        {fieldsList.map((field, idx) => {
                            return (
                                <ListGroupItem
                                    action
                                    key={idx}
                                    className="pb-0 pt-0 border-bottom"
                                >
                                    <FieldMergeTileContainer
                                        fieldName={field}
                                        disableHalf={shouldDisable}
                                    />
                                </ListGroupItem>
                            )
                        })}
                    </ListGroup>
                </OptionalDisable>
                <div className="text-center bg-white z-10 bottom-nav-height border-top">
                    {noChanges ? (
                        <p className="text-muted">No Changes</p>
                    ) : (
                        <StageButtonsContainer
                            onClickNextStatus={PossibleStatus.MERGED}
                            focusRef={el => (this.focusElement = el)}
                        />
                    )}
                </div>
            </div>
        )
    }
}

ConflictPanel.propTypes = {
    name: PropTypes.string.isRequired,
    pendingStatus: PropTypes.oneOf(Object.values(PossibleStatus)).isRequired,
    fieldsList: PropTypes.arrayOf(PropTypes.string).isRequired,
}
