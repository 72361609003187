import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import PropTypes from 'prop-types'

export default class ActionModal extends React.PureComponent {
    render() {
        const {
            modalType,
            head,
            body,
            onAcceptFunc,
            actionModalToggle,
            actionModals,
            okButton,
            cancelButton,
            customFooter,
        } = this.props
        return (
            <Modal isOpen={actionModals[modalType].isOpen}>
                <ModalHeader toggle={() => actionModalToggle(modalType)}>
                    {head}
                </ModalHeader>
                <ModalBody>{body}</ModalBody>
                <ModalFooter>
                    {customFooter
                        ? customFooter
                        : [
                              cancelButton && (
                                  <Button
                                      className="btn-colored fixed-height-btn no-focus-outline"
                                      color="dark"
                                      onClick={() =>
                                          actionModalToggle(modalType)
                                      }
                                  >
                                      {cancelButton}
                                  </Button>
                              ),

                              okButton && (
                                  <Button
                                      className="btn-colored btn-green fixed-height-btn admin-tool-font no-focus-outline"
                                      onClick={() => {
                                          onAcceptFunc()
                                      }}
                                  >
                                      {okButton}
                                  </Button>
                              ),
                          ]}
                </ModalFooter>
            </Modal>
        )
    }
}

ActionModal.propTypes = {
    modalType: PropTypes.string.isRequired,
    head: PropTypes.string.isRequired,
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    onAcceptFunc: PropTypes.func,
    okButton: PropTypes.string,
    cancelButton: PropTypes.string,
    customFooter: PropTypes.element,
    actionModalToggle: PropTypes.func.isRequired,
    actionModals: PropTypes.object.isRequired,
}
