import { all, takeEvery } from 'redux-saga/effects'
import {
    getCurrentDataHandler,
    ingestCompaniesHandler,
    fetchServerCompanyDataHandler,
    saveCompanies,
} from './companies'
import {
    uploadFileHandler,
    deleteFileHandler,
    selectFileHandler,
    checkColHandler,
    getItemErrorsHandler,
    expandHandler,
    inferHandler,
    tryAndProceedToMergeStateHandler,
    addColumnHandler,
} from './validate'
import { CompanyActions } from '../actions/companies'
import { FetchActions } from '../actions/companies'
import { ValidatorActions } from '../actions/validate'

export function* watchAll() {
    yield all([
        takeEvery(CompanyActions.INGEST_COMPANIES, ingestCompaniesHandler),
        takeEvery(CompanyActions.SAVE_COMPANIES, saveCompanies),
        takeEvery(
            FetchActions.FETCH_SERVER_COMPANY_DATA,
            fetchServerCompanyDataHandler,
        ),
        takeEvery(ValidatorActions.UPLOAD_FILE, uploadFileHandler),
        takeEvery(ValidatorActions.DELETE_FILE, deleteFileHandler),
        takeEvery(ValidatorActions.SELECT_FILE, selectFileHandler),
        takeEvery(ValidatorActions.CHECK_ITEMS_COL, checkColHandler),
        takeEvery(ValidatorActions.GET_ITEM_ERRORS, getItemErrorsHandler),
        takeEvery(ValidatorActions.INFER_SETTINGS, inferHandler),
        takeEvery(
            ValidatorActions.TRY_AND_PROCEED_TO_MERGE_STATE,
            tryAndProceedToMergeStateHandler,
        ),
        takeEvery(ValidatorActions.ADD_NEW_COLUMN, addColumnHandler),
    ])
}
