import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { ErrorPopoverContainer } from '../containers/ErrorPopoverContainer'
import { NameConflictPopover } from './NameConflictPopover'
import { OptionalDisable } from './OptionalDisable'
import '../styles/InteractiveTableRowItem.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export class InteractiveTableRowItem extends PureComponent {
    render() {
        const {
            data,
            rowNum,
            colNum,
            disabled,
            updateItemContents,
            hasErrors,
            hasNameWarning,
            selected,
        } = this.props

        return (
            <OptionalDisable
                disabled={disabled}
                className="d-flex justify-content-between align-items-center fill"
            >
                <Fragment>
                    <div
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                e.preventDefault()
                                e.target.blur()
                                window.getSelection().removeAllRanges()
                            }
                        }}
                        suppressContentEditableWarning
                        className={`${hasErrors &&
                            'text-danger'} min-width pr-3`}
                        contentEditable
                        onBlur={e => {
                            if (e.target.innerText !== String(data)) {
                                updateItemContents(e.target.innerText)
                            }
                        }}
                    >
                        {data}
                    </div>
                    {hasNameWarning && (
                        <NameConflictPopover row={rowNum} col={colNum} />
                    )}
                    {hasErrors && (
                        <ErrorPopoverContainer row={rowNum} col={colNum} />
                    )}
                </Fragment>
            </OptionalDisable>
        )
    }
}

InteractiveTableRowItem.defaultProps = {
    data: '',
    disabled: false,
    rowNum: 0,
    colNum: 0,
    hasErrors: false,
    hasNameWarning: false,
    selected: '',
}

InteractiveTableRowItem.propTypes = {
    data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    disabled: PropTypes.bool.isRequired,
    rowNum: PropTypes.number.isRequired,
    colNum: PropTypes.number.isRequired,
    updateItemContents: PropTypes.func.isRequired,
    hasErrors: PropTypes.bool.isRequired,
    hasNameWarning: PropTypes.bool.isRequired,
    selected: PropTypes.string.isRequired,
}
