import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button } from 'reactstrap'
import ExpandingTile from './ExpandingTile'
import { OptionalDisable } from './OptionalDisable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../styles/FieldMergeTile.css'

export const FieldMergeTile = ({
    fieldName,
    disableHalf,
    incomingVal,
    currVal,
    conflicting,
    handleMerge,
    error,
}) => {
    const merged = incomingVal === currVal
    const showMerge = conflicting && !merged ? 'visible' : 'invisible'
    const showUnmerge = conflicting && merged ? 'visible' : 'invisible'

    return (
        <Row className="p-0 pt-1 pb-1 m-0">
            <Col className="d-flex p-0 m-0">
                {error && (
                    <FontAwesomeIcon
                        icon="exclamation"
                        className="mr-2 ml-1 align-self-center"
                    />
                )}
                <OptionalDisable
                    disabled={disableHalf}
                    className="flex-grow-1 align-self-center"
                >
                    <ExpandingTile
                        conflicting={conflicting}
                        fieldName={fieldName}
                        merged={merged}
                        value={incomingVal}
                        error={error}
                    />
                </OptionalDisable>
                <Button
                    disabled={disableHalf}
                    onClick={() => handleMerge()}
                    className={`align-self-center pt-0 pb-0 conflict-btn custom-btn-outline-success ${showMerge}`}
                    tabIndex="-1"
                >
                    <FontAwesomeIcon icon="angle-double-right" />
                </Button>
            </Col>
            <Col className="d-flex p-0 m-0">
                <Button
                    disabled={disableHalf}
                    color="danger"
                    outline
                    onClick={() => handleMerge()}
                    className={`align-self-center pt-0 pb-0 conflict-btn custom-btn-outline-danger ${showUnmerge}`}
                    tabIndex="-1"
                >
                    <FontAwesomeIcon icon="times" />
                </Button>
                <ExpandingTile
                    conflicting={conflicting}
                    fieldName={fieldName}
                    merged={merged}
                    value={currVal}
                />
            </Col>
        </Row>
    )
}

FieldMergeTile.propTypes = {
    fieldName: PropTypes.string.isRequired,
    incomingVal: PropTypes.string.isRequired,
    currVal: PropTypes.string.isRequired,
    conflicting: PropTypes.bool.isRequired,
    handleMerge: PropTypes.func.isRequired,
    error: PropTypes.bool.isRequired,
}
