import { connect } from 'react-redux'
import { CompanyList } from '../components/CompanyList'
import { SortStates } from '../actions/sort'
import { selectCompany } from '../actions/companies'
import { filterCompanyList } from '../helpers'
import { actionModalToggle } from '../actions/modals'

const sortCompanyList = (companies, companyMeta, sortStatusField) => {
    const shownCompanies = companies.map(id => ({
        ...companyMeta[id],
    }))

    switch (sortStatusField) {
        case SortStates.BY_NEW:
            return shownCompanies.sort((a, b) => {
                if (a.isConflicting && !b.isConflicting) return 1
                if (!a.isConflicting && b.isConflicting) return -1
                return 0
            })
        case SortStates.BY_ADDED:
            return shownCompanies.sort((a, b) => {
                if (a.status < b.status) return -1
                if (a.status > b.status) return 1
                return 0
            })
        case SortStates.BY_MERGED:
            return shownCompanies.sort((a, b) => {
                return b.status.lastIndexOf('D') - a.status.lastIndexOf('D')
            })
        case SortStates.BY_PENDING:
        default:
            return shownCompanies.sort((a, b) => {
                if (a.status < b.status) return 1
                if (a.status > b.status) return -1
                return 0
            })
    }
}

const mapStateToProps = state => {
    const sortedCompanies = filterCompanyList(
        state.displayedCompanies,
        state.companyMeta,
        state.sortStatusField,
    )
    const sortedIds = sortedCompanies.map(company => company.id)
    // const prevIdIdx = sortedIds.indexOf(state.currSelectedCompany) - 1
    // const nextIdIdx = sortedIds.indexOf(state.currSelectedCompany) + 1
    return {
        canIngest: state.stagedForIngest.length > 0,
        numToIngest: state.stagedForIngest.length,
        companies: sortedCompanies,
        noCompanies: sortedCompanies.length <= 0,
        activeId: state.currSelectedCompany,
        fullyMerged:
            state.stagedForIngest.length ===
            state.companies.length - state.ingested.length,
        fullyIngested: state.companies.length === state.ingested.length,
        // prevId:
        //     prevIdIdx < 0
        //         ? sortedIds[sortedIds.length - 1]
        //         : sortedIds[prevIdIdx],
        // nextId:
        //     nextIdIdx >= sortedIds.length ? sortedIds[0] : sortedIds[nextIdIdx],
    }
}

const mapDispatchToProps = dispatch => ({
    handleSelection: (id, name) => dispatch(selectCompany(id, name)),
    actionModalToggle: type => dispatch(actionModalToggle(type)),
})

export const CompanyListContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CompanyList)
