import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import ReactTable from 'react-table'
import { RowToggle } from './RowToggle'
import { InteractiveTableHeaderItemContainer } from '../containers/InteractiveTableHeaderItemContainer'
import { InteractiveTableRowItemContainer } from '../containers/InteractiveTableRowItemContainer'
import { InteractiveTableOriginCellContainer } from '../containers/InteractiveTableOriginCellContainer'
import { AddColumnModalNameOnlyContainer } from '../containers/AddColumnModalNameOnlyContainer'
import { InteractiveTableFooterContainer } from '../containers/InteractiveTableFooterContainer'
import '../styles/InteractiveTable.css'
import 'react-table/react-table.css'

const isNumber = str => {
    const parsed = parseInt(str)
    if (isNaN(parsed)) {
        return false
    } else {
        return true
    }
}

const createColumns = (rows, offset) => {
    const numCols = Math.max(...rows.map(row => row.length))
    return [
        // The disable row column
        {
            Header: () => <InteractiveTableOriginCellContainer />,
            Cell: ({ index }) => <RowToggle row={index + offset} />,
            // hardcoded for now?
            maxWidth: 40,
            maxHeight: 40,
            filterable: false,
            sortable: false,
            resizable: false,
        },
        ...[...Array(numCols).keys()].map(col => ({
            Header: () => <InteractiveTableHeaderItemContainer col={col} />,
            Cell: ({ index }) => (
                <InteractiveTableRowItemContainer
                    row={index + offset}
                    col={col}
                />
            ),
            accessor: String(col),

            // override default filter to add placeholder text
            Filter: ({ filter, onChange }) => (
                <input
                    className="w-100"
                    type="text"
                    placeholder="Search..."
                    value={filter ? filter.value : ''}
                    onChange={event => onChange(event.target.value)}
                />
            ),
            minWidth: 250,
        })),
    ]
}

export class InteractiveTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableLoad: false,
        }
        props.setPageSize(100)
    }

    render() {
        const {
            rows,
            skippedRows,
            skippedCols,
            pageIndex,
            pageSize,
            totalPageCount,
            setPageIndex,
            setPageSize,
            errorsList,
        } = this.props
        const offset = pageSize * pageIndex
        return (
            rows.length > 0 && (
                <Fragment>
                    <AddColumnModalNameOnlyContainer />

                    <ReactTable
                        pageSize={pageSize}
                        defaultPageSize={100}
                        getTdProps={(state, rowInfo, column) => {
                            if (rowInfo && column) {
                                const colSkipped = skippedCols.includes(
                                    Number(column.id),
                                )
                                const rowSkipped = skippedRows.includes(rowInfo.index + offset)
                                return {
                                    style: {
                                        background:
                                            (colSkipped || rowSkipped) &&
                                            column.id !== undefined
                                                ? '#f6f6f6'
                                                : '',
                                    },
                                }
                            }
                            return {}
                        }}
                        defaultSortMethod={(a, b) => {
                            // force null and undefined to the bottom
                            a = a === null || a === undefined ? '' : a
                            b = b === null || b === undefined ? '' : b
                            // force any string values to lowercase
                            a = typeof a === 'string' ? a.toLowerCase() : a
                            b = typeof b === 'string' ? b.toLowerCase() : b
                            // convert data type to Number if value is a number
                            a = isNumber(a) ? Number(a) : a
                            b = isNumber(b) ? Number(b) : b
                            // Return either 1 or -1 to indicate a sort priority
                            if (a > b) {
                                return 1
                            }
                            if (a < b) {
                                return -1
                            }
                            // returning 0, undefined or any falsey value will use subsequent sorts or
                            // the index as a tiebreaker
                            return 0
                        }}
                        minRows={0}
                        data={rows}
                        sortable={false}
                        columns={createColumns(rows, offset)}
                        className="-highlight overflow-hidden"
                        PaginationComponent={InteractiveTableFooterContainer}
                        showPagination={true}
                        showPaginationBottom={true}
                        TfootComponent={<Button>testing</Button>}
                    />
                </Fragment>
            )
        )
    }
}

InteractiveTable.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.array.isRequired).isRequired,
}
