import { put, call, all, apply, select } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import {
    showErrors,
    showFilenames,
    showFilesData,
    showSelectedFile,
    showRows,
    showOptions,
    showOptionMappings,
    showSelectedOptions,
    skipSelectedCols,
    skipSelectedRows,
    clearItemErrors,
    clearSkippedRows,
    addItemErrors,
    addItemErrorsCol,
    showRequiredFields,
    clearSkippedCols,
    selectOption,
    clearErrors,
    addColDataType,
    resetItemErrorsCol,
    addOptionMapping,
    skipCol,
    unskipCol,
    clearRowsHistory,
} from '../actions/validate'
import {
    fetchCompaniesBegin,
    fetchCompaniesEnd,
    clearCompanyData,
    addCompanyBatch,
    addCompanySuggestions,
    fetchServerCompanyData,
    clearCompanySuggestions,
    clearIngested,
} from '../actions/companies'
import { addUserAffiliationId, addAffiliations } from '../actions/affiliations'
import { setJumpIndex } from '../actions/table'
import { flaskProxy } from './clientProxy'
import { getCurrentDataHandler } from './companies'
import { navigateTo } from '../routes/history'
import { createCompanySuggestionsArray } from '../helpers'
import { extendedSchemaPrefix } from '../statics/columnStatics'
import { enumKeys } from '../statics/keyMapping'
import { union as _union } from 'lodash'
import { callFlaskProxy } from './utils'

function* hasErrors(code, msg = false) {
    if (!code || code !== 200) {
        if (code && code === 401) {
            // This is a token-authentication related error.
            navigateTo('/badtoken')
        } else {
            // This is an unexpected internal error.
            yield put(
                showErrors([
                    {
                        type: 'danger',
                        message:
                            msg ||
                            'There was an error processing this request.  Please try again.',
                    },
                ]),
            )
        }
        yield put(fetchCompaniesEnd())
        return true
    } else {
        return false
    }
}

function* addFilteredColHeaderHelper(data) {
    const filteredHeaders = data.filter(header => header !== 'Not Applicable')
    const types = []
    for (const header of filteredHeaders) {
        const colDataType = yield callFlaskProxy(`/api/type/${header}`)
        if (yield hasErrors(colDataType.code)) return
        types.push(
            put(
                addColDataType(
                    header,
                    colDataType.field_type,
                    colDataType.enum_choices,
                ),
            ),
        )
    }
    return types
}

export function* uploadFileHandler(action) {
    const fetchResult = yield callFlaskProxy('/api/upload', {
        method: 'POST',
        body: action.file,
    })
    if (yield hasErrors(fetchResult.code)) return

    if (fetchResult.type !== 'danger') {
        yield selectFileHandler(action, [fetchResult], fetchResult.uploaded)
    } else {
        yield put(showErrors([fetchResult]))
    }
}

export function* selectFileHandler(
    action,
    uploadErrs = [],
    forwardedFn = null,
) {
    yield put(fetchCompaniesBegin('Retrieving file data...'))

    // get fn from uploadFileHandler if we can
    const fn = forwardedFn !== null ? forwardedFn : action.fn

    // get data from backend
    let rows = yield callFlaskProxy(`/api/data/${fn}`)
    if (rows && rows.code === 200) {
        rows.data = rows.data.filter(
            arr => !arr.every(val => val === '' || val === 'None'),
        )
        //get schema_extension column options
        const extendedSchemaTarget = '/services/app/schema/company/extended'
        const enumsTarget = '/services/app/enums'

        const [
            string_files,
            options,
            requiredFields,
            fetchExtendedOptions,
            fetchEnums,
        ] = yield all([
            yield callFlaskProxy('/api/files'),
            yield callFlaskProxy('/api/options'),
            yield callFlaskProxy('/api/required'),
            yield callFlaskProxy(
                `/api/proxy?target_url=${extendedSchemaTarget}`,
                {
                    method: 'GET',
                    credentials: 'include',
                },
            ),
            yield callFlaskProxy(`/api/proxy?target_url=${enumsTarget}`, {
                method: 'GET',
                credentials: 'include',
            }),
        ])

        //I will eventually need to remove filenames calls cause they can be covered through
        // showFilesData
        const files = string_files.data.map(file => JSON.parse(file))
        const filenames = files.map(file => file.filename)
        const selectedOptions = rows.headers
        let skipColsArray = rows.headers.map((header, idx) =>
            header === 'Not Applicable' ? idx : null,
        )
        skipColsArray = skipColsArray.filter(item => item !== null)
        if (skipColsArray.length === 0) {
            yield put(
                fetchCompaniesBegin(
                    'Validating and setting detected headers...',
                ),
            )
        }

        // const colsArray = Array.from(Array(rows.data[0].length).keys())
        const rowsArray = Array.from(Array(rows.data.length).keys())
        const naColsArray = new Array(rows.data[0].length).fill(
            'Not Applicable',
        )

        for (const res of [rows, string_files, options, requiredFields]) {
            if (yield hasErrors(res.code)) return
        }

        //options stuff
        let affiliationId = null
        let extendedOptions = []
        if (yield hasErrors(fetchExtendedOptions.status)) return
        else {
            let extendedOptionsKeys = Object.keys(fetchExtendedOptions.data)
            // ensure we are only recieving extended options for one affiliation
            if (extendedOptionsKeys.length === 1) {
                affiliationId = extendedOptionsKeys[0]
                extendedOptions = fetchExtendedOptions.data[affiliationId].map(
                    extendedOption =>
                        `${extendedSchemaPrefix}_${extendedOption}`,
                )
            } else {
                yield put(
                    showErrors([
                        {
                            type: 'danger',
                            message: 'Invalid extended column options',
                        },
                    ]),
                )
            }
        }
        let optionData = _union(options.data, rows.headers, extendedOptions)
        optionData = optionData.filter(
            option => !['Not Applicable', 'affiliate'].includes(option),
        )

        //enums stuff
        if (yield hasErrors(fetchEnums.status)) return
        else {
            for (let [key, value] of Object.entries(fetchEnums.data)) {
                if (key === 'affiliations') {
                    const affiliationObj = {}
                    if (value.length) {
                        value.forEach(
                            affiliation =>
                                (affiliationObj[affiliation.id] = affiliation),
                        )
                    }
                    yield put(addAffiliations(affiliationObj))
                } else if (enumKeys[key]) {
                    let editColumn = {
                        name: enumKeys[key].label,
                        subcategory: value.filter(v => v),
                    }
                    let fetchEditColumn = yield callFlaskProxy(
                        '/api/options/update',
                        {
                            method: 'POST',
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(editColumn),
                        },
                    )
                    if (yield hasErrors(fetchEditColumn.code)) return
                }
            }
        }

        const types = yield addFilteredColHeaderHelper(rows.headers)

        yield all([
            //in case cols were set for the previously viewed file we should reset all
            // cols to NA, load the new data, and then update cols otherwise we may have a memory leak
            put(showSelectedOptions(naColsArray)),
            put(clearCompanySuggestions()),
            put(showSelectedFile(fn)),
            put(showRows(rows.data)),
            put(skipSelectedRows(rowsArray)),
            put(showOptions(optionData)),
            put(skipSelectedCols(skipColsArray)),
            put(showSelectedOptions(selectedOptions)),
            put(clearItemErrors()),
            put(showRequiredFields(requiredFields.data)),
            put(addUserAffiliationId(affiliationId)),

            put(
                showErrors([
                    ...uploadErrs,
                    {
                        type: 'success',
                        message: 'Selected file: ',
                        importantMessage: fn,
                    },
                ]),
            ),
            put(showFilenames(filenames)),
            put(showFilesData(files)),
            put(showOptionMappings(options.data)),
            ...types,
        ])
    } else {
        yield put(
            showErrors([
                {
                    type: 'danger',
                    message: `Could not load file: ${fn}. Please try again or try a different file.`,
                },
            ]),
        )
    }
    yield put(fetchCompaniesEnd())
    yield call(delay, 5000)
    yield put(clearErrors())
}

export function* inferHandler(action) {
    yield put(fetchCompaniesBegin('Attempting to auto-classify data...'))

    const inferredHeaders = yield callFlaskProxy('/api/infer')
    if (yield hasErrors(inferredHeaders.code)) return

    const types = yield addFilteredColHeaderHelper(inferredHeaders.data)

    let naCols = inferredHeaders.data
        .map((val, index) => (val === 'Not Applicable' ? index : null))
        .filter(val => val !== null)
    yield all([
        put(clearItemErrors()),
        put(clearSkippedRows()),
        put(clearSkippedCols()),
        put(showSelectedOptions(inferredHeaders.data)),
        put(skipSelectedCols(naCols)),
        put(
            showErrors([
                {
                    type: 'success',
                    message: 'Auto-Classification complete.',
                },
            ]),
        ),
        ...types,
    ])
    yield put(fetchCompaniesEnd())
    yield call(delay, 5000)
    yield put(clearErrors())
}

export function* deleteFileHandler(action) {
    const deleteResult = yield callFlaskProxy('/api/files', {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ fn: action.fn }),
    })
    if (yield hasErrors(deleteResult.code)) return

    if (deleteResult.type !== 'danger') {
        const string_files = yield callFlaskProxy('/api/files')
        const files = string_files.data.map(file => JSON.parse(file))
        const filenames = files.map(file => file.filename)
        yield put(showFilenames(filenames))
        yield put(showFilesData(files))
    } else {
        yield put(showErrors([deleteResult]))
    }
}

export function* getItemErrorsHandler(action) {
    yield put(fetchCompaniesBegin())
    const errors = yield callFlaskProxy(
        `/api/validate/${[action.field, action.value]
            .map(arg => arg.replace(/[\/\\]/g, ''))
            .join('/')}`,
    )
    if (yield hasErrors(errors.code)) return
    yield all([
        put(addItemErrors(action.row, action.col, errors.data)),
        call(delay, 250),
    ])
    yield put(fetchCompaniesEnd())
}

export function* checkColHandler(action) {
    if (action.header === 'Not Applicable') {
        yield put(selectOption(action.col, action.batch[0].field))
        yield put(skipCol())
    } else {
        // For now I will assign default column data to a column that cannot be found
        // field_type=text and enum_options=[]
        //This will have to be updated when we are able to set field_type for schema_extension cols
        const colDataType = yield callFlaskProxy(`/api/type/${action.header}`)
        if (colDataType && colDataType.code === 200) {
            // if (yield hasErrors(colDataType)) return
            yield put(selectOption(action.col, action.batch[0].field))
            yield put(
                addColDataType(
                    action.header,
                    colDataType.field_type,
                    colDataType.enum_choices,
                ),
            )
            yield put(unskipCol())
        } else {
            yield put(
                showErrors([
                    {
                        type: 'danger',
                        message: 'Failed to select this column',
                    },
                ]),
            )
        }
    }
}

export function* tryAndProceedToMergeStateHandler(action) {
    const {
        colBatches,
        skippedRows,
        skippedCols,
        headerErrors,
        companyMetaBatch,
        companyDataBatch,
    } = action

    yield put(
        fetchCompaniesBegin(
            'Validating data... This may take a few minutes, depending on file size.',
        ),
    )

    const itemErrorsToAdd = []
    let numItemErrors = 0
    for (const [idx, batch] of colBatches.entries()) {
        if (batch[0].field !== 'Not Applicable') {
            const batchResults = yield callFlaskProxy('/api/batch_check', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(batch),
            })
            if (yield hasErrors(batchResults.code)) return

            // only count item errors that are not apart of skipped rows/cols
            if (!skippedCols.includes(idx)) {
                batchResults.data.forEach((item, idx) => {
                    if (!skippedRows.includes(idx)) {
                        numItemErrors += item.length
                    }
                })
            }
            itemErrorsToAdd.push(put(addItemErrorsCol(idx, batchResults.data)))
        }
    }

    if (numItemErrors) {
        headerErrors.push({
            type: 'danger',
            message: `${numItemErrors} individual ${
                numItemErrors > 1 ? 'errors exist.' : 'error exists.'
            }`,
        })
    }

    if (!headerErrors.length && !numItemErrors) {
        // success, proceed
        yield put(clearCompanyData())
        yield put(clearRowsHistory())
        yield put(clearIngested())
        yield put(addCompanyBatch(companyMetaBatch))
        yield getCurrentDataHandler(action, companyDataBatch)
    } else {
        // errors present, hold up
        yield all([...itemErrorsToAdd, put(showErrors(headerErrors))])
        yield put(fetchCompaniesEnd())
    }
}

export function* addColumnHandler(action) {
    let newColumn = {
        name: action.normalizedName,
        ref: action.normalizedName,
        description: action.description,
        type: action.field_type,
        subcategory: action.enum_options,
        range: action.range,
        encrypt: action.encrypt,
        optional: action.optional,
    }
    //if the range was not set it to empty string as it is in the data model
    if (
        newColumn.type !== 'number' ||
        !newColumn.range.lower ||
        !newColumn.range.upper
    ) {
        newColumn.range = ''
    }

    const fetchResult = yield callFlaskProxy('/api/options/add', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(newColumn),
    })
    if (fetchResult && fetchResult.code === 200) {
        yield put(
            showErrors([
                {
                    type: 'success',
                    message: 'Added new column ' + action.name,
                },
            ]),
        )
        yield put(unskipCol(action.col))
        yield put(resetItemErrorsCol(action.col))
        yield put(selectOption(action.col, action.normalizedName))
        yield put(
            addColDataType(
                action.normalizedName,
                fetchResult.data[0].type,
                fetchResult.data[0].subcategory,
            ),
        )
        yield put(addOptionMapping(action.normalizedName, action.name))
    } else {
        yield put(
            showErrors([
                {
                    type: 'danger',
                    message: 'Failed to add new column',
                },
            ]),
        )
    }
}
