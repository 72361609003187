import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Form,
    InputGroup,
    Input,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Button,
    Table,
} from 'reactstrap'
import moment from 'moment'
import { orderBy as _orderBy } from 'lodash'
import {
    selectFile,
    showFilenames,
    showFilesData,
    deleteFile,
} from '../actions/validate'
import { setPageIndex } from '../actions/table'
import { flaskProxy } from '../sagas/clientProxy'
import { getRows } from '../helpers'
import '../styles/Import.css'

class ShownImport extends Component {
    constructor(props) {
        super(props)
        this.toggleModal = this.toggleModal.bind(this)
        this.toggleTrash = this.toggleTrash.bind(this)
    }

    state = {
        modal: false,
        activeTrash: null,
    }

    toggleTrash(id) {
        this.setState({
            activeTrash: id,
        })
    }

    toggleModal() {
        this.setState({
            modal: !this.state.modal,
            activeTrash: null,
        })
    }

    convertUtcToLocal(time) {
        return moment
            .utc(time)
            .local()
            .format('YYYY-MM-DD HH:mm:ss')
    }

    componentDidMount() {
        const { handleShowFilenames, handleShowFilesData, token, destdomain } = this.props
        flaskProxy
            .perform('/api/files', {headers: {'x-session-token': token, 'x-session-destdomain': destdomain}})
            .then(res => {
                handleShowFilenames(
                    res.data.map(file => JSON.parse(file).filename),
                )
                handleShowFilesData(res.data.map(file => JSON.parse(file)))
            })
            .catch(err => console.error(err))
    }

    render() {
        const {
            filenames,
            filesData,
            selectedFile,
            noTable,
            deleteFile,
            handleSelectFile,
            setPageIndex,
            noFiles,
        } = this.props
        return (
            <div>
                <Form onClick={this.toggleModal}>
                    <InputGroup className="group">
                        <Input
                            id="customSelect"
                            type="select"
                            value={noTable ? 'init' : selectedFile}
                            className={`admin-tool-font ${
                                noTable ? 'text-muted' : ''
                            }`}
                            onChange={e => {
                                handleSelectFile(e.target.value)
                                setPageIndex(0)
                            }}
                        >
                            {noTable && (
                                <option value="init" disabled hidden>
                                    {noFiles
                                        ? 'Use + to upload new data file'
                                        : 'Select data file'}
                                </option>
                            )}

                            {filenames.map((filename, idx) => (
                                <option hidden value={filename} key={idx}>
                                    {filename}
                                </option>
                            ))}
                        </Input>
                    </InputGroup>
                </Form>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggleModal}
                    className={this.props.className}
                    size="lg"
                >
                    <ModalHeader toggle={this.toggleModal}>
                        Select Data Files
                    </ModalHeader>
                    <ModalBody>
                        <Table hover>
                            <thead>
                                <tr className="table-header">
                                    <th>File Name</th>
                                    <th>Upload Date</th>
                                    <th className="text-center">Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filesData.map((fileData, idx) => (
                                    <tr key={idx} className="table-body-row">
                                        <td
                                            id={fileData.filename}
                                            onClick={e => {
                                                handleSelectFile(e.target.id)
                                                setPageIndex(0)
                                                this.toggleModal()
                                            }}
                                            className={`cursor-change ${
                                                fileData.filename ===
                                                selectedFile
                                                    ? 'font-weight-bold'
                                                    : ''
                                            }`}
                                        >
                                            {fileData.filename}
                                        </td>
                                        <td>
                                            {this.convertUtcToLocal(
                                                fileData.upload_date,
                                            )}
                                        </td>
                                        {`trash-${fileData.filename}` ===
                                        this.state.activeTrash ? (
                                            <td className="text-center">
                                                <span
                                                    onClick={() => {
                                                        deleteFile(
                                                            fileData.filename,
                                                        )
                                                        setPageIndex(0)
                                                    }}
                                                    className="cursor-change"
                                                >
                                                    <FontAwesomeIcon
                                                        icon="check"
                                                        className="green-check no-pointer"
                                                    />
                                                </span>
                                                <span className="ml-2 mr-2" />
                                                <span
                                                    onClick={() => {
                                                        this.toggleTrash(null)
                                                    }}
                                                    className="cursor-change"
                                                >
                                                    <FontAwesomeIcon
                                                        icon="times"
                                                        className="red-times no-pointer"
                                                    />
                                                </span>
                                            </td>
                                        ) : (
                                            <td className="text-center">
                                                <span
                                                    id={`trash-${
                                                        fileData.filename
                                                    }`}
                                                    className={`border-0 ${
                                                        fileData.filename ===
                                                        selectedFile
                                                            ? 'disabled-trash'
                                                            : 'cursor-change'
                                                    }`}
                                                    onClick={e =>
                                                        this.toggleTrash(
                                                            e.target.id,
                                                        )
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon="trash-alt"
                                                        className="no-pointer"
                                                    />
                                                </span>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            className="btn-colored fixed-height-btn no-focus-outline"
                            color="dark"
                            onClick={this.toggleModal}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

ShownImport.propTypes = {
    handleShowFilenames: PropTypes.func.isRequired,
    handleSelectFile: PropTypes.func.isRequired,
    handleShowFilesData: PropTypes.func.isRequired,
    filenames: PropTypes.arrayOf(PropTypes.string).isRequired,
    filesData: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export const Import = connect(
    // mapStateToProps
    state => ({
        filenames: state.filenames,
        filesData: _orderBy(state.filesData, ['upload_date'], ['desc']),
        selectedFile: state.selectedFile,
        noTable: getRows(state, state.undoState).length === 0,
        noFiles: state.filenames.length === 0,
        token: state.app.token,
        destdomain: state.app.destdomain
    }),
    // mapDispatchToProps
    dispatch => ({
        deleteFile: fn => dispatch(deleteFile(fn)),
        handleSelectFile: fn => dispatch(selectFile(fn)),
        handleShowFilenames: filenames => dispatch(showFilenames(filenames)),
        handleShowFilesData: files => dispatch(showFilesData(files)),
        setPageIndex: idx => dispatch(setPageIndex(idx)),
    }),
)(ShownImport)
