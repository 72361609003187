import React, { Component } from 'react'
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    UncontrolledTooltip,
} from 'reactstrap'
import '../styles/InteractiveTable.css'
import '../styles/InteractiveTableOriginCell.css'

export class InteractiveTableOriginCell extends Component {
    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this)
    }
    state = {
        modal: false,
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
        })
    }

    render() {
        const {
            noneSkipped,
            rowsArray,
            infer,
            skipSelectedRows,
            clearSkippedRows,
        } = this.props

        return (
            <div className="index-box d-flex flex-column justify-content-center">
                <span className="border-0 check-button ">
                    <input
                        type="checkbox"
                        checked={noneSkipped}
                        onChange={() =>
                            noneSkipped
                                ? skipSelectedRows(rowsArray)
                                : clearSkippedRows()
                        }
                    />
                    {/* {noneSkipped ? (
                        <FontAwesomeIcon icon={faCheckSquare} />
                    ) : (
                        <FontAwesomeIcon icon={faSquare} />
                    )} */}
                </span>
            </div>
        )
    }
}
