import React from 'react'
import PropTypes from 'prop-types'
import {
    PossibleStatus,
    PossibleConflictStatus,
    PossibleMergeStatus,
} from '../actions/companies'
import { Badge } from 'reactstrap'
import { getColorClass } from '../helpers'

const getBadgeClass = status => {
    switch (status) {
        case PossibleConflictStatus.NEW:
        case PossibleMergeStatus.CURRENT:
        case PossibleMergeStatus.INCOMING:
            return 'align-top'

        case PossibleStatus.PENDING:
            return 'align-middle'

        case PossibleStatus.ADDED:
        case PossibleStatus.MERGED:
            return 'align-center'

        default:
            return ''
    }
}

export const StatusBox = ({ status, minWidth, width }) => (
    <Badge
        className={`${getBadgeClass(
            status,
        )} d-inline-block text-${getColorClass(status)} bg-transparent`}
        style={{ minWidth: minWidth, width: width }}
    >
        {status}
    </Badge>
)

StatusBox.propTypes = {
    status: PropTypes.oneOf(
        Object.values(PossibleStatus)
            .concat(Object.values(PossibleConflictStatus))
            .concat(Object.values(PossibleMergeStatus)),
    ).isRequired,
    minWidth: PropTypes.string,
    width: PropTypes.string,
}
