import {
    AffiliationActions,
} from '../actions/affiliations'

export const userAffiliationId = (state = null, action) => {
    switch (action.type) {
        case AffiliationActions.ADD_USER_AFFILIATION_ID:
            return action.affiliationId
        default:
            return state
    }
}

export const affiliations = (state = null, action) => {
    switch (action.type) {
        case AffiliationActions.ADD_AFFILIATIONS:
            return action.affiliations
        default:
            return state
    }
}