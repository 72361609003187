import { connect } from 'react-redux'
import { InteractiveTableHeaderInput } from '../components/InteractiveTableHeaderInput'
import {
    checkItemsCol,
    skipCol,
    unskipCol,
    batchUpdateColumn,
    resetItemErrorsCol,
} from '../actions/validate'
import {
    removeCompanySuggestions,
    addCompanySuggestions,
    clearCompanySuggestions,
} from '../actions/companies'
import { actionModalToggle } from '../actions/modals'
import { getRows } from '../helpers'
import { permanentOptions } from '../statics/columnStatics'

const getTotalNumErrors = (itemErrors, skippedRows, col) => {
    let numErrs = 0
    itemErrors.forEach(item => {
        if (!skippedRows.includes(item.row) && item.col === col) {
            numErrs += item.errors.length
        }
    })
    return numErrs
}

const mapStateToProps = (state, ownProps) => {
    const selected = state.selectedOptions[ownProps.col]
    return {
        options: state.options,
        selected,
        permanent: permanentOptions.includes(selected),
        colDisabled: state.skippedCols.includes(ownProps.col),
        fullCol: getRows(state, state.undoState).map(row => row[ownProps.col]),
        totalNumErrors: getTotalNumErrors(
            state.itemErrors,
            state.skippedRows,
            ownProps.col,
        ),
        col: ownProps.col,
        normalizedServerCompanyNames: state.normalizedServerCompanyNames,
        pageSize: state.pageSize,
        pageIndex: state.pageIndex,
        companySuggestions: state.companySuggestions,
        optionMappings: state.optionMappings,
        errorsLength: state.errors.length,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    checkItemsCol: (batch, header) =>
        dispatch(checkItemsCol(batch, ownProps.col, header)),
    skipCol: () => dispatch(skipCol(ownProps.col)),
    unskipCol: () => dispatch(unskipCol(ownProps.col)),
    batchUpdate: (col, data) => dispatch(batchUpdateColumn(col, data)),
    resetItemErrorsCol: col => dispatch(resetItemErrorsCol(col)),
    removeCompanySuggestions: col => dispatch(removeCompanySuggestions(col)),
    addCompanySuggestions: suggestions =>
        dispatch(addCompanySuggestions(suggestions)),
    clearCompanySuggestions: () => dispatch(clearCompanySuggestions()),
    actionModalToggle: (modalType, col) =>
        dispatch(actionModalToggle(modalType, col)),
})

export const InteractiveTableHeaderInputContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    // mergeProps
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        handleSelectOption: option => {
            //here I have to ask Justin A. more about batch and undo batch operation
            // when option is Not Applicable
            //Should I instead directly just call selectOpton here?
            const batch = stateProps.fullCol.map(val => ({
                field: option,
                value: val,
            }))
            dispatchProps.checkItemsCol(batch, option)
            if (option === 'Not Applicable') {
                dispatchProps.skipCol()
            } else {
                dispatchProps.unskipCol()
            }
        },
        batchUpdate: data => {
            dispatchProps.batchUpdate(stateProps.col, data)
        },
    }),
)(InteractiveTableHeaderInput)
