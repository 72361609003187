import { connect } from 'react-redux'
import { ValidateButton } from '../components/ValidateButton'
import { tryAndProceedToMergeState } from '../actions/validate'
import { PossibleStatus } from '../actions/companies'
import { getRows, denormalizeColumnName } from '../helpers'
const notAllColHeadersChosen = (selectedOptions, skippedCols) =>
    selectedOptions.some(
        (option, idx) =>
            option === 'Not Applicable' && !skippedCols.includes(idx),
    )
const duplicateColHeaders = (selectedOptions, skippedCols) => {
    const sorted = selectedOptions
        .filter((_, idx) => !skippedCols.includes(idx))
        .sort()
    for (let i = 0; i < sorted.length - 1; i++) {
        if (sorted[i + 1] === sorted[i]) {
            return true
        }
    }
    return false
}
const duplicateCompanyNameRows = (
    selectedOptions,
    skippedCols,
    skippedRows,
    rows,
) => {
    let nameIdx = -1
    //find x index of name column
    for (const [idx, option] of selectedOptions.entries()) {
        if (option === 'name' && !skippedCols.includes(idx)) {
            nameIdx = idx
        }
    }
    if (nameIdx >= 0) {
        //filter rows that have been skipped and keep only name column
        const sortedNames = rows
            .filter((_, idy) => !skippedRows.includes(idy))
            .map(name => name[nameIdx])
            .sort()
        if (sortedNames.length > 0) {
            for (let i = 0; i < sortedNames.length - 1; i++) {
                if (sortedNames[i] === sortedNames[i + 1]) {
                    return true
                }
            }
        }
    }
    return false
}
const getMissingRequiredFields = (selectedOptions, required, skippedCols) => {
    const remainingFields = selectedOptions.filter(
        (_, idx) => !skippedCols.includes(idx),
    )
    return required.filter(field => !remainingFields.includes(field))
}
const getNumItemErrors = (itemErrors, skippedRows, skippedCols) => {
    let numItemErrs = 0
    itemErrors.forEach(item => {
        if (
            !(skippedRows.includes(item.row) || skippedCols.includes(item.col))
        ) {
            numItemErrs += item.errors.length
        }
    })
    return numItemErrs
}
const createCompanyMetaFromRows = (
    rows,
    skippedRows,
    skippedCols,
    selectedOptions,
) => {
    let nameIdx = -1
    for (const [idx, option] of selectedOptions.entries()) {
        if (option === 'name' && !skippedCols.includes(idx)) {
            nameIdx = idx
        }
    }
    // name should exist, but if it doesn't, abort!
    if (nameIdx >= 0) {
        return rows.reduce((companyMeta, row, idx) => {
            if (!skippedRows.includes(idx) && row[nameIdx] !== '') {
                // start id's at 1... 0 is reserved as a default
                companyMeta[idx + 1] = {
                    id: idx + 1,
                    name: String(row[nameIdx]),
                    status: PossibleStatus.PENDING,
                    isConflicting: false,
                    merges: [],
                    errors: [],
                }
            }
            return companyMeta
        }, {})
    }
}
// use this to create companyData
// make sure on custom column creation you black list extsch_
const createCompanyDataFromRows = (
    rows,
    skippedRows,
    skippedCols,
    selectedOptions,
) => {
    const test = rows.reduce((companyData, row, idx) => {
        if (!skippedRows.includes(idx)) {
            // start id's at 1... 0 is reserved as a default
            companyData[idx + 1] = {
                id: idx + 1,
                ...row.reduce((rowData, item, idx) => {
                    const strItem = String(item)
                    if (!skippedCols.includes(idx) && strItem) {
                        rowData[selectedOptions[idx]] = strItem
                    }
                    return rowData
                }, {}),
            }
        }
        return companyData
    }, {})
    return test
}

const mapStateToProps = state => {
    const errs = []
    if (notAllColHeadersChosen(state.selectedOptions, state.skippedCols)) {
        errs.push({
            type: 'danger',
            message: 'All column headers must be selected.',
        })
    }
    if (duplicateColHeaders(state.selectedOptions, state.skippedCols)) {
        errs.push({
            type: 'danger',
            message: 'Duplicate column headers are not allowed.',
        })
    }
    if (
        duplicateCompanyNameRows(
            state.selectedOptions,
            state.skippedCols,
            state.skippedRows,
            getRows(state, state.undoState),
        )
    ) {
        errs.push({
            type: 'danger',
            message: 'Duplicate company names are not allowed',
        })
    }
    if (
        state.skippedRows.length === getRows(state, state.undoState).length ||
        state.skippedCols.length === getRows(state, state.undoState)[0].length
    ) {
        errs.push({
            type: 'danger',
            message:
                'At least one row of data must be present before proceeding.',
        })
    }
    getMissingRequiredFields(
        state.selectedOptions,
        state.required,
        state.skippedCols,
    ).forEach(field => {
        const errLabel = state.optionMappings[field]
            ? state.optionMappings[field]
            : denormalizeColumnName(field)
        errs.push({
            type: 'danger',
            message: 'Missing required field: ',
            importantMessage: `${errLabel}.`,
        })
    })
    // form column batches
    const rows = getRows(state, state.undoState)
    const colBatches = []
    if (rows.length > 0) {
        for (let i = 0; i < rows[0].length; i++) {
            colBatches.push([])
            for (const row of rows) {
                colBatches[i].push({
                    field: state.selectedOptions[i],
                    value: row[i],
                })
            }
        }
    }
    return {
        rows: getRows(state, state.undoState),
        skippedRows: state.skippedRows,
        skippedCols: state.skippedCols,
        selectedOptions: state.selectedOptions,
        fetching: state.fetching.fetching,
        fetchingMsg: state.fetching.msg,
        errs,
        colBatches,
        userAffiliationId: state.userAffiliationId,
    }
}
const mapDispatchToProps = dispatch => ({
    tryAndProceedToMergeState: (
        colBatches,
        skippedRows,
        skippedCols,
        headerErrors,
        companyMetaBatch,
        companyDataBatch,
    ) => {
        dispatch(
            tryAndProceedToMergeState(
                colBatches,
                skippedRows,
                skippedCols,
                headerErrors,
                companyMetaBatch,
                companyDataBatch,
            ),
        )
    },
})
export const ValidateButtonContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    // mergeProps
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        tryAndProceedToMergeState: () => {
            dispatchProps.tryAndProceedToMergeState(
                stateProps.colBatches,
                stateProps.skippedRows,
                stateProps.skippedCols,
                stateProps.errs,
                createCompanyMetaFromRows(
                    stateProps.rows,
                    stateProps.skippedRows,
                    stateProps.skippedCols,
                    stateProps.selectedOptions,
                ),
                createCompanyDataFromRows(
                    stateProps.rows,
                    stateProps.skippedRows,
                    stateProps.skippedCols,
                    stateProps.selectedOptions,
                    stateProps.affiliationId,
                ),
            )
        },
    }),
)(ValidateButton)
