import { connect } from 'react-redux'
import { InteractiveTableRowItem } from '../components/InteractiveTableRowItem'
import { addItemErrors, updateCellData } from '../actions/validate'
import { getRows } from '../helpers'
import { get as _get } from 'lodash'

const mapStateToProps = (state, ownProps) => {
    const item = state.itemErrors.find(
        item => item.row === ownProps.row && item.col === ownProps.col,
    )

    const rows = getRows(state, state.undoState)
    const data = _get(rows, [ownProps.row, ownProps.col], '')
    return {
        data,
        errsReady:
            item &&
            item.errors.length > 0 &&
            state.selectedOptions[ownProps.col] !== 'Not Applicable',
        rowNum: ownProps.row,
        colNum: ownProps.col,
        disabled:
            state.skippedCols.includes(ownProps.col) ||
            state.skippedRows.includes(ownProps.row),
        selected: state.selectedOptions[ownProps.col],
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    updateItemContents: value => {
        dispatch(updateCellData(ownProps.row, ownProps.col, value))
        dispatch(addItemErrors(ownProps.row, ownProps.col, []))
    },
})

export const InteractiveTableRowItemContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    // mergeProps
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        hasErrors: stateProps.errsReady && !stateProps.disabled,
        hasNameWarning:
            stateProps.selected === 'name' &&
            !stateProps.disabled &&
            stateProps.data !== '',
        // updateItemContents: value => {
        //     dispatchProps.updateItemContents(stateProps.selected, value)
        // },
    }),
)(InteractiveTableRowItem)
