export const defaultMeta = {
    0: {
        id: 0,
        name: 'default',
        status: 'Pending',
        isConflicting: false,
        isExactMatch: false,
        merges: [],
        unmerged: [],
        errors: [],
    },
}

export const defaultData = {
    0: {
        id: '',
        name: '',
        category: '',
        sector: '',
        subsectors: '',
        local_employee_count: '',
        website: '',
        founding_year: '',
        address: '',
        city: '',
        postal_code: '',
        description: '',
        organization_type: '',
        company_status: '',
        headquarters: '',
        summary: '',
        capital_raised: '',
        size: '',
        revenue: '',
        revenue_stage: '',
        global_employee_count: '',
        ticker: '',
        phone: '',
        linkedin_url: '',
        facebook_url: '',
        twitter_id: '',
        acquired_by_name: '',
        acquired_date: '',
        acquisition_amount: '',
        industries: '',
        demograpgics: '',
        hours: '',
        email: '',
        notes: '',
        eligibility_notes: '',
        contact_notes: '',
    },
}
export const defaultActionModals = {
    import: { isOpen: false },
    infer: { isOpen: false },
    merge: { isOpen: false },
    unmerge: { isOpen: false },
    ingest: { isOpen: false },
    addColumn: { isOpen: false, column: null },
    save: { isOpen: false },
}

export const defaultApp = {
    token : null,
    destdomain : null,
    isReady: false
}
