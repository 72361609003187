import { connect } from 'react-redux'
import { InteractiveTableHeaderItem } from '../components/InteractiveTableHeaderItem'
import {
    checkItemsCol,
    skipCol,
    unskipCol,
    batchUpdateColumn,
    resetItemErrorsCol,
} from '../actions/validate'
import {
    removeCompanySuggestions,
    addCompanySuggestions,
    clearCompanySuggestions,
} from '../actions/companies'
import { getRows } from '../helpers'

const getTotalNumErrors = (itemErrors, skippedRows, col) => {
    let numErrs = 0
    itemErrors.forEach(item => {
        if (!skippedRows.includes(item.row) && item.col === col) {
            numErrs += item.errors.length
        }
    })
    return numErrs
}

const mapStateToProps = (state, ownProps) => ({
    options: state.options,
    selected: state.selectedOptions[ownProps.col],
    colDisabled: state.skippedCols.includes(ownProps.col),
    fullCol: getRows(state, state.undoState).map(row => row[ownProps.col]),
    totalNumErrors: getTotalNumErrors(
        state.itemErrors,
        state.skippedRows,
        ownProps.col,
    ),
    col: ownProps.col,
    normalizedServerCompanyNames: state.normalizedServerCompanyNames,
    pageSize: state.pageSize,
    pageIndex: state.pageIndex,
    companySuggestions: state.companySuggestions,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    checkItemsCol: (batch, header) =>
        dispatch(checkItemsCol(batch, ownProps.col, header)),
    skipCol: () => dispatch(skipCol(ownProps.col)),
    unskipCol: () => dispatch(unskipCol(ownProps.col)),
    batchUpdate: (col, data) => dispatch(batchUpdateColumn(col, data)),
    resetItemErrorsCol: col => dispatch(resetItemErrorsCol(col)),
    removeCompanySuggestions: col => dispatch(removeCompanySuggestions(col)),
    addCompanySuggestions: suggestions =>
        dispatch(addCompanySuggestions(suggestions)),
    clearCompanySuggestions: () => dispatch(clearCompanySuggestions()),
})

export const InteractiveTableHeaderItemContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    // mergeProps
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        handleSelectOption: option => {
            const batch = stateProps.fullCol.map(val => ({
                field: option,
                value: val,
            }))
            dispatchProps.checkItemsCol(batch, option)
        },
        batchUpdate: data => {
            dispatchProps.batchUpdate(stateProps.col, data)
        },
    }),
)(InteractiveTableHeaderItem)
