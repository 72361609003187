import { connect } from 'react-redux'
import { App } from '../components/App'
import { init } from '../actions/app'

const mapStateToProps = state => {
    return {
        isReady: state.app.isReady,
    }
}

const mapDispatchToProps = dispatch => ({
    init: (token, domain) => dispatch(init(token, domain)),
})
export const AppContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(App)
