import ActionModal from '../components/ActionModal'
import { connect } from 'react-redux'
import { actionModalToggle } from '../actions/modals'

const mapStateToProps = state => {
    return {
        actionModals: state.actionModals,
    }
}

const mapDispatchToProps = dispatch => ({
    actionModalToggle: modalType => {
        dispatch(actionModalToggle(modalType))
    },
})

export const ActionModalContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ActionModal)
