import { connect } from 'react-redux'
import { InteractiveTable } from '../components/InteractiveTable'
import { setPageIndex, setPageSize } from '../actions/table'
import { getRows } from '../helpers'

const mapStateToProps = (state, ownProps) => {
    return {
        pageSize: state.pageSize,
        pageIndex: state.pageIndex,
        rows: getRows(state, state.undoState),
        totalPageCount: Math.floor(
            getRows(state, state.undoState).length / state.pageSize,
        ),
        jumpIndex: state.jumpIndex,
        skippedRows: state.skippedRows,
        skippedCols: state.skippedCols,
        errorsList: ownProps.errorsList,
    }
}

const mapDispatchToProps = dispatch => ({
    setPageIndex: idx => dispatch(setPageIndex(idx)),
    setPageSize: size => dispatch(setPageSize(size)),
})

export const InteractiveTableContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
    }),
)(InteractiveTable)
