export const MergeActions = {
    MERGE_FIELD: 'MERGE_FIELD',
    UNMERGE_FIELD: 'UNMERGE_FIELD',
    MERGE_ALL: 'MERGE_ALL',
    UNMERGE_ALL: 'UNMERGE_ALL',
}

export const mergeField = (id, fieldName) => ({
    type: MergeActions.MERGE_FIELD,
    id,
    fieldName,
})

export const unmergeField = (id, fieldName) => ({
    type: MergeActions.UNMERGE_FIELD,
    id,
    fieldName,
})

export const mergeAll = ids => ({
    type: MergeActions.MERGE_ALL,
    ids,
})

export const unmergeAll = ids => ({
    type: MergeActions.UNMERGE_ALL,
    ids,
})
