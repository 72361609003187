import { SortStates, SELECT_SORT_STATE } from '../actions/sort'

export const sortStatusField = (state = SortStates.BY_NEW, action) => {
    switch (action.type) {
        case SELECT_SORT_STATE:
            return action.sortState

        default:
            return state
    }
}
