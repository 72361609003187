import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
    Form,
    InputGroup,
    InputGroupAddon,
    Input,
    Button,
    Row,
    Col,
    UncontrolledTooltip,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { uploadFile, clearErrors } from '../actions/validate'
import { setPageIndex, setPageSize } from '../actions/table'
import '../styles/Upload.css'

class ShownUpload extends PureComponent {
    constructor(props) {
        super(props)
        this.input = React.createRef()
    }

    handleUpload = e => {
        const { uploadFile, setPageIndex, setPageSize } = this.props
        const data = new FormData()
        const file = this.input.current.files[0]
        if (file) {
            data.append('uploadFile', file)
            uploadFile(file.name, data)
            setPageIndex(0)
            setPageSize(100)
            e.target.value = null
        }
    }

    render() {
        return (
            <Form className="upload-form">
                <InputGroup className="nowrap">
                    <label
                        htmlFor="uploadFileStrap"
                        className="custom-file-upload"
                        id="uploadInput"
                    >
                        <FontAwesomeIcon
                            icon="plus-circle"
                            className="cursor-pointer"
                        />
                    </label>
                    <Input
                        type="file"
                        hidden
                        id="uploadFileStrap"
                        className="upload-input"
                        innerRef={this.input}
                        onChange={this.handleUpload}
                    />
                </InputGroup>
                <UncontrolledTooltip
                    innerClassName="infer-tooltip pl-1 pr-1"
                    placement="right"
                    target="uploadInput"
                >
                    Upload CSV file
                </UncontrolledTooltip>
            </Form>
        )
    }
}

ShownUpload.propTypes = {
    uploadFile: PropTypes.func.isRequired,
}

export const Upload = connect(
    state => ({
        errors: state.errors,
    }),
    // mapDispatchToProps
    dispatch => ({
        uploadFile: (fn, file) => {
            dispatch(uploadFile(fn, file))
        },
        setPageIndex: idx => {
            dispatch(setPageIndex(idx))
        },
        setPageSize: size => {
            dispatch(setPageSize(size))
        },
        clearErrs: () => {
            dispatch(clearErrors())
        },
    }),
)(ShownUpload)
