import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { batchUpdateColumn, addItemErrorsCol } from '../actions/validate'
import {
    Button,
    Input,
    InputGroup,
    InputGroupAddon,
    Popover,
    PopoverHeader,
    PopoverBody,
    ListGroup,
    ListGroupItem,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getRows } from '../helpers'
import { categoryKeys } from '../statics/keyMapping'
import '../styles/BatchPopover.css'

export class ShowBatchPopover extends Component {
    constructor(props) {
        super(props)
        this.state = {
            popoverOpen: false,
            fetching: true,
        }
        this.toggle = this.toggle.bind(this)
    }

    toggle() {
        this.setState({ popoverOpen: !this.state.popoverOpen })
    }

    submitValue(selected, col, choice) {
        this.props.batchUpdate(choice)
        this.toggle()
    }

    render() {
        const {
            col,
            handleBatchUpdate,
            selected,
            disabled,
            colType,
            colChoices,
        } = this.props
        const { popoverOpen } = this.state
        const popoverId = `batchUpdate-${col}`

        return (
            <Fragment>
                <FontAwesomeIcon
                    id={`batchUpdate-${col}`}
                    icon="tag"
                    onClick={this.toggle}
                    className={`${
                        disabled ? 'tag-disabled' : 'batch-update-btn'
                    } ml-3 mr-3 grow-1`}
                />
                <Popover
                    placement="right-end"
                    isOpen={popoverOpen}
                    toggle={this.toggle}
                    target={popoverId}
                    className="batch-popover admin-tool-font"
                >
                    <PopoverHeader className="batch-popover-header admin-tool-font">
                        Apply to Column
                    </PopoverHeader>
                    <PopoverBody className="admin-tool-font">
                        <ListGroup flush className="scroll-group">
                            {colType === 'enumeration' ? (
                                colChoices.map((choice, idx) => (
                                    <ListGroupItem
                                        key={idx}
                                        className="batch-item"
                                        action
                                        onClick={() => {
                                            this.submitValue(
                                                selected,
                                                col,
                                                choice,
                                            )
                                        }}
                                    >
                                        <i>{choice}</i>
                                    </ListGroupItem>
                                ))
                            ) : (
                                <form
                                    onSubmit={e => {
                                        e.preventDefault()
                                        this.submitValue(
                                            selected,
                                            col,
                                            this.input.value,
                                        )
                                    }}
                                >
                                    <InputGroup className="batch-item">
                                        <Input
                                            autoFocus
                                            innerRef={element => {
                                                this.input = element
                                            }}
                                            placeholder="Enter new value"
                                        />
                                        <InputGroupAddon addonType="append">
                                            <Button
                                                outline
                                                className="apply-batch-btn"
                                                onClick={e => {
                                                    e.preventDefault()
                                                    this.submitValue(
                                                        selected,
                                                        col,
                                                        this.input.value,
                                                    )
                                                }}
                                            >
                                                Apply
                                            </Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </form>
                            )}
                        </ListGroup>
                    </PopoverBody>
                </Popover>
            </Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const header = state.selectedOptions[ownProps.col]
    const currRows = getRows(state, state.undoState)

    return {
        col: ownProps.col,
        fullCol: currRows.map(row => row[ownProps.col]),
        selected: header,
        disabled: header === 'Not Applicable',
        colType: header in state.types ? state.types[header].type : 'text',
        colChoices: header in state.types ? state.types[header].choices : [],

        // below props are for passing state data to mergeProps, not for component use
        toSkip: state.skippedRows,

        // whether or not every single row is skipped
        allRowsSkipped: [...Array(currRows.length).keys()].every(rowIdx =>
            state.skippedRows.includes(rowIdx),
        ),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    batchUpdate: (data, toSkip) =>
        dispatch(batchUpdateColumn(null, ownProps.col, data, toSkip)),
    clearColErrors: colLen =>
        dispatch(addItemErrorsCol(ownProps.col, new Array(colLen).fill([]))),
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    batchUpdate: data => {
        // if all rows are skipped, dont bother updating the column
        // when the tag icon is clicked.  This allows us to avoid
        // creating an extra undo state in which nothing has actually changed.
        if (!stateProps.allRowsSkipped) {
            dispatchProps.batchUpdate(data, stateProps.toSkip)
            dispatchProps.clearColErrors(stateProps.fullCol.length)
        }
    },
})

export const BatchPopover = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
)(ShowBatchPopover)
