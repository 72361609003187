import { connect } from 'react-redux'
import { ErrorPopover } from '../components/ErrorPopover'
import { addItemErrors, updateCellData } from '../actions/validate'

const mapStateToProps = (state, ownProps) => {
    const errors = state.itemErrors.find(
        item => item.row === ownProps.row && item.col === ownProps.col,
    ).errors

    return {
        rowNum: ownProps.row,
        colNum: ownProps.col,
        ignoredCallback: ownProps.ignoredCallback,
        errorsNotIgnorable: errors.some(error => error.important === true),
        errors,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    ignoreErrors: remainingErrs =>
        dispatch(addItemErrors(ownProps.row, ownProps.col, remainingErrs)),
    selectChoice: choice => {
        dispatch(updateCellData(ownProps.row, ownProps.col, choice))
        dispatch(addItemErrors(ownProps.row, ownProps.col, []))
    },
})

export const ErrorPopoverContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    // mergeProps
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        // only ignore ignorable errors
        ignoreErrors: () => {
            const remainingErrs = stateProps.errors.filter(err => err.important)
            dispatchProps.ignoreErrors(remainingErrs)
        },
    }),
)(ErrorPopover)
