import React from 'react'
import PropTypes from 'prop-types'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
    PossibleStatus,
    PossibleConflictStatus,
    EditStatus,
} from '../actions/companies'
import { StageButtonsContainer } from '../containers/StageButtonsContainer'
import { CompanyHeader } from './CompanyHeader'
import { OptionalDisable } from './OptionalDisable'
import { OptionalHide } from './OptionalHide'
import ExpandingTile from './ExpandingTile'
import '../styles/NewPanelPane.css'

export const NewPanelPane = ({
    name,
    pendingStatus,
    fieldsObj,
    noChanges,
    errors,
}) => {
    const shouldDisable =
        pendingStatus === PossibleStatus.ADDED ||
        pendingStatus === PossibleStatus.MERGED ||
        pendingStatus === PossibleStatus.WARNING ||
        pendingStatus === PossibleStatus.ERROR ||
        pendingStatus === PossibleStatus.INGESTED
    return (
        <div className="m-0 p-0 d-flex flex-column full-height">
            <div className="d-flex flex-row fixed-height-header">
                <div className="d-flex flex-column p-0 col-sm-6">
                    <div className="new-panel-header general-header d-flex justify-content-center border-right border-bottom">
                        <CompanyHeader
                            name={name}
                            pendingStatus={pendingStatus}
                            conflictStatus={EditStatus.NEW_EDIT}
                        />
                    </div>
                </div>
                <div className="d-flex flex-column p-0 col-sm-6">
                    <div className="general-header d-flex justify-content-center border-bottom">
                        <CompanyHeader
                            name={name}
                            pendingStatus={pendingStatus}
                            conflictStatus={EditStatus.FINAL_EDIT}
                        />
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row flex-grow-1 hoverflay-y">
                <OptionalDisable
                    disabled={noChanges || shouldDisable}
                    className="d-flex flex-column p-0 col-sm-6 border-right"
                >
                    <ListGroup flush>
                        {Object.entries(fieldsObj).map((field, idx) => (
                            <ListGroupItem
                                key={idx}
                                className="list-group-item-action pt-1 pb-1 border-bottom d-flex"
                            >
                                {errors.includes(field[0]) && (
                                    <FontAwesomeIcon
                                        icon="exclamation"
                                        className="mr-2 ml-1 align-self-center"
                                    />
                                )}
                                <ExpandingTile
                                    fieldName={field[0]}
                                    value={field[1]}
                                />
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </OptionalDisable>
                <OptionalHide
                    hidden={noChanges || !shouldDisable}
                    className="d-flex flex-column p-0 col-sm-6"
                >
                    <ListGroup flush>
                        {Object.entries(fieldsObj).map((field, idx) => (
                            <ListGroupItem
                                key={idx}
                                className="list-group-item-action pt-1 pb-1 border-bottom"
                            >
                                <ExpandingTile
                                    fieldName={field[0]}
                                    value={field[1]}
                                />
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </OptionalHide>
            </div>
            <div className="text-center bg-white z-10 bottom-nav-height border-top">
                {noChanges ? (
                    <p className="text-muted">No Changes</p>
                ) : (
                    <StageButtonsContainer
                        onClickNextStatus={PossibleStatus.ADDED}
                    />
                )}
            </div>
        </div>
    )
}

NewPanelPane.propTypes = {
    name: PropTypes.string.isRequired,
    pendingStatus: PropTypes.oneOf(Object.values(PossibleStatus)).isRequired,
    fieldsObj: PropTypes.object.isRequired,
    noChanges: PropTypes.bool.isRequired,
    errors: PropTypes.array.isRequired,
}
