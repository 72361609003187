import React, { Component } from 'react'
import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    CustomInput,
    InputGroupAddon,
    InputGroup,
    FormFeedback,
    ModalBody,
    ModalFooter,
    Modal,
    ModalHeader,
    Table,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { normalizeExtendedColumnName } from '../helpers'
import { missingOptions, blackList } from '../statics/columnStatics'

export class AddColumnModalNameOnly extends Component {
    constructor(props) {
        super(props)
        this.state = props.initialState
        this.nameInput = React.createRef()
    }

    validateInput = () => {
        const {
            selectVal,
            columnName,
            columnDescription,
            enumOptions,
            range,
            encrypt,
            optional,
            optionData,
            invalidName,
        } = this.state
        const re = /^(?=.*[a-zA-Z].*)[a-zA-Z0-9\s]+$/
        const spaceRe = /^[\s]+$/
        let status = true,
            invalidNameObj = { status: false, description: '' }
        //fail validation if name is taken already
        if (invalidName.status === true) {
            status = false
            invalidNameObj.status = true
            invalidNameObj.description = 'Column name is already taken'
        }
        //check that user only inputs letters and spaces
        if (!columnName.match(re)) {
            status = false
            invalidNameObj.status = true
            invalidNameObj.description =
                'Column name must include at least one letter and can only include letters, numbers and/or spaces.'
        }
        //check that none of our fields are empty
        if (!columnName || columnName == '' || columnName.match(spaceRe)) {
            status = false
            invalidNameObj.status = true
            invalidNameObj.description = 'Column name cannot be empty'
        }
        if (!status) {
            this.setState({
                invalidName: invalidNameObj,
            })
        } else {
            this.props.confirmAddColumn(
                columnName,
                normalizeExtendedColumnName(columnName),
                columnDescription,
                selectVal,
                enumOptions,
                range,
                encrypt,
                optional,
                this.props.actionModals['addColumn']['column'],
            );
            this.handleToggle();
        }
    }

    reset = () => {
        this.setState(this.props.initialState)
    }

    handleToggle = () => {
        this.reset()
        this.props.actionModalToggle('addColumn')
    }

    setAndCheck = e => {
        const { options, companyLabels, updateOptions } = this.props

        var fullOptions = options.concat(missingOptions)
        // Validate current input if the enter key is pressed
        if (e.key === 'Enter') {
            this.validateInput()
        }
        if (
            fullOptions.includes(e.target.value.toLowerCase()) ||
            companyLabels.includes(e.target.value.toLowerCase()) ||
            fullOptions.includes(normalizeExtendedColumnName(e.target.value)) ||
            blackList.includes(e.target.value)
        ) {
            this.setState({
                invalidName: {
                    status: true,
                    description: 'Column name is already taken',
                },
                columnName: e.target.value,
            })
        } else {
            this.setState({
                invalidName: {
                    status: false,
                },
                columnName: e.target.value,
            })
        }
    }

    preventEnterSubmit = e => {
        e.preventDefault()
    }

    componentDidUpdate = prevProps => {
        if (this.props.actionModals['addColumn']['isOpen']) {
            this.nameInput.current.focus()
        }
    }

    render() {
        const {
            options,
            companyLabels,
            actionModalToggle,
            actionModals,
            confirmAddColumn,
        } = this.props
        const { selectVal, columnName, invalidName } = this.state
        return (
            <div>
                <Modal isOpen={actionModals['addColumn']['isOpen']}>
                    <ModalHeader toggle={() => this.handleToggle()}>
                        Add Column
                    </ModalHeader>
                    <Form onSubmit={this.preventEnterSubmit}>
                        <ModalBody>
                            <FormGroup>
                                <Label for="name">Column Name:</Label>
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    onKeyUp={this.setAndCheck}
                                    invalid={invalidName.status}
                                    autoComplete="off"
                                    innerRef={this.nameInput}
                                />
                                <FormFeedback invalid={invalidName.status}>
                                    {invalidName.description}
                                </FormFeedback>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                className="btn-colored fixed-height-btn no-focus-outline"
                                color="dark"
                                onClick={() => this.handleToggle()}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="btn-colored btn-green fixed-height-btn admin-tool-font no-focus-outline"
                                onClick={() => {
                                    this.validateInput()
                                }}
                            >
                                Add
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </div>
        )
    }
}
