export const SELECT_SORT_STATE = 'SELECT_SORT_STATE'

export const SortStates = {
    BY_PENDING: 'BY_PENDING',
    BY_ADDED: 'BY_ADDED',
    BY_MERGED: 'BY_MERGED',
    BY_NEW: 'BY_NEW',
}

export const FilterStates = {
    BY_ALL: 'BY_ALL',
    BY_PENDING: 'BY_PENDING',
    BY_INGEST_READY: 'BY_INGEST_READY',
    BY_NO_CHANGES: 'BY_NO_CHANGES',
}

export const selectSortState = sortState => ({
    type: SELECT_SORT_STATE,
    sortState,
})
