import {AppActions}  from '../actions/app'
import {defaultApp} from './defaults'

export const app = (state = defaultApp, action) => {
    switch (action.type) {
        case AppActions.INIT:
            return {
                isReady: true,
                token: action.token,
                destdomain: action.destdomain
            }

        default:
            return state
    }
}
