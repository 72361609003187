import { connect } from 'react-redux'
import { SearchBar } from '../components/SearchBar'
import { displaySearchResults } from '../actions/companies'

// relatively dumb search
const getSearchResults = (search, namesAndIds) => {
    let treatedSearch = search.replace(/\s/g, '').toUpperCase()
    let filteredSearch = namesAndIds.filter(company =>
        company.name
            .replace(/\s/g, '')
            .toUpperCase()
            .includes(treatedSearch),
    )
    return filteredSearch.map(company => company.id)
}

const mapStateToProps = state => ({
    companyNamesAndIds: state.companies.map(id => ({
        id,
        name: state.companyMeta[id].name,
    })),
})

const mapDispatchToProps = dispatch => ({
    displayResults: resultsIdList => {
        dispatch(displaySearchResults(resultsIdList))
    },
})

export const SearchBarContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    // mergeProps
    (stateProps, dispatchProps) => ({
        ...stateProps,
        ...dispatchProps,
        handleSearch: search => {
            dispatchProps.displayResults(
                getSearchResults(search, stateProps.companyNamesAndIds),
            )
        },
    }),
)(SearchBar)
